import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Header from "../components/Header";
import FooterComponent from "../components/Footer";
import { setUserResult } from "../redux/action/result";
import { setSupportEmail } from "../redux/action/support";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  CssBaseline,
} from "@mui/material";
import { setUserStatus } from "../redux/action/user";
import { Link } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { saveTargetPath, getEmail } from "../utils/storage";
import { Auth } from "aws-amplify";
import { customGoogleUserLogin } from "../components/common/GlobalFunction";
import ResponsiveDialog from "../components/common/ResponsiveDialog";
import { KeyboardArrowRight } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CoverLetter from "../components/common/CoverLetter";
import {
  getPreviousResult,
  googleUserLogin,
  getResultPageCarousel,
} from "../components/stepper_form/stepper.helper";
import {
  setResultPageSections,
  setResultPageCarousel,
} from "../redux/action/result";
import { toast } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { RWebShare } from "react-web-share";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { setupAuthInterceptor } from "../utils/apis";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import { OAuthCtx } from "../auth/AuthContext";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import confetti from "canvas-confetti";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";
import Stack from "@mui/material/Stack";
import DialogComponent from "../components/common/Dailog";
import ReplyTwoToneIcon from "@mui/icons-material/ReplyTwoTone";
import Banner from "../components/UI/Banner";
import TooltipComponent from "../components/common/Tooltip";
import DeviceInfoAndLocation from "../components/common/DeviceInfoAndLocation";
import Circle from "@mui/icons-material/Circle";
import Skeleton from "@mui/material/Skeleton";
import SkeletonLoader from "../components/common/SkeletonLoader";
import { setUserPreviousResult } from "../redux/action/setPreviousResult";

function HomePage() {
  const dispatch = useDispatch();
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const HomeMainTitle = useFeatureFlag("Home_Page_Main_Title");
  const HomePageDescription = useFeatureFlag("Home_Page_Description");
  const Referrals_mandatory_sentance = useFeatureFlag("_referrals_mandatory");
  const Software_Personnel_Title = useFeatureFlag("Software_Personnel_Title");
  const Refer_Link_Description_Write_up = useFeatureFlag(
    "Refer_Link_Description_Write_up"
  );
  const Admin_Access = useFeatureFlag("Admin_Access");
  const allowedEmails = JSON.parse(Admin_Access);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isLoggedIn = useSelector(
    (state) => state.userReducer?.isLoggedIn || false
  );
  const clickedSocialMediaLogin = sessionStorage.getItem(
    "clickedSocialMediaLogin"
  );
  const userRefId = useSelector((state) => state.userReducer?.userRefId || "");
  const [shareLink, setShareLink] = useState(
    `${process.env.REACT_APP_BASE_URL}/signin`
  );
  const MaintenanceMessage = useFeatureFlag("Maintenance_Break");
  const [openResponsiveDialog, setResponsiveDialog] = React.useState(false);
  const [openTrueEdge, setOpenTrueEdge] = React.useState(false);
  const resultData = useSelector(
    (state) => state?.ResultReducer?.previousResult
  );
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const [location, setLocation] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );

  const updateReferBy = async () => {
    const payload = {
      email: getEmail(),
      registerTime: new Date(Date.now()).toUTCString(),
      lastLoginTime: new Date(Date.now()).toUTCString(),
      termsCondition: true,
      referBy: localStorage.getItem("referBy"),
      referTime: new Date(Date.now()).toUTCString(),
    };
    await googleUserLogin(payload, dispatch, setUserStatus);
    localStorage.removeItem("loggedInUserReferBy");
  };

  // useEffect(() => {
  //   // dispatch(setUserResult(""));
  //   // const IncognitoMode = localStorage.getItem("IncognitoUser");
  //   // if (IncognitoMode === "true") {
  //   //   dispatch(setIncognitoMode(true));
  //   // } else {
  //   //   dispatch(setIncognitoMode(false));
  //   // }
  //   // dispatch(setSupportEmail(allowedEmails))
  //   // console.log(allowedEmails,"allowedEmails")
  // }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success("Copied");
  };

  const [videoLoaded, setVideoLoaded] = React.useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };
  useEffect(() => {
    if (isLoggedIn === "true") {
      getPreviousResult(
        dispatch,
        setUserStatus,
        setResultPageSections,
        setUserPreviousResult
      );
      getResultPageCarousel(dispatch, setResultPageCarousel);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);

  useEffect(() => {
    var scalar = 5;
    // Dollar shape
    const dollar = confetti.shapeFromText({
      text: `🤑`,
      scalar,
    });
    // Rupee shape
    const notes = confetti.shapeFromText({
      text: "💸",
      scalar,
    });

    // Euro shape
    const symbol = confetti.shapeFromText({
      text: "💲",
      scalar,
    });

    const building = confetti.shapeFromText({
      text: "🌃",
      scalar,
    });
    const moneyBag = confetti.shapeFromText({
      text: "💰",
      scalar,
    });
    const pound = confetti.shapeFromText({
      text: "💷",
      scalar,
    });

    const defaults = {
      // spread: 180,

      // // startVelocity: 10,
      // scalar,
      // origin: { y: -0.1 },
      // startVelocity: -35,
      spread: 360,
      ticks: 150,
      gravity: 0,
      decay: 0.95,
      startVelocity: 20,
      scalar,
    };

    // Add confetti with custom shapes
    // confetti({
    //   ...defaults,
    //   particleCount: 20,
    //   shapes: [dollar],

    //   flat: true,
    // });
    // confetti({
    //   ...defaults,
    //   particleCount: 20,
    //   shapes: [notes],

    //   flat: true,
    // });

    // remove animation due to heavy memory usage
    // if (
    //   isLoggedIn !== "true" &&
    //   (deviceInfo?.browser === "Chrome" || deviceInfo?.browser === "Edge")
    // ) {
    //   confetti({
    //     spread: 180,
    //     scalar,
    //     origin: { y: 0.6 },
    //     particleCount: 450,
    //     shapes: [symbol, dollar, notes, moneyBag, pound],

    //   });
    // }
  }, [isLoggedIn, deviceInfo]);

  const users = 3000;
  const countries = 20;
  const cities = 190;
  const techWorthUsers = 1500;

  const JoyrideSteps = [
    {
      target: ".root",
      content: (
        <>
          <Box>
            <Typography variant="h5" align="center" gutterBottom>
              🌟 Welcome to TrueSelfy! 🌟
            </Typography>
            <Typography variant="body1" align="center">
              Discover your Tech Worth – measure your tech skills against
              earnings. Unveil your value in the tech world! 🚀
            </Typography>
          </Box>
        </>
      ),
    },
    {
      target: ".tech-worth-example",
      content: (
        <>
          <Box>
            <Typography variant="h5" align="center" gutterBottom>
              🌟 Welcome to TrueSelfy! 🌟
            </Typography>
            <Typography variant="body1" align="center">
              Discover your Tech Worth – measure your tech skills against
              earnings. Unveil your value in the tech world! 🚀
            </Typography>
          </Box>
        </>
      ),
    },
    {
      target: ".tech-value-smart-salary",
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              💻<strong>TechValue </strong> Curious about your tech prowess?
              TrueSelfy calculates your TechValue based on primary skills,
              experience, company type, industry, communication, and management
              skills! Let's unveil your expertise! 💼💡
            </Typography>
            <Typography variant="body1">
              💰 <strong>SmartSalary </strong> Wondering about your earning
              potential? TrueSelfy's SmartSalary considers inflation, location,
              country, job market, and current trends to give you a fair
              assessment! Let's navigate the salary landscape together! 💸🗺️
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      target: ".techworth-zone",
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            🌟 TechWorth Zone ! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Wondering where your job stands? Our Zone Dial tells you if you're
            in Danger, Overpaid, Ideal, Attrition, or Underpaid based on your
            TechWorth! Let's find your perfect fit! 💼💰
          </Typography>
        </Box>
      ),
    },
    {
      target: ".refer-a-friend",
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Refer a Friend and Unlock Their Earning Potential! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Share with friends and colleagues to help them discover their
            deserved salary based on skills, expertise, and experience. Let's
            empower each other! 💬💼🚀
          </Typography>
        </Box>
      ),
    },
    {
      target: ".calculate-your-tech-worth",
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Ready to unveil your TechWorth ? 🌟
          </Typography>
          <Typography variant="body1">
            Click the button to calculate your TechWorth and see the TrueSelfy
            Algorithm magic ✨💥
          </Typography>
        </Box>
      ),
    },
  ];
  const [run, setRun] = useState(false);

  // Dialog Component state and handler here

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    // handle confirm logic here
    handleClose();
  };

  const checkUser = async () => {
    try {
      var currentSession = null;
      currentSession = await Auth.currentSession();
      // await setAuthState(currentSession);
      // setupAuthInterceptor(getAuthState);
      // dispatch(
      //   setUserStatus({
      //     isLoggedIn: sessionStorage.getItem("isLoggedIn"),
      //     userRefId: sessionStorage.getItem("userRefId"),
      //     uniqueReferredUserId: sessionStorage.getItem("uniqueReferredUserId"),
      //   })
      // );
      // sessionStorage.setItem("clickedSocialMediaLogin", false);
    } catch (e) {
      localStorage.clear();
      sessionStorage.setItem("isLoggedIn", false);
      // sessionStorage.clear();
      // setOpenSnackbar(true);
      // setSnackbarSeverity("warning");
      // setSnackbarMsg("you are not logged in");
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      // do something
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      //setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      // setRun(false);
      const element = document?.getElementById(
        "calculate-your-tech-worth-button"
      );
      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    // console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    // console.groupEnd();
  };
  useEffect(() => {
    // customGoogleUserLogin(dispatch, uniqueReferredUserId);
    if (isLoggedIn === "true" && !IncognitoModeStatus) {
      setShareLink(
        `${process.env.REACT_APP_BASE_URL}/signin?referBy=${userRefId}`
      );
    } else {
      setShareLink(`${process.env.REACT_APP_BASE_URL}/signin`);
    }
    const loggedInUserReferBy = localStorage.getItem("loggedInUserReferBy");
    if (loggedInUserReferBy === "true") {
      updateReferBy();
    }
    checkUser();
    // if (isLoggedIn === "true") {
    const joyRide = setTimeout(() => {
      // console.log("joyride called");
      if (isLoggedIn !== "true") {
        setRun(true);
      }
    }, 3000);
    return () => clearTimeout(joyRide);
    // }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn === "true" && !IncognitoModeStatus) {
      setShareLink(
        `${process.env.REACT_APP_BASE_URL}/signin?referBy=${userRefId}`
      );
    } else {
      setShareLink(`${process.env.REACT_APP_BASE_URL}/signin`);
    }
    // console.log(isLoggedIn === "true", "isLoggedIn");
  }, [userRefId, isLoggedIn]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactGA4.send({
      hitType: "pageview",
      page: "/home",
      title: "visited Home",
    });
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const navigate = useNavigate();
  const competencies = [
    "Technical skills",
    "Design and architecture",
    "Management and leadership",
    "Creativity and innovation",
    "Industry-specific expertise",
    "Interpersonal communication",
    "Process optimization",
    "Quality Assurance",
    "Product design and development",
    "Network Management and security",
    "Digitalization and Cloud computing",
    "AI and Machine learning",
  ];

  const salaryObject = [
    {
      index: 0,
      mainHeading: "SmartSalary",
      subHeading: "(TechValue based)",
      description: () => {
        return (
          <Typography sx={{ textAlign: "center" }}>
            The fair salary one should expect based on the value you bring to
            the table
          </Typography>
        );
      },
    },
    {
      index: 1,
      mainHeading: "TechValue",
      subHeading: "",
      description: () => {
        return (
          <Typography sx={{ textAlign: "center", pt: 1 }}>
            Value you bring to the table based on your
            <span style={{ fontWeight: 650, color: "#808080" }}>
              {" "}
              unique combination{" "}
            </span>
            of&nbsp;
            <span style={{ fontWeight: 650, color: "#808080" }}>
              skills, abilities, and expertise{" "}
            </span>
          </Typography>
        );
      },
    },

    {
      index: 2,
      mainHeading: "TechWorth",
      subHeading: "",
      description: () => {
        return (
          <Typography sx={{ textAlign: "center", pt: 1 }}>
            Your perceived worth is based on your value, as seen by the
            organization you work for.
          </Typography>
        );
      },
    },
  ];
  const reviewData = [
    {
      id: 0,
      name: "Rajdeep Singh",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7149663970931417088/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7149663970931417088%29",
      designation:
        "Chief Architect | Head of TAG (Technology Architecture Group",
      text: "TrueSelfy not only provides valuable tech trends but also delivers a comprehensive understanding of my technology skills' market worth. 🌐💰Kudos to Vucaware for trying out some thing different in similar space! 👏",
    },
    {
      id: 1,
      name: "Alok hota",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7127182559309926400/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7127182559309926400%29",
      designation: "Information Technology leader",
      text: "Just took a short survey from #TrueSelfy to gauge my TechWorth.. It was surprising to see the results close to a variance of 3% Awestruck !!",
    },
    {
      id: 2,
      name: "Sauneet Kumar",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7065216415657541632/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7065216415657541632%29",
      designation: "Director at UBS",
      text: "Now you can recommend buddy professional connections in TrueSelfy.. beautifully done..",
    },
    {
      id: 3,
      name: "Prasana Iyengar",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7155038855568543744?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7155038855568543744%29",
      designation: "SRM at Google",
      text: "Valuable tool in these times where there's a lot of upheaval at employers with the uncertainty and promise of efficiency the chatgpt/GenAI has introduced for the last 15 months.",
    },
    {
      id: 4,
      name: "Yogesh Thite",
      link: "https://www.linkedin.com/posts/startupconsultantyogeshthite_startups-businessdating-networking-activity-7156843265995030528-PGNr?utm_source=share&utm_medium=member_desktop",
      designation: "Founder: Entie & Meticulous B-Plans",
      text: "I am personally curious about this startup. The CIBIL is for your financial credibility, TrueSelfy is for your professional credibility.",
    },
  ];

  const actions = [
    { icon: <FileCopyIcon onClick={handleCopyToClipboard} />, name: "Copy" },
    {
      icon: (
        <RWebShare
          data={{
            text: Refer_Link_Description_Write_up,
            url: shareLink,
            title:
              "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <ShareIcon />
        </RWebShare>
      ),
      name: "Share",
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <Joyride
        steps={JoyrideSteps}
        run={run}
        showSkipButton
        continuous
        disableCloseOnEsc
        disableOverlayClose
        disableScrolling
        callback={handleJoyrideCallback}
      />
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "flex-end",
          alignItems: "flex-end",
          mt: { xs: "108px", sm: "70px", md: "80px", lg: "80px", xl: "56px" },
        }}
        // spacing={2}
        // p={{
        //   xs: "40px 20px",
        //   sm: "30px 50px",
        //   md: "30px 50px",
        //   lg: "40px",
        //   xl: "40px 80px 30px",
        // }}
        // mt={{ lg: "50px" }}
      >
        <Grid item xs={12} sx={{}}>
          <Box
            sx={{
              position: "relative",
              mb: { xs: "0px", md: "150px" },
              pb: { xs: "40px", md: "160px" },
              backgroundColor: "rgba(35,28,79,0.4)",
            }}
          >
            {/* <Box className="overlay"></Box> */}
            {!videoLoaded && (
              <img
                src="../../assets/preload-image.png" // Path to your preload image
                alt="Preload Image"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  zIndex: -1, // Ensure preload image appears above video
                }}
                loading="lazy"
              />
            )}
            <video
              id="home-video"
              autoPlay
              loop
              muted
              onLoadedData={handleVideoLoaded} // Call handleVideoLoaded when video is loaded
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1, // Ensure video appears behind preload image
              }}
            >
              <source src="../../assets/homepage.mp4" type="video/mp4" />
            </video>
            <Grid
              display={"flex"}
              alignItems="center"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
              }}
              sx={{ pb: { xs: 1, lg: 2 } }}
              pt={{ xs: 15, sm: 20, md: 20, lg: 25 }}
              order={{ xs: 3, sm: 3, lg: 1 }}
              item
              className="Software_Personnel_Title"
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  mb: 0,
                  textAlign: { xs: "center", md: "center", lg: "center" },
                  color: "white",
                }}
              >
                {Software_Personnel_Title}
              </Typography>
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                spacing={2}
                flexWrap={"wrap"}
                alignItems={"center"}
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "center",
                }}
              >
                <img
                  src={"../../assets/images/flags/india.svg"}
                  style={{ minWidth: "60px" }}
                  alt="India"
                  title={"India"}
                  loading="lazy"
                />
                <img
                  src={"../../assets/images/flags/unitedstates.svg"}
                  style={{ minWidth: "60px" }}
                  alt="United States"
                  title={"United States"}
                  loading="lazy"
                />
                <img
                  src={"../../assets/images/flags/unitedkingdom.svg"}
                  style={{ minWidth: "60px" }}
                  alt="United Kingdom"
                  title={"United Kingdom"}
                  loading="lazy"
                />
                <img
                  src={"../../assets/images/flags/canada.svg"}
                  style={{ minWidth: "60px" }}
                  alt="Canada"
                  title={"Canada"}
                  loading="lazy"
                />
                <img
                  src={"../../assets/images/flags/Australia.svg"}
                  style={{ minWidth: "60px" }}
                  alt="Australia"
                  title={"Australia"}
                  loading="lazy"
                />
                <img
                  src={"../../assets/images/flags/newzeland.svg"}
                  style={{ minWidth: "60px" }}
                  alt="New Zealand"
                  title={"New Zealand"}
                  loading="lazy"
                />
                <img
                  src={"../../assets/images/flags/germany.svg"}
                  style={{ minWidth: "60px" }}
                  alt="Germany"
                  title={"Germany"}
                  loading="lazy"
                />
              </Stack>
            </Grid>
            <Grid
              item
              order={{ xs: 1, sm: 1, lg: 2 }}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { lg: "inherit", xl: "64px" },
                  mb: { xs: "20px", lg: "28px" },
                  color: "white",
                  lineHeight: { xs: "44px", lg: "74px", xl: "74px" },
                  maxWidth: {
                    xs: "444px",
                    sm: "555px",
                    md: "666px",
                    lg: "777px",
                    xl: "777px",
                  },
                }}
                textAlign={{ xs: "center", xl: "center" }}
                component="h1"
                variant="h1"
                // gutterBottom
              >
                {HomeMainTitle}
              </Typography>
            </Grid>
            <Grid
              item
              order={{ xs: 3, sm: 3, lg: 2 }}
              display={{
                xs: "flex",
                md: "flex",
                lg: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              textAlign={{ xs: "center" }}
            >
              <Typography
                // variant="h6"
                sx={{
                  fontSize: `${isMobile ? "16px !important" : "24px"}`,
                  color: "white",
                  maxWidth: {
                    xs: "400px",
                    sm: "634px",
                    md: "734px",
                    lg: "834px",
                    xl: "934px",
                  },
                }}
                // variant="body1"
              >
                {HomePageDescription}
              </Typography>
            </Grid>
            {Referrals_mandatory_sentance && (
              <Grid
                item
                order={{ xs: 2, sm: 2 }}
                display={"flex"}
                alignItems={{
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                }}
                flexDirection={"column"}
                justifyContent={{
                  xs: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "left",
                }}
                textAlign={{
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                }}
              >
                {Referrals_mandatory_sentance ? (
                  <Typography sx={{ mt: "10px", mb: "10px" }}>
                    Be ready with 3 referrrals prior to clicking "Calculate your
                    worth" - You may have to provide references to validate your
                    expertise.
                  </Typography>
                ) : null}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              // sx={{
              //   display: "flex",
              //   flexWrap: "wrap",
              //   gap: 2,
              //   alignItems: "center",
              //   mt: "50px",
              //   mb: "20px",
              // }}
              // flexDirection={{ xs: "center", md: "center", lg: "flex-start" }}
              // justifyContent={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", md: "column", lg: "row" }}
                // justifyContent={"space-around"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ mt: "20px", mb: "0px" }}
                id={"calculate-your-tech-worth-button"}
              >
                <Box p={1} className={"calculate-your-tech-worth"}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#0D9B21",
                      "&:hover": {
                        backgroundColor: "#fff !important",
                        color: "#0D9B21",
                        borderColor: "#fff",
                      },
                    }}
                    onClick={() => {
                      ReactGA.event({
                        category: "calculate",
                        action: "calculate-action",
                        label: "calculate-label",
                      });
                      if (MaintenanceMessage != "undefined") {
                        setResponsiveDialog(true);
                      } else {
                        navigate("/skills-calculation");
                        saveTargetPath(window.location.pathname);
                      }
                    }}
                  >
                    {CalculateButtonValue}
                  </Button>
                </Box>

                {/* <Box p={1} sx={{ textAlign: { xs: "center", lg: "left" } }}>
                  <Link
                    // href={"/changelog"}
                    sx={{ cursor: "pointer", textAlign: { xs: "center", lg: "left" } }}
                    onClick={() => navigate("/changelog")}
                    color="primary"
                  >
                    TrueSelfy TechWorth Alogrithm Evolution
                  </Link>
                </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ py: 2 }}
              >
                {
                  <Link
                    component="a"
                    href="/reassess-techvalue"
                    target="_blank"
                    rel="noopener noreferrer"
                    display={
                      window.location.pathname === "/reassess-techvalue"
                        ? "none"
                        : "inline-block"
                    }
                    sx={{
                      textDecoration: "underline",
                      color: "#FFC218",
                      fontSize: { xs: 16, md: 19 },
                      cursor: "pointer",
                      // "&:hover": {
                      //   color: "#26801E",
                      // },
                    }}
                  >
                    When to Recalculate TechWorth?
                  </Link>
                }
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ py: 2 }}
              >
                <RWebShare
                  data={{
                    text: Refer_Link_Description_Write_up,
                    url: shareLink,
                    title:
                      "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <Button
                    className={"refer-a-friend"}
                    sx={{
                      backgroundColor: "#fff",
                      display: "flex",
                      pl: "10px !important",
                      pr: "15px !important",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "#0D9B21 !important",
                        color: "#fff",
                        borderColor: "#0D9B21",
                      },
                    }}
                    disabled={isLoggedIn && userRefId === "" ? true : false}
                  >
                    <ReplyTwoToneIcon
                      sx={{
                        // mr: "5px",
                        mb: "5px",
                        transform: "rotateY(-180deg)",
                      }}
                    />
                    Help Your Friends
                  </Button>
                </RWebShare>
                <TooltipComponent
                  size="small"
                  message={
                    "People who are looking for a change, just underpaid, job hunting, recently laid off, etc."
                  }
                />
              </Box>
            </Grid>
            {isLoggedIn &&
            resultData !== undefined &&
            IncognitoModeStatus === false ? (
              <Grid item xs={12} sx={{ pb: 3, pt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                    // mb: { md: "20px", lg: "0px", xl: "0px" },
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <Button
                    component="a"
                    variant="outlined"
                    endIcon={<KeyboardArrowRight />}
                    sx={{
                      background: "#0BA2FF",
                      maxWidth: "270px",
                      width: "100%",
                      p: "10px 12px",
                      borderRadius: "15px",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      color: "#FFF",
                      textAlign: { xs: "center", md: "left" },
                      "&:hover": {
                        border: "1.5px solid #FFF",
                        color: "#0BA2FF",
                        background: "#FFF",
                      },
                    }}
                    target="_blank"
                    href="/previous-result"
                  >
                    Check Previous result
                  </Button>

                  {resultData?.coverLetter !== "" && (
                    <Button
                      variant="outlined"
                      endIcon={<KeyboardArrowRight />}
                      sx={{
                        background: "#0BA2FF",
                        maxWidth: "270px",
                        width: "100%",
                        p: "10px 12px",
                        borderRadius: "15px",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        color: "#FFF",
                        textAlign: { xs: "center", md: "left" },
                        "&:hover": {
                          border: "1.5px solid #FFF",
                          color: "#0BA2FF",
                          background: "#FFF",
                        },
                      }}
                      onClick={() => setOpenTrueEdge(true)}
                    >
                      Check Previous TrueEdge
                    </Button>
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ pb: 3, pt: 1 }}>
                {isLoggedIn && resultData === undefined && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                      // mb: { md: "20px", lg: "0px", xl: "0px" },
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    {/* <Skeleton variant="circular" width={40} height={40} /> */}
                    <SkeletonLoader
                      type="rect"
                      variant="rounded"
                      width={300}
                      height={50}
                      backgroundColor="#d3d3dc"
                    />

                    <SkeletonLoader
                      type="rect"
                      variant="rounded"
                      width={300}
                      height={50}
                      backgroundColor="#d3d3dc"
                    />
                  </Box>
                )}
              </Grid>
            )}
            <Box
              sx={{
                // border: "2.5px solid #2BB344",
                borderRadius: "12px",
                padding: "20px",
                // paddingTop: { xs: 10, lg: "calc(100vh - 850px)" },
                position: "absolute",
                // top: { xs: 0, md: 10, sm: 10, lg: 10, xl: 10 },
                display: { xs: "none", md: "flex" },
                // mb: 5,
                bottom: "-21%",
                width: "100%",
                zIndex: 97,
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {salaryObject?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      padding: "8px !important",
                      maxWidth: { xs: "285px", lg: "400px" },
                    }}
                  >
                    <Box
                      sx={{
                        padding: { xs: "15px", lg: "25px" },
                        display: "flex",
                        flexDirection: "column !important",
                        borderRadius: "26px",
                        alignItems: "center",
                        minHeight: "274px",
                        justifyContent: "center !important",
                        background: "#fff",
                      }}
                      className="gradient-border-mask"
                    >
                      <Box>
                        {/* <CheckCircleIcon
                      sx={{
                        color: "#2BB344",
                        mr: 0.5,
                        fontSize: "32px !important",
                      }}
                    /> */}
                        <img
                          src={`../../assets/images/${item?.mainHeading}.svg`}
                          style={{ maxWidth: "64px", height: "100%" }}
                          alt={`${item?.mainHeading}`}
                          loading="lazy"
                        />
                      </Box>
                      <Box sx={{ pt: 1.5 }}>
                        <Typography
                          variant="h3"
                          sx={{
                            color: "#232323",
                            // fontSize: `${isMobile ? "20px !important" : "28px"}`,
                            maxWidth: "175px",
                            fontWeight: 600,
                          }}
                        >
                          {item?.mainHeading}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            pb: 2,
                          }}
                        >
                          {item?.subHeading}
                        </Typography>
                      </Box>

                      <Box>
                        {/* <Typography
                          sx={{
                            textAlign: "center",
                            maxWidth: "290px",
                            color: "#808080",
                          }}
                        > */}
                        {item.description()}
                        {/* </Typography> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              // border: "2.5px solid #2BB344",
              borderRadius: "12px",
              padding: "20px",
              // paddingTop: { xs: 10, lg: "calc(100vh - 850px)" }
              // top: { xs: 0, md: 10, sm: 10, lg: 10, xl: 10 },
              display: { xs: "flex", md: "none" },
              mt: { xs: "-16%", sm: "0px" },
              // bottom: "-21%",
              width: "100%",
              // zIndex: 99,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {salaryObject?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "8px !important",
                    maxWidth: { xs: "285px", lg: "400px" },
                  }}
                >
                  <Box
                    sx={{
                      padding: { xs: "15px", lg: "25px" },
                      display: "flex",
                      flexDirection: "column !important",
                      borderRadius: "26px",
                      alignItems: "center",
                      minHeight: "274px",
                      justifyContent: "center !important",
                      background: "#fff",
                    }}
                    className="gradient-border-mask"
                  >
                    <Box>
                      {/* <CheckCircleIcon
                      sx={{
                        color: "#2BB344",
                        mr: 0.5,
                        fontSize: "32px !important",
                      }}
                    /> */}
                      <img
                        src={`../../assets/images/${item?.mainHeading}.svg`}
                        style={{ maxWidth: "64px", height: "100%" }}
                        alt={`${item?.mainHeading}`}
                        loading="lazy"
                      />
                    </Box>
                    <Box sx={{ pt: 1.5 }}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#232323",
                          // fontSize: `${isMobile ? "20px !important" : "28px"}`,
                          maxWidth: "175px",
                          fontWeight: 600,
                        }}
                      >
                        {item?.mainHeading}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          pb: 2,
                        }}
                      >
                        {item?.subHeading}
                      </Typography>
                    </Box>

                    <Box>
                      {/* <Typography
                          sx={{
                            textAlign: "center",
                            maxWidth: "290px",
                            color: "#808080",
                          }}
                        > */}
                      {item.description()}
                      {/* </Typography> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            py: { xs: "20px", sm: "45px", md: "85px" },
            mt: { xs: "0px", sm: "40px", lg: "80px" },
            background: "#175512",
          }}
        >
          <CssBaseline />
          <Banner
            users={users}
            countries={countries}
            cities={cities}
            techWorthUsers={techWorthUsers}
          />
        </Grid>
        <Grid item xs={12} sx={{ p: "30px 30px" }}>
          <Box>
            <Typography
              sx={{
                fontSize: { lg: "inherit", xl: "44px" },
                fontWeight: 600,
                pb: { xs: "20px", md: "60px" },
                pt: { xs: "10px", md: "20px" },
                backgroundImage:
                  "linear-gradient(to right, #2BB344, #FFC218, #E0011C, #3D7CCA,#0BA2FF)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
                ml: { xs: "0px", lg: "52px" },
                textAlign: { xs: "center", md: "center", lg: "center" },
              }}
              variant="h2"
            >
              Read what users say.
            </Typography>
          </Box>
          <Box>
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              breakpoints={{
                438: {
                  slidesPerView: 2,
                  // spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation
              loop={true}
              scrollbar={{ draggable: true }}
              centeredSlides="true"
              className="home-swiper"
            >
              {reviewData.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Box
                      component="div"
                      sx={{
                        // maxWidth: { xs: "394px", md: "500px", lg: "394px" },
                        // width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                        height: "100%",
                        boxSizing: "border-box",
                        background: "#FFF",
                        p: 3,
                        borderRadius: "24px",
                        // m: "0px 10px",
                      }}
                      className="gradient-border-mask"
                    >
                      <Box
                        sx={{
                          maxWidth: "24px",
                          width: "100%",
                          height: "auto",
                          borderRadius: "50%",
                          overflow: "hidden",
                          float: "right",
                          "& >img": {
                            width: "100%",
                          },
                        }}
                      >
                        <img
                          src="../../assets/images/linkedin-rounded.png"
                          alt="linkedin"
                          loading="lazy"
                        />
                      </Box>

                      <Box
                        sx={{
                          maxWidth: "26px",
                          width: "100%",
                          margin: "0px auto",
                          overflow: "hidden",
                          mb: "15px",
                          "> img": { width: "100%" },
                        }}
                      >
                        <img
                          src="../../assets/images/inverted-grey.png"
                          alt="coma"
                          loading="lazy"
                        />
                      </Box>
                      <Typography
                        className="clamp-text"
                        sx={{
                          color: "#000",
                          lineHeight: "18px",
                          mb: "10px",
                          textAlign: "center",
                          lineHeight: "25px",
                        }}
                        variant="subtitle1"
                      >
                        {item?.text}
                      </Typography>
                      <Box
                        sx={{
                          width: "56px",
                          height: "2.5px",
                          backgroundColor: "#D7D7D7",
                          m: "0px auto",
                          mb: "10px",
                          display: "block",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#000",
                          textAlign: "center",
                          mb: "10px",
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Link
                          target="_blank"
                          href={item?.link}
                          // component="button"
                          sx={{
                            display: "inline-flex",
                            gap: 1,
                            alignItems: "center",
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#0D9B21",
                            textDecorationColor: "inherit",
                          }}
                        >
                          Read full review
                          <Box
                            component="span"
                            sx={{
                              width: "16px",
                              height: "16px",
                              display: "grid",
                              placeContent: "center",
                              background: "#0D9B21",
                              borderRadius: "12px",
                              color: "#fff",
                            }}
                          >
                            {" "}
                            <KeyboardArrowRight
                              sx={{ fontSize: "16px" }}
                            />{" "}
                          </Box>
                        </Link>{" "}
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontSize: { lg: "inherit", xl: "44px" },
            fontWeight: 600,
            color: "black",
            backgroundImage:
              "linear-gradient(to right, #2BB344, #FFC218, #E0011C,#3D7CCA, #0BA2FF)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            pt: 1,
            pb: { xs: 10, md: 3, lg: 3 },
          }}
          textAlign={{ xs: "center", md: "center", sm: "center", lg: "center" }}
        >
          How your TechWorth is calculated?
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: { xs: "flex", sm: "block", md: "block", lg: "flex" },
          alignItems: "center",
          //   background: "#F4F4F4",
        }}
        flexDirection={{ xs: "column", md: "column", lg: "row" }}
        justifyContent={{
          xs: "center",
          sm: "space-evenly",
          md: "center",
          lg: "space-evenly",
        }}
        p={{
          xs: "00px 20px",
          sm: "0px 50px",
          md: "0px 50px",
          lg: "0px 40px",
          xl: "0px 60px ",
        }}
      >
        <Grid item xs={12} sm={12} lg={7.5} xl={7.5}>
          <Box
            sx={{
              mt: { sm: "20px", md: "20px", lg: "0px", xl: "0px" },
              display: "flex",
              flexDirection: { xs: "column", md: "row", lg: "row" },
              alignItems: "center",
              // padding: { xs: 3, md: 7 },
              position: "relative",

              justifyContent: "space-around",
              // border: `2px solid transparent`, // Set an initial transparent border
              // background: `linear-gradient(to right, #2BB344, #FFC218, #E0011C, #0BA2FF)`,
              // backgroundOrigin: "border-box",
              // position: "relative",
              // backgroundClip: "content-box, border-box",
            }}
            className={"tech-worth-example gradient-border-mask"}
          >
            <Box
              sx={{
                position: "absolute",
                top: {
                  xs: "-38px",
                  sm: "-38px",
                  md: "-38px",
                  lg: "-38px",
                  xl: "-38px",
                },
                left: { xs: "50%", sm: "50%", md: "50%", lg: "7%", xl: "7%" },
                transform: {
                  xs: "translateX(-50%)",
                  sm: "translateX(-50%)",
                  md: "translateX(-50%)",
                  lg: "translateX(0%)",
                },
                background: "#2BB344",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                px: 6,
                py: 1,
                color: "#fff",
              }}
            >
              <Typography>Example</Typography>
            </Box>
            <Box>
              <Box pb={3} xs={12} className={"techworth-formula"}>
                <img
                  style={{ maxWidth: "100%" }}
                  src={`../../assets/images/techWorthV2.svg`}
                  alt="TechWorth Formula"
                  loading="lazy"
                />
              </Box>
              <Box xs={12} className={"tech-value-smart-salary"}>
                <img
                  style={{ maxWidth: "100%" }}
                  src={`../../assets/images/smartSalaryV2.svg`}
                  alt="techvalue&marketsalary"
                  loading="lazy"
                />
              </Box>
            </Box>
            <Box sx={{ ml: { lg: 6 } }} className={"techworth-zone"}>
              <img
                style={{ maxWidth: "100%" }}
                src={`../../assets/images/techworthdialV2.svg`}
                alt="techworthdial"
                loading="lazy"
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          lg={3}
          xl={3}
          sx={{
            display: { xs: "block", md: "flex", lg: "flex", xl: "block" },
            justifyContent: { md: "center", lg: "center" },
            pt: { xs: 4, sm: 4, md: 4, lg: 4, xl: 0 },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: 2,
              borderRadius: "12px",
              // flexDirection: "column",
            }}
            // display={{
            //   xs: "flex",
            //   md: "flex",
            //   sm: "flex",
            //   lg: "flex",
            //   xl: "flex",
            // }}
            className="gradient-border-mask"
            alignItems={{ xs: "left", md: "left", lg: "left", xl: "left" }}
          >
            {[
              { label: "Technology skills", count: "5000+" },
              { label: "Technology Management skills", count: "750+" },
              { label: "Functional/domain skills", count: "1000+" },
              { label: "Generic skills", count: "30000+" },
            ].map((item, index) => (
              <Box
                display={"flex"}
                alignItems={"center"}
                key={index}
                textAlign={{
                  xs: "left",
                  md: "left",
                  sm: "left",
                  lg: "left",
                }}
                py={{ xs: 0, md: 1, sm: 1, lg: 0.5, xl: 1.1 }}
                px={{ xs: 0, md: 1, sm: 1, lg: 2, xl: 1 }}
              >
                <Box
                  display={{ xs: "block", md: "block", lg: "block" }}
                  sx={{
                    px: 2,
                    py: 1,
                    maxWidth: {
                      xs: "85px",
                      sm: "85px",
                      md: "85px",
                      lg: "85px",
                      xl: "102px",
                    },
                  }}
                >
                  {/* <span className="custom-border"></span> */}
                  <img
                    src={`../../assets/images/home-icons-new/count-box/${index}.svg`}
                    style={{ width: "100%", height: "auto" }}
                    alt={`${item?.label}`}
                    loading="lazy"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "28px",
                        md: "28px",
                        lg: "28px",
                        xl: "34px",
                      },
                    }}
                  >
                    <mark>{item?.count} </mark>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "16px",
                        lg: "16px",
                        xl: "18px",
                      },
                    }}
                  >
                    {item?.label}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        p={{
          xs: "20px 20px",
          sm: "20px 50px",
          md: "20px 50px",
          lg: "30px 120px",
        }}
      >
        <Box>
          <Typography
            variant="body1"
            component="h4"
            textAlign={{
              xs: "justify",
              md: "justify",
              lg: "justify",
            }}
            sx={{
              color: "#232323",
            }}
            fontSize={{ xs: "16px", md: "24px", lg: "24px" }}
          >
            TrueSelfy’s innovative algorithm offers comprehensive evaluation of{" "}
            <mark>5000+ </mark>
            technology skills,<mark> 750+</mark> technology management skills,{" "}
            <mark>1000+</mark> functional/domain skills, and <mark>30000+</mark>{" "}
            generic skills to determine the current market value of your
            expertise. TrueSelfy considers macroeconomic impact, technological
            advancements and obsolescence, and is continuously updated with new
            competencies so that the insights you get are always current.
          </Typography>
        </Box>
      </Grid>
      <Grid
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          // position: "relative",
          // top: isMobile ? 40 : 70,
          padding: isMobile ? 1 : 3,
        }}
        item
      >
        <Typography
          // variant="h4"
          textAlign={"center"}
          sx={{
            fontWeight: "bold",
            color: "transparent",
            backgroundImage:
              "linear-gradient(to right, #2BB344, #FFC218, #E0011C, #0BA2FF)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            borderRadius: "50px",
            fontSize: `${isMobile ? "18px !important" : "34px"}`,
          }}
        >
          TrueSelfy is designed for all types of competencies
        </Typography>
      </Grid>
      <Grid
        container
        spacing={1.5}
        sx={{ mb: 5 }}
        textAlign={{ xs: "center", md: "center", lg: "center" }}
        // alignItems="center"

        p={{
          xs: "0px 8px 0px 20px",
          sm: "0px 28px 0px 40px",
          md: "10px 50px",
          lg: "20px 80px 20px 100px",
        }}
      >
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            // border: "2.5px solid #2BB344",
            borderRadius: "12px",
            padding: { xs: "5px", lg: "0px 20px" },
          }}
        >
          {competencies?.map((item, index) => (
            <Grid item xs={6} lg={2} md={4} key={index}>
              <Box
                sx={{
                  padding: { xs: "8px !important", lg: "8px !important" },
                }}
              >
                <Box
                  sx={{
                    padding: "0px !important",
                    display: "flex",
                    flexDirection: "column !important",
                    border: "2px solid transparent",
                    borderRadius: "26px",
                    // Apply gradient border color

                    alignItems: "center",
                    minHeight: "176px",
                    justifyContent: "center",
                  }}
                  className="gradient-border-mask"
                >
                  <Box sx={{ p: 2 }}>
                    {/* <CheckCircleIcon
                      sx={{
                        color: "#2BB344",
                        mr: 0.5,
                        fontSize: "32px !important",
                      }}
                    /> */}
                    <img
                      src={`../../assets/images/home-icons-new/competencies/${index}.svg`}
                      style={{ maxWidth: "64px", height: "100%" }}
                      alt={`${item}`}
                      loading="lazy"
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#232323",
                        fontSize: `${isMobile ? "14px !important" : "16px"}`,
                        maxWidth: "175px",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Box>
        <CoverLetter
          setOpen={setOpenTrueEdge}
          open={openTrueEdge}
          data={resultData}
        />
        {/* <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "nowrap", lg: "nowrap", xl: "nowrap" },
            gap: { xs: 2, xl: 2 },
            alignItems: "center",
            justifyContent: "center",
            mb: 5,
          }}
        >
          <TextField
            fullWidth
            type="text"
            value={shareLink}
            // size="small"
            InputProps={{
              readOnly: "true",
              sx: { color: "#8D8D99" },
            }}
          />

          <Box sx={{ display: "flex", gap: { xs: 2, xl: 2 }, alignItems: "center" }}>
            <Button variant="outlined" color="primary" onClick={handleCopyToClipboard}>
              {" "}
              Copy
            </Button>
            <RWebShare
              data={{
                text: Refer_Link_Description_Write_up,
                url: shareLink,
                title: "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <Button variant="contained" color="primary">
                Share
              </Button>
            </RWebShare>{" "}
          </Box>
        </Box> */}
      </Grid>

      {/* <Box
        sx={{
          position: "fixed",
          // transform: "translateZ(0px)",
          flexGrow: 1,
          alignItems: "center",
          display: "flex",
          zIndex: 9999,
          bottom: 15,
          right: "5%",
          gap: { xs: 0, md: 2, lg: 3 },
          justifyContent: "center",
        }}
      >
        <SpeedDial ariaLabel="SpeedDial basic example" icon={<ShareIcon />}>
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
            />
          ))}
        </SpeedDial>
      </Box> */}

      {openResponsiveDialog && (
        <ResponsiveDialog
          openResponsiveDialog={openResponsiveDialog}
          setResponsiveDialog={setResponsiveDialog}
          MaintenanceMessage={MaintenanceMessage}
        />
      )}

      <FooterComponent />
      {clickedSocialMediaLogin && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMsg={snackbarMsg}
          snackbarSeverity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}

export default HomePage;
