/** @format */

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Header from "../Header";
import Stepper from "@mui/material/Stepper";
import Footer from "../Footer";
import { useForm, FormProvider } from "react-hook-form";
import "./style.css";
import SingleStepForm from "./SingleStepForm";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  getResult,
  getStepperQuestionForm,
  saveUserFormData,
  getReferrals,
  getResults,
} from "./stepper.helper";
import { setReferralList } from "../../redux/action/setReferralsList";
import { useDispatch, useSelector } from "react-redux";
import { setUserResult } from "../../redux/action/result";
import { useNavigate } from "react-router-dom";
import StepReferals from "./StepReferals";
import { setLoading } from "../../redux/action/loadingRequest";
import stepAlert from "./stepAlert.json";
import toast from "react-hot-toast";
import _ from "lodash";
import Loading from "../UI/Loading";
import { getEmail } from "../../utils/storage";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { useMediaQuery } from "@mui/material";
import { Alert, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PizzaPopHover from "../common/PizzaPopHover";
import PaymentForm from "./PaymentService";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import DeviceInfoAndLocation from "../common/DeviceInfoAndLocation";
import UploadResume from "../common/ResumeUpload";

function StepperForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setStepperJsonObject] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referrals, setReferrals] = React.useState([]);
  const [oldFormObject, setOldFormObject] = React.useState([]);
  const [questionJsonObject, setQuestionJsonObject] = React.useState([]);
  const [stepperLoader, setStepperLoader] = React.useState(true);
  const flagValue = useFeatureFlag("Pre_load_user_form");
  const [open, setOpen] = React.useState(true);
  const [userFormData, setUserFormData] = React.useState();
  const [referralslist, setReferralsList] = useState();
  const [userName, setUserName] = React.useState(getEmail());
  const referralFlag = useFeatureFlag("Referrals_mandatory");
  const userEmail = getEmail();
  const basePrice = useFeatureFlag("Base_price");
  const offValue = useFeatureFlag("discount_value");
  const numberOfEvaluationResult = useFeatureFlag("numberOfEvaluationResult");
  const discountedPrice = basePrice - offValue;
  const [results, setResults] = useState(0);
  const [formData, setFormData] = useState();
  const [referralDiscountPrice, setReferralDiscountPrice] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState();
  const isMobile = useMediaQuery("(max-width:600px)");
  const resultData = useSelector(
    (state) => state?.ResultReducer?.previousResult || undefined
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      suggestedObject: {},
    },
    criteriaMode: "all",
  });

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };
  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  var filterOldFormObject = {};
  questionJsonObject.map(function (item) {
    if (oldFormObject.hasOwnProperty(item.questionName)) {
      filterOldFormObject[item.questionName] = oldFormObject[item.questionName];
    }
  });

  useEffect(() => {
    const temp = [];
    referrals?.map((referral) => {
      if (referral.email?.length > 0) {
        temp.push(referral);
      }
    });
    if (temp.length !== 0) {
      let tempformdata = formData;
      tempformdata.referrals = temp;
      // console.log("tempformdata", tempformdata);
      setFormData(tempformdata);
    }
  }, [formData, referrals]);

  useEffect(() => {
    getStepperQuestionForm(
      methods,
      setStepperJsonObject,
      setQuestionJsonObject,
      setStepperLoader,
      navigate,
      toast,
      _.isEmpty,
      flagValue,
      setUserFormData,
      dispatch
    );
    getReferrals(setReferralsList, dispatch, setReferralList);
    getResults(setResults);
  }, []);

  /* Dummy Use Effect to active skill and certificate lambda */

  //Google Analytics events and pageview
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactGA4.send({
      hitType: "pageview",
      page: "/skills-calculation",
      title: "visited StepperForm",
    });
  }, []);


  const handleSteps = (step) => {
    // toast.success(stepAlert[step].alertMessage);
    ReactGA.event({
      category: "stepperForm",
      action: "visited",
      label: steps[step].stepLabel,
    });
    ReactGA4.event({
      category: "stepperForm",
      action: "visited",
      label: steps[step].stepLabel, // optional
    });
    return step === 7 ? (
      <>
        <StepReferals
          setReferrals={setReferrals}
          questionsObject={questionJsonObject}
          step={steps[step]}
          discountedPrice={discountedPrice}
          referralslist={referralslist}
          setReferralDiscountPrice={setReferralDiscountPrice}
          setInvoiceDetails={setInvoiceDetails}
          results={results}
        />
        {/* <PaymentForm /> */}
      </>
    ) : step === 1 ? (
      <>
        <UploadResume DataDecryption={DataDecryption} />
      </>
    ) : (
      <>
        <SingleStepForm
          questionsObject={questionJsonObject}
          activeStepObject={steps[step]}
        />
        {/* {toast.success(stepAlert[step + 1]?.alertMessage)} */}
      </>
    );
  };
  const [finalResult, setFinalResult] = useState({
    marketSalary: "",
    techValue: "",
    techworth: "",
  });
  // const [extraDeviceInfo, setExtraDeviceInfo] = useState({
  //   userlocation: "",
  //   deviceinfo: "",
  // });

  const [location, setLocation] = useState();
  const [deviceInfo, setDeviceInfo] = useState();

  useEffect((item) => {
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);
  const [lastStep, setLastStep] = useState(0);

  const handleNext = async (data) => {
    /* remove "",null,undefind value from data object */
    data = _.pickBy(data, (v) => v !== null && v !== undefined && v !== "");
    data.suggestedObject = _.pickBy(
      data.suggestedObject,
      (v) => v !== null && v !== undefined && v !== ""
    );
    const temp = [];
    referrals?.map((referral) => {
      if (referral.email?.length > 0) {
        temp.push(referral);
      }
    });
    if (temp.length !== 0) {
      data.referrals = temp;
    }
    if (activeStep >= 0) {
      setUserName(data.name);
    }
    data.deviceinfo = deviceInfo;
    data.userlocation = location;
    data.resultTime = new Date(Date.now()).toUTCString();
    setFormData(data);

    if (activeStep >= lastStep) {
      setLastStep(activeStep);
      data.lastStepper = steps[activeStep].stepLabel;
    }
    if (activeStep < 7) {
      saveUserFormData(data, dispatch);
    }
    // referralFlag - referralslist && referral.length < referralLength
    //1-25=-24>0  && 0 < 3 = true error show
    //3-1=2>0  && 1 < 2 = true error show
    //3-2=1>0  && 1 < 1 = false submit
    //3-3=0>0  && 1 < 0 = false submit
    //3-4=-1>0 && 1 < 3 = false submit
    //3-5=-2>0 && 1 < 3 = false submit

    //23-23=0 > 0 && 1 < 3 = false submit
    //24-23=1 > 0 && 1 < 1 = false error show
    // const referralLength = referralFlag - referralslist?.referrals?.length;
    //activeStep === 7 && referralLength > 0 && referrals?.length <= referralLength
    if (referrals?.length < referralFlag && activeStep === 7) {
      // setOpenAlert(true);
      toast.error(`Refer at least ${referralFlag} colleague to proceed`);
    } else {
      if (
        referrals[0]?.email.length === 0 &&
        referralFlag == 1 &&
        activeStep === 7 &&
        referralslist?.referrals?.length < numberOfEvaluationResult
      ) {
        toast.error(`Refer at least ${referralFlag} colleague to proceed`);
      } else {
        var refEmail = referrals?.map(function (item) {
          return item.email;
        });
        var dup = refEmail?.some(function (item, index) {
          return refEmail?.indexOf(item) != index;
        });
        if (dup && activeStep === 7) {
          // setOpenAlert(true);
          // setAlertMessage(" Ypu have entered duplicate emails");
          toast.error("You have entered duplicate emails");
        } else {
          if (activeStep === steps.length - 1) {
            dispatch(setLoading(true));
            getResult(
              data,
              setFinalResult,
              dispatch,
              setUserResult,
              setLoading,
              navigate
            );
            delete data.formFinish;
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          toast.success(stepAlert[activeStep]?.alertMessage);
        }
      }
    }
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <>
        {stepperLoader ? (
          <Loading />
        ) : (
          <>
            {userFormData && flagValue && (
              <Snackbar
                open={open}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                autoHideDuration={7000}
                onClose={handleClose}
                action={action}
              >
                <Alert
                  onClose={handleClose}
                  variant="filled"
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="subtitle1">
                    Data from your previous session is displayed for your
                    convenience. You may change the desired values to
                    re-calculate your results
                  </Typography>
                </Alert>
              </Snackbar>
            )}
            <Header />
            <Container
              maxWidth="x2"
              component="main"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <Grid
                container
                justifyContent="center"
                sx={{
                  // height: "100%",
                  // overflow: "scroll",
                  minHeight: `calc(100vh)`,
                  // height:'100%'
                  mt: { xs: 3, sm: 7, md: 6, lg: 13 },
                }}
                display={"flex"}
                flexDirection="row"
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                  md={6}
                  sm={12}
                  sx={
                    {
                      // pt: { xs: "none", md: 30, lg: 40 },
                      // pr: { xs: "none", md: 30, lg: 30 },
                      // pb: 80,
                      // overflow: "hidden",
                    }
                  }
                  // display="flex"
                  // flexDirection="column"
                  // className="pizza-holder-main"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      boxSizing: "border-box",
                      position: "relative",
                      height: { xs: "500px", sm: "600px", md: "100%" },
                      pb: "21px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "29%",
                        left: { xs: "28%", sm: "38%", md: "20%", lg: "35%" },
                      }}
                    >
                      <Stepper
                        className="image-holder"
                        activeStep={activeStep}
                        type={"submit"}
                        form={"validationForm"}
                        // overflow="auto"
                      >
                        {steps?.map((stepObj, stepIndex) => {
                          const stepProps = {};
                          // if (isStepOptional(stepIndex)) {
                          //   labelProps.optional = (
                          //     <Typography variant="caption"></Typography>
                          //   );
                          //   labelProps.optional = (
                          //     <Typography variant="caption"></Typography>
                          //   );
                          // }
                          return (
                            <>
                              {stepIndex <= activeStep ? (
                                <Typography
                                  component="button"
                                  variant="span"
                                  key={stepIndex}
                                  {...stepProps}
                                  onClick={() => handleStep(stepIndex)}
                                  className={`step-img-wrap ${
                                    stepIndex === activeStep
                                      ? "step-active"
                                      : ""
                                  }`}
                                >
                                  {stepIndex < 8 && (
                                    <>
                                      {stepIndex === activeStep ||
                                      (activeStep >= stepIndex &&
                                        activeStep >= 0) ? (
                                        <>
                                          <img
                                            className="ts-slice-img"
                                            src={`../../../assets/images/step_0.svg`}
                                            alt={stepObj.stepLabel}
                                            loading="lazy"
                                          />
                                          {/* <div className="ts-section-title">
                                        {stepObj.stepLabel}
                                      </div> */}
                                          {stepIndex === activeStep ? (
                                            <>
                                              <PizzaPopHover
                                                message={stepObj?.stepLabel}
                                                src={`../../../assets/images/pizza-step-active/${stepObj.stepLabel.replace(
                                                  /\s+/g,
                                                  ""
                                                )}.svg`}
                                                alt={stepObj.stepLabel}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <PizzaPopHover
                                                message={stepObj?.stepLabel}
                                                src={`../../../assets/images/pizza-active-icon/${stepObj.stepLabel.replace(
                                                  /\s+/g,
                                                  ""
                                                )}.svg`}
                                                alt={stepObj.stepLabel}
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="ts-slice-img"
                                            src={`../../../assets/images/step_1.svg`}
                                            alt={stepObj.stepLabel}
                                            loading="lazy"
                                            // onClick={() => handleStep(stepIndex)}
                                          />
                                          {/* <div className="ts-section-title">
                                        {stepObj.stepLabel}
                                      </div> */}
                                          <PizzaPopHover
                                            src={`../../../assets/images/pizza-non-active-icon/${stepObj.stepLabel.replace(
                                              /\s+/g,
                                              ""
                                            )}.svg`}
                                            alt={stepObj.stepLabel}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </Typography>
                              ) : (
                                <Typography
                                  component="button"
                                  variant="span"
                                  form={"validationForm"}
                                  type="submit"
                                  key={stepIndex}
                                  {...stepProps}
                                  //onClick={() => handleStep(stepIndex)}
                                  className={`step-img-wrap ${
                                    stepIndex === activeStep
                                      ? "step-active"
                                      : ""
                                  }`}
                                >
                                  {stepIndex < 8 && (
                                    <>
                                      {stepIndex === activeStep ||
                                      (activeStep >= stepIndex &&
                                        activeStep >= 0) ? (
                                        <>
                                          <img
                                            className="ts-slice-img"
                                            src={`../../../assets/images/step_0.svg`}
                                            alt={stepObj.stepLabel}
                                            loading="lazy"
                                          />
                                          {/* <div className="ts-section-title">
                                        {stepObj.stepLabel}
                                      </div> */}
                                          {stepIndex === activeStep ? (
                                            <>
                                              <img
                                                className="ts-slice-icon"
                                                src={`../../../assets/images/pizza-step-active/${stepObj.stepLabel.replace(
                                                  /\s+/g,
                                                  ""
                                                )}.svg`}
                                                alt={stepObj.stepLabel}
                                                loading="lazy"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="ts-slice-icon"
                                                src={`../../../assets/images/pizza-active-icon/${stepObj.stepLabel.replace(
                                                  /\s+/g,
                                                  ""
                                                )}.svg`}
                                                alt={stepObj.stepLabel}
                                                loading="lazy"
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="ts-slice-img"
                                            src={`../../../assets/images/step_1.svg`}
                                            alt={stepObj.stepLabel}
                                            loading="lazy"
                                            // onClick={() => handleStep(stepIndex)}
                                          />
                                          {/* <div className="ts-section-title">{stepObj.stepLabel}</div> */}
                                          <img
                                            className="ts-slice-icon"
                                            src={`../../../assets/images/pizza-non-active-icon/${stepObj.stepLabel.replace(
                                              /\s+/g,
                                              ""
                                            )}.svg`}
                                            alt={stepObj.stepLabel}
                                            loading="lazy"
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </Typography>
                              )}
                            </>
                          );
                        })}
                      </Stepper>
                    </Box>

                    <Box
                      sx={{
                        // pt: { sm: 0, md: 0, lg: 70 },
                        // pl: { sm: 0, md: 0, lg: 0 },
                        alignItems: "center",
                        maxWidth: "512px",
                        width: "100%",
                      }}
                      // className="user-email-box"
                    >
                      {resultData != undefined ? (
                        <Box
                          sx={{
                            top: { xs: "2%", md: "-2%", sm: "-2%", lg: "2%" },
                            left: {
                              xs: "13%",
                              sm: "30%",
                              md: "35%",
                              lg: "32%",
                            },
                            right: {
                              xs: "13%",
                              sm: "30%",
                              md: "35%",
                              lg: "0%",
                            },
                            pl: 4.5,
                            pb: 0.75,
                          }}
                        >
                          <Link target="_blank" href="/previous-result">
                            {" "}
                            Click to see your previous Result
                          </Link>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box
                        style={{
                          backgroundColor: "#DBDBDB",
                          padding: "10px",
                          // width: "100%",
                          borderTopRightRadius: "50px",
                          borderBottomRightRadius: "50px",
                          textAlign: { sm: "center", md: "center", lg: "none" },
                        }}
                        flexDirection="row"
                        display={"flex"}
                      >
                        <Typography
                          sx={{
                            // backgroundColor: "#DBDBDB",
                            pl: 3,
                            // fontSize: "18px",
                          }}
                        >
                          Evaluating for&nbsp;
                        </Typography>
                        <Typography
                          noWrap
                          sx={{
                            // pr: 3,
                            borderRadiusTopRight: 50,
                            fontWeight: "bold",
                            // fontSize: "18px",
                          }}
                        >
                          {userName}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={6}
                  md={6}
                  // sx={{ height: "100%" }}
                >
                  <Box
                    flexDirection="column"
                    display={"flex"}
                    sx={{
                      // p: 3,
                      // minHeight: { sm: "100%", md: "100vh", lg: "100vh" },
                      backgroundColor: "#F3FFEB",
                      height: "100%",
                      boxSizing: "border-box",
                      // p: "40px 40px 21px 40px",
                      pt: { xs: "25px", sm: "40px" },
                      pl: { xs: "25px", sm: "40px" },
                      pr: { xs: "25px", sm: "40px" },
                      pb: "21px",
                    }}
                    // alignItems="center"
                    // justifyContent="center"
                  >
                    <Box flex={1}>
                      {activeStep !== steps.length && (
                        <React.Fragment>
                          <FormProvider {...methods}>
                            <form
                              id="validationForm"
                              onSubmit={methods.handleSubmit(handleNext)}
                            >
                              {handleSteps(activeStep)}
                            </form>
                          </FormProvider>
                        </React.Fragment>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        pb: 0,
                        pt: 3,
                      }}
                    >
                      {isMobile ? (
                        <Button
                          // className="form-button"
                          sx={{
                            backgroundColor: "#EF4F38",
                            color: "white",
                            pl: "12px",
                            pr: "20px",
                            pb: "8px",
                            pt: "8px",
                          }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          variant="contained"
                          startIcon={<ArrowBackIosNewIcon />}
                        >
                          Back
                        </Button>
                      ) : (
                        <Button
                          className="form-button"
                          sx={{
                            backgroundColor: "#EF4F38",
                            color: "white",
                          }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          variant="contained"
                          startIcon={<ArrowBackIosNewIcon />}
                        >
                          Step Back
                        </Button>
                      )}

                      <Box sx={{ flex: "1 1 auto" }} />

                      {activeStep === 7 &&
                      referralDiscountPrice >= 0 &&
                      results >= 0 ? (
                        <PaymentForm
                          amount={referralDiscountPrice}
                          userName={userName}
                          userEmail={userEmail}
                          formData={formData}
                          invoiceDetails={invoiceDetails}
                        />
                      ) : (
                        <Button
                          className="form-button"
                          form={"validationForm"}
                          type="submit"
                          variant="contained"
                          endIcon={<ArrowForwardIosIcon />}
                        >
                          {activeStep === steps.length - 1
                            ? "Calculate"
                            : "Proceed"}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {/* Footer */}
            <Footer />
          </>
        )}
      </>
    </React.Fragment>
  );
}

export default StepperForm;
