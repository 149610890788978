import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const SkeletonLoader = ({
  type,
  width,
  height,
  variant,
  animation,
  backgroundColor,
}) => {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      animation={animation}
      sx={{
        backgroundColor: backgroundColor,
      }}
    />
  );
};

SkeletonLoader.propTypes = {
  type: PropTypes.oneOf(["text", "circle", "rect"]).isRequired, // Skeleton type
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Skeleton width
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Skeleton height
  variant: PropTypes.oneOf(["text", "circular", "rectangular"]), // MUI variant
  animation: PropTypes.oneOf(["pulse", "wave", false]), // Animation type
  backgroundColor: PropTypes.string, // Custom background color
};

SkeletonLoader.defaultProps = {
  width: "100%", // Default width
  height: 40, // Default height
  variant: "text", // Default variant
  animation: "pulse", // Default animation
  backgroundColor: "rgba(0, 0, 0, 0.1)", // Default background color
};

export default SkeletonLoader;
