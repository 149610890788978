import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import Lottie from "react-lottie";
import uploadAnimationData from "../animations/resume-parsing.json"; // Lottie animation for upload
import resultAnimationData from "../animations/0.json"; // Lottie animation for result
import apis from "../../utils/apis";

const UploadResume = ({ DataDecryption }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isFetchingResult, setIsFetchingResult] = useState(false);
  const [error, setError] = useState(null);
  const [convertedTechValue, setConvertedTechValue] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (["pdf", "docx"].includes(fileExtension)) {
        setFile(selectedFile);
        setError(null);
      } else {
        setError("Only .pdf and .docx files are allowed.");
        setFile(null);
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (["pdf", "docx"].includes(fileExtension)) {
        setFile(selectedFile);
        setError(null);
      } else {
        setError("Only .pdf and .docx files are allowed.");
        setFile(null);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    setIsUploading(true);
    setError(null);
    setConvertedTechValue(null); // Reset convertedTechValue on new upload

    const convertExperienceToMonths = (experience) => {
      if (!experience) return "12";

      const regex = /(\d+)\s*(\+?\s*years?)/i;
      const match = experience.match(regex);

      if (match) {
        const years = parseInt(match[1], 10);
        return String(years * 12);
      }

      const moreThanRegex = /more than\s+(\d+)\s*years?/i;
      const moreThanMatch = experience.match(moreThanRegex);

      if (moreThanMatch) {
        const years = parseInt(moreThanMatch[1], 10);
        return String(years * 12);
      }

      return "12";
    };

    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const fileContent = reader.result.split(",")[1]; // Extract base64 string

        // Send request to backend to parse resume
        const response = await apis.POST({
          endpoint: "/get-parse-resume",
          payload: {
            fileName: file.name,
            fileContent,
          },
          options: { type: "json" },
        });

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        const decryptionData = DataDecryption(response.data);
        const parsedData = JSON.parse(decryptionData.body);

        // Validate JSON and required fields
        let parsedResume;
        try {
          parsedResume = JSON.parse(parsedData.openAiResponse);
          console.log(parsedResume, "parseResume");

          if (
            !parsedResume.name ||
            !parsedResume.email ||
            !parsedResume.primarySkill ||
            parsedResume.primarySkill.length < 1
          ) {
            throw new Error(
              "Parsed resume does not contain valid name, email, or primary skills."
            );
          }

          // Convert the experience value to months if present
          if (parsedResume.experience) {
            parsedResume.experience = `${convertExperienceToMonths(
              parsedResume.experience
            )}`;
            parsedResume.payloadType = "resume-parsed";
            console.log(
              "Updated Experience in Months:",
              parsedResume.experience
            );
          }
        } catch (err) {
          throw new Error("Please upload a valid resume.");
        }

        // Call the result API with the processed parsed resume data
        const resultResponse = await apis.POST({
          endpoint: "/get-result",
          payload: parsedResume,
          options: { type: "json" },
        });

        if (resultResponse.data.error) {
          throw new Error(resultResponse.data.error);
        }

        const resultData = resultResponse.data;

        if (resultData.convertedTechValue) {
          setConvertedTechValue(resultData.convertedTechValue);
          setIsFetchingResult(false);
        } else {
          setError("No convertedTechValue found in the response.");
        }
      } catch (error) {
        setError(
          error.message || "An error occurred while uploading the resume."
        );
      } finally {
        setIsUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 600, margin: "auto", padding: 3 }}>
      {(!isUploading || !isFetchingResult) && (
        <Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", py: 3 }}
          >
            Upload Your Resume
          </Typography>

          {/* Drag-and-Drop Area */}
          <Paper
            elevation={3}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={{
              padding: 2,
              textAlign: "center",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              backgroundColor: file ? "#e8f5e9" : "#f9f9f9",
            }}
          >
            {file ? (
              <Typography variant="body1">
                File Selected: {file.name}
              </Typography>
            ) : (
              <Typography variant="body1">
                Drag and drop your resume here
              </Typography>
            )}
          </Paper>

          {/* Choose File Button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: 2,
            }}
          >
            <Button
              variant="outlined"
              component="label"
              fullWidth
              disabled={isUploading || isFetchingResult}
            >
              Choose File
              <input
                type="file"
                hidden
                accept=".pdf,.docx"
                onChange={handleFileChange}
              />
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={isUploading || isFetchingResult || !file}
              fullWidth
            >
              {isUploading || isFetchingResult
                ? "Processing..."
                : "Upload Resume"}
            </Button>
          </Box>
        </Box>
      )}

      {/* Lottie Animation and Messages */}
      {(isUploading || isFetchingResult) && (
        <Box sx={{ textAlign: "center", marginTop: 4 }}>
          <Lottie
            options={{
              animationData: isUploading
                ? uploadAnimationData
                : resultAnimationData,
              loop: true,
              autoplay: true,
            }}
            height={150}
            width={150}
          />
          <Typography variant="body1">
            {isUploading
              ? "Uploading and parsing your resume..."
              : "Calculating your tech value..."}
          </Typography>
        </Box>
      )}

      {/* Error Message */}
      {error && (
        <Typography
          color="error"
          variant="body2"
          sx={{ marginTop: 2, textAlign: "center" }}
        >
          {error}
        </Typography>
      )}

      {/* Display Converted Tech Value */}
      {convertedTechValue && (
        <Typography
          variant="h6"
          sx={{ marginTop: 4, textAlign: "center", color: "green" }}
        >
          Your Tech Value: {convertedTechValue}
        </Typography>
      )}
    </Box>
  );
};

export default UploadResume;
