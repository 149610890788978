import React from "react";
import Lottie from "react-lottie";
import { Grid, Box, Typography, Fade } from "@mui/material";
import defaultAnimation from "../animations/400-error.json"; // Default Lottie animation
import networkErrorAnimation from "../animations/network-error.json"; // Network error Lottie animation
import serverErrorAnimation from "../animations/500-error.json"; // Server error Lottie animation
import genericErrorAnimation from "../animations/400-error.json"; // Generic error Lottie animation
import notfoundErrorAnimation from "../animations/not-found.json";

const ErrorComponent = ({ errorWithStatus }) => {
  const { message, status } = errorWithStatus || {};

  // Map error status/types to specific animations
  const getAnimationData = () => {
    if (!status) return networkErrorAnimation; // No status => Network error
    if (status === 500) return serverErrorAnimation; // Server error
    if (status === 404) return genericErrorAnimation; // Resource not found
    if (status === 400) return genericErrorAnimation; // Generic error between 400-499
    return defaultAnimation; // Default for other errors
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: getAnimationData(),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Lottie options={defaultOptions} height={300} width={300} />
      <Fade in timeout={800}>
        <Typography
          variant="h4"
          sx={{
            mt: 2,
            fontWeight: 600,
            color: "#d32f2f", // Red color for error header
          }}
        >
          {status ? `Error ${status}` : "Unexpected Error"}
        </Typography>
      </Fade>
      <Fade in timeout={1000}>
        <Typography
          variant="h6"
          sx={{
            mt: 1,
            fontWeight: 500,
            color: "text.secondary", // Subtle color for the error message
          }}
        >
          {message || "Something went wrong. Please try again later."}
        </Typography>
      </Fade>
      {status === 500 && (
        <Fade in timeout={1200}>
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              color: "text.disabled", // Even more subtle color for the contact message
            }}
          >
            Please contact the administrator if this issue persists.
          </Typography>
        </Fade>
      )}
    </Box>
  );
};

export default ErrorComponent;
