import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import {
  getCoverLeter,
  getCoverLetterForResult,
} from "../components/stepper_form/stepper.helper";
import { CloseRounded } from "@mui/icons-material";
import { Link } from "@mui/material";
import { KeyboardArrowRight, Circle } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReactSpeedometer from "react-d3-speedometer";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import confetti from "canvas-confetti";
import { getEmail } from "../utils/storage";
import toast from "react-hot-toast";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { submitSalaryFeedback } from "../components/stepper_form/stepper.helper";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Add, Delete, DeleteOutline } from "@mui/icons-material";
import apis from "../utils/apis";
import { getReferralsStatus } from "../components/common/getReferralsStatus";
import CoverLetter from "../components/common/CoverLetter";
import PrimarySkillRelevanceAIResult from "../components/stepper_form/question-type-components/PrimarySkillRelevanceAIResult";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReferencesTable from "../components/common/ReferencesTable";
import Badge from "@mui/material/Badge";
import confetti from "canvas-confetti";
import Joyride from "react-joyride";
import { styled } from "@mui/material/styles";
import "./UpdatedResult.css";
import DeviceInfoAndLocation from "../components/common/DeviceInfoAndLocation";
import ReferaFriend from "../components/common/ReferaFriend";
import DeleteUserForm from "./DeleteUserForm.json";
import TooltipComponent from "../components/common/Tooltip";
import SubSkills from "../components/common/SubSkills";
import Stack from "@mui/material/Stack";
import { setPrimarySkillRelevance } from "../redux/action/result";
import axios from "axios";

import Lottie from "react-lottie";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: DeleteUserForm,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 15,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#2BB344",
  },
}));

const getIconBySkillName = async (skillName) => {
  const svgIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.svg`;
  const pngIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.png`;
  const darkIconPath = `${process.env.PUBLIC_URL}/assets/images/dark-icons/${skillName}.svg`;

  if (await imageExists(svgIconPath)) {
    return svgIconPath;
  } else if (await imageExists(pngIconPath)) {
    return pngIconPath;
  } else if (await imageExists(darkIconPath)) {
    return darkIconPath;
  }
  return null;
};

const imageExists = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

function TechResultV3() {
  const resultData = useSelector((state) => state?.ResultReducer?.result || []);

  const subSkillsData = useSelector(
    (state) => state?.OldFormReducer?.subSkill || []
  );

  const [skillsData, setSkillsData] = useState({
    primarySkill: [],
    secondarySkill: [],
    AllTools: [],
  });

  const staticRandomEncodeDecodeString = useFeatureFlag(
    "staticRandomEncodeDecodeString"
  );
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson =
      secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(
      /"/g,
      staticRandomEncodeDecodeString
    );
    const encodedString = btoa(newCustomStringJson);
    return encodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(
      new RegExp(secretEncodeDecodeString, "g"),
      ""
    );
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  useEffect(() => {
    const fetchSkillsWithIcons = async () => {
      setLoading(true);
      // Fetch primary skills with icons
      const primarySkills = await Promise.all(
        resultData?.primarySkill?.map(async (skill) => {
          const iconUrl = await getIconBySkillName(skill.name); // Await if async
          return { ...skill, iconUrl };
        }) || []
      );

      // Fetch secondary skills with icons
      const secondarySkills = await Promise.all(
        resultData?.secondarySkill?.map(async (skill) => {
          const iconUrl = await getIconBySkillName(skill.name); // Await if async
          return { ...skill, iconUrl };
        }) || []
      );

      // Fetch all tools with icons
      const toolsWithIcons = await Promise.all(
        resultData?.AllTools?.map(async (tool) => {
          const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
          return { ...tool, iconUrl }; // Append iconUrl to each tool object
        }) || []
      );

      const colors = checkYearWithGpt(relevanceResult[0]);
      const newSkills = primarySkills?.map((item) => {
        if (item?.name === relevanceResult[0]["name"]) {
          return (item = { ...item, ...colors });
        } else {
          return item;
        }
      });

      // Update state with both primary and secondary skills
      setSkillsData({
        primarySkill: newSkills,
        secondarySkill: secondarySkills,
        AllTools: toolsWithIcons,
      });

      setLoading(false);
    };

    if (resultData) {
      fetchSkillsWithIcons(); // Call the async function
    } else {
      setLoading(false); // If no resultData, set loading to false
    }
  }, [resultData]); // Dependency on resultData

  const isMobile = useMediaQuery("(max-width:600px)");
  const isDesktop = useMediaQuery("(max-width:1400px)");
  // const resultData = useSelector((state) => state?.ResultReducer?.result || "");
  // console.log("resultData", resultData);
  const userForm = useSelector((state) => state?.OldFormReducer || "");
  // console.log("userForm", userForm);
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });

  const [getTrueEdge, setTrueEdge] = useState(false);
  const [viewTrueEdge, setViewTrueEdge] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [openEndorse, setOpenEndorse] = useState(false);
  const [openTrueEdge, setOpenTrueEdge] = useState(false);
  const [salaryFeedback, setSalaryFeedback] = useState("");
  const [salaryComment, setSalaryComment] = useState("");
  const [checked, setChecked] = useState(false);
  const [location, setLocation] = useState();
  const [jobLocation, setJobLocation] = useState();

  const [deviceInfo, setDeviceInfo] = useState();
  const [indeedUrl, setIndeedUrl] = useState(
    resultData?.countryCode?.indeedUrl
  );
  const [monthlySalary, setMonthlySalary] = useState();
  const [contractSalarySelect, setContractSalarySelect] = useState(false);
  const [openPopup, setOpenPopUp] = useState(false);
  const relevanceResult = useSelector(
    (state) => state?.ResultReducer?.skill_relevance || ""
  );
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  const UserFormDeleted = localStorage.getItem("UserFrom Deleted");

  var scalar = 5;
  // Dollar shape
  const dollar = confetti.shapeFromText({
    text: `🤑`,
    scalar,
  });
  // Rupee shape
  const notes = confetti.shapeFromText({
    text: "💸",
    scalar,
  });

  // Euro shape
  const symbol = confetti.shapeFromText({
    text: "💲",
    scalar,
  });

  const defaults = {
    // spread: 180,

    // // startVelocity: 10,
    // scalar,
    // origin: { y: -0.1 },
    // startVelocity: -35,
    spread: 360,
    ticks: 150,
    gravity: 0,
    decay: 0.95,
    startVelocity: 20,
    scalar,
  };

  useEffect(() => {
    // console.log(UserFormDeleted, "UserFormDeletedUserFormDeleted");

    if (
      IncognitoModeStatus &&
      (UserFormDeleted === null || UserFormDeleted === undefined)
    ) {
      setDialogOpen(true);
    } else {
      //   // Add confetti w  ith custom shapes
      // remove animation due to heavy memory usage
      //   confetti({
      //     ...defaults,
      //     particleCount: 50,
      //     shapes: [dollar],

      //   flat: true,
      // });
      // confetti({
      //   ...defaults,
      //   particleCount: 50,
      //   shapes: [notes],

      //   flat: true,
      // });
      // confetti({
      //   ...defaults,
      //   particleCount: 35,
      //   shapes: [symbol],

      //   flat: true,
      // });

      const startJoyRide = setTimeout(() => {
        setRun(true);
      }, 2000);

      return () => clearTimeout(startJoyRide);
    }
  }, []);

  useEffect(() => {
    if (relevanceResult[0] === undefined) {
      console.log("called", "called");
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-topSkillValidity",
          DataEncryption({
            primarySkill: resultData?.primarySkill,
          }),
          {
            // cancelToken: cancelSkillToken.current.token,
            timeout: 200000, // Set timeout to 5 seconds (5000 milliseconds)
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          const data = DataDecryption(object.data);
          // console.log(data, "Data relevance");
          const topN = (arr, n) => {
            if (n > arr?.length) {
              return arr;
            }
            return arr
              ?.slice()
              ?.sort((a, b) => {
                // Prioritize chat_gpt true, then sort by estYear
                if (a.chat_gpt === b.chat_gpt) {
                  return b.expiryYear - a.expiryYear;
                }
                return b.chat_gpt - a.chat_gpt;
              })
              .slice(0, n);
          };

          let topSkill = topN(data.topPrimarySkill, 1);
          // console.log("topSkill", topSkill);

          dispatch(setPrimarySkillRelevance(topSkill));

        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, []);

  
  const handleSalaryFeedback = (event) => {
    setSalaryFeedback(event.target.value);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    // getBiolater();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenPopup = () => {
    if (resultData?.coverLetter === undefined) {
      setOpenPopUp(true);
    } else {
      setOpenPopUp(false);
    }
  };
  const handleClosePopup = () => {
    setTrueEdge(true);
    setOpenPopUp(false);
  };
  const handleCloseTrueEdge = () => setOpenTrueEdge(false);
  const handleCloseEndorse = () => setOpenEndorse(false);
  const handleOpenTrueEdge = () => {
    setOpenTrueEdge(true);
    setText("");
  };
  const User_Cover_letter = useFeatureFlag("User_Cover_letter");
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  // Joy ride initiation states
  const [run, setRun] = useState(false);
  // JoyrideSteps
  const JoyrideSteps = [
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Explore Your Earning Potential with SmartSalary! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Discover your full-time and contract salary estimates tailored to
            your TechValue. Let's unlock your earning possibilities! 💼💰
          </Typography>
        </Box>
      ),

      target: ".smart-salary-container",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Discover Full-Time Salary Estimates! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Unlock per annum earnings aligned with your TechValue. Let's explore
            your financial horizon! 💼💰
          </Typography>
        </Box>
      ),
      target: ".full-time-salary",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Explore Contract Salary Estimates! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Unlock hourly rates tailored to your TechValue. Let's discover your
            earning potential on contract terms! 💼💰
          </Typography>
        </Box>
      ),
      target: ".contact-salary",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Your Tech Value 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Your Tech Value based on your input! Factors like skills,
            experience, and more will determine your worth in the tech world.
            Let's get started! 💼💡
          </Typography>
        </Box>
      ),
      target: "tech-value-container",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Tech Worth 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Explore your Tech Worth Dial! 🎯 Are you in the Layoff, Overpaid,
            Ideal, Attrition, or Underpaid zone? Let's find out together! 💼💡
          </Typography>
        </Box>
      ),
      target: ".techworth-dial-container",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Explore Job Opportunities! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Discover jobs tailored to your primary skills on LinkedIn and
            Indeed! Let's find the perfect career match for you! 🚀🔍
          </Typography>
        </Box>
      ),
      target: ".find-jobs-container",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Introducing TrueEdge! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Craft personalized cover letters powered by ChatGPT, tailored to
            your skills and work experience. Let's land your dream job! 📝💼
          </Typography>
        </Box>
      ),
      target: ".true-edge-container",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Refer a Friend and Unlock Their Earning Potential! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Share with friends and colleagues to help them discover their
            deserved salary based on skills, expertise, and experience. Let's
            empower each other! 💬💼🚀
          </Typography>
        </Box>
      ),
      target: ".refer-a-friend",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Discover Your Top Skill Validity! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Our algorithm predicts your top skill based on its highest validity
            year. Let's uncover your expertise! 💡🔍
          </Typography>
        </Box>
      ),
      target: ".top-skill-validity",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Explore Tech Relevance! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Discover the relevance, validity, and popularity of your skills!
            Let's navigate the ever-changing tech landscape together! 💼💡
          </Typography>
        </Box>
      ),
      target: ".truselfy-tech-relevance",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Your Provided References! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Explore the list of references you've previously provided. Keep
            track of your endorsements and build your credibility! 💼📜
          </Typography>
        </Box>
      ),
      target: ".provided-references",
    },
    {
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            🌟 Your Endorsed Users! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Discover the list of users who have endorsed your skills and
            expertise. Build connections and enhance your professional network!
            👥🌐
          </Typography>
        </Box>
      ),
      target: ".endorsed-references",
    },
  ];

  // smart salary
  const [smartSalary, setSmartSalary] = useState(
    resultData.convertedMarketSalary
  );

  const referralslist = useSelector(
    (state) => state.ReferralsReducer?.referrals
  );

  const [selectedValue, setSelectedValue] = useState("full-time-salary");

  const allSkills = resultData?.primarySkill
    ?.concat(resultData?.secondarySkill)
    ?.map((item) => {
      return item.name;
    });

  const handleClick = () => {
    toast.success("Copied");
  };

  useEffect((item) => {
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
    extractCityState();
    convertCurrency();
    // setTimeout(handleOpenPopup, 10000);
  }, []);
  function extractCityState() {
    let locationString = userForm?.location;
    // Find the index of the last comma
    let lastIndex = locationString.lastIndexOf(",");
    // Extract the substring up to the last comma
    let result = locationString.substring(0, lastIndex);
    setJobLocation(result);
  }
  useEffect(() => {
    if (getTrueEdge) {
      const element = document?.getElementById("salary-feedback");
      element?.scrollIntoView();
      // window.scrollTo({
      //   top: isMobile ? 2600 : 1000,
      //   behavior: "smooth",
      // });
    }
  }, [getTrueEdge]);

  useEffect(() => {
    var scalar = 10;
  }, []);

  const salaryData = [
    {
      id: 0,
      iconSrc: "/assets/images/unchecked-1-3lac.png",
      checkedSrc: "/assets/images/1-3lac.png",
      label: "1-3 Lacs",
      value: "-30%",
    },
    {
      id: 1,
      iconSrc: "/assets/images/unchecked-3-5lac.png",
      checkedSrc: "/assets/images/3-5lac.png",
      label: "3-5 Lacs",
      value: "-20%",
    },
    {
      id: 2,
      iconSrc: "/assets/images/unchecked-5-10lac.png",
      checkedSrc: "/assets/images/5-10lac.png",
      label: "5-10 Lacs",
      value: "-10%",
    },
    {
      id: 3,
      iconSrc: "/assets/images/unchecked-10-15lac.png",
      checkedSrc: "/assets/images/10-15lac.png",
      label: "10-15 Lacs",
      value: "-5%",
    },
    {
      id: 4,
      iconSrc: "/assets/images/unchecked-15-20lac.png",
      checkedSrc: "/assets/images/15-20lac.png",
      label: "15-20 Lacs",
      value: "10%",
    },
    {
      id: 5,
      iconSrc: "/assets/images/unchecked-20-25lac.png",
      checkedSrc: "/assets/images/20-25lac.png",
      label: "20-25 Lacs",
      value: "20%",
    },
    {
      id: 6,
      iconSrc: "/assets/images/unchecked-25-30lac.png",
      checkedSrc: "/assets/images/25-30lac.png",
      label: "25-30 Lacs",
      value: "30%",
    },
  ];

  // cover letter code
  function removeEmptyKeysAndObjects(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          obj[key] === null ||
          obj[key] === undefined ||
          obj[key] === "" ||
          obj[key] === "No" ||
          key === "userlocation" ||
          key === "resultTime" ||
          key === "lastStepper" ||
          key === "deviceinfo" ||
          key === "location" ||
          key === "typeOfCompany"
        ) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
              delete obj[key];
            } else {
              obj[key].forEach((item) => {
                if (item && typeof item === "object" && "type" in item) {
                  delete item.type;
                }
              });
            }
          } else {
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
    }
    return obj;
  }

  const handleChangeSalaryType = (event) => {
    const { value } = event.target;
    setContractSalarySelect(value === "contract-salary");
    setSelectedValue(value);
  };

  const handleChange = (event) => {
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    // Get the current value of the text area.
    const currentValue = event.target.value;

    // Get the text that the user is typing.
    const newText = event.target.value.substring(selectionStart, selectionEnd);

    // Replace the selected text with the new text.
    const newValue = currentValue.replace(
      currentValue.substring(selectionStart, selectionEnd),
      newText
    );
    setText(newValue);
  };

  function DataComponent() {
    return (
      <div>
        <textarea
          className="typing-textarea"
          value={text}
          onChange={handleChange}
        />
      </div>
    );
  }
  function filterSalaryPartitions(value, index, array) {
    const isNotFirstOrLast = index > 0 && index < array.length - 1;
    const label = isNotFirstOrLast
      ? `${value}`
      : index === 0
      ? `<${value}`
      : `>${value}`;

    return label;
  }

  const getBiolater = async () => {
    // console.log("removeObjects", removeEmptyKeysAndObjects(userForm));
    handleOpenTrueEdge();
    submitSalaryFeedback(resultData?._id, salaryFeedback, salaryComment);
    const techValue = resultData?.convertedTechValue;
    let userEmail = getEmail();
    let resultId = resultData?._id;
    const userFormData = { ...userForm };
    const IncognitoMode = IncognitoModeStatus;
    let userData = removeEmptyKeysAndObjects(userFormData);
    let userObject = {
      userData,
      resultId,
      techValue,
      userEmail,
      IncognitoMode,
    };

    getCoverLetterForResult(
      userObject,
      setText,
      setLoading,
      dispatch,
      resultData
    );
  };

  // format currency for indeed and linkedin search
  const formatCurrencyString = (str) => {
    const symbolFirst = /^[^\d]+/;
    const symbolLast = /[^\d]+$/;

    if (symbolFirst.test(str)) {
      return str;
    } else if (symbolLast.test(str)) {
      const parts = str.split(symbolLast);
      const number = parts[0].trim();
      const symbol = str.match(symbolLast)[0].trim();
      return `${symbol}${number}`;
    } else {
      return str;
    }
  };
  function convertCurrency() {
    const { marketSalary, countryCode, convertedMarketSalary } = resultData;

    const currency = new Intl.NumberFormat(countryCode?.languageCode, {
      style: "currency",
      currency: countryCode?.code,
      maximumSignificantDigits: 4,
    });
    // console.log(currency.format(convertedMarketSalary), "currency");
    setMonthlySalary(formatCurrencyString(convertedMarketSalary));
  }
  const RadioIcon = ({ src }) => {
    return (
      <Box
        sx={{
          width: { xs: "60px", md: "80px", lg: "100px" },
          height: { xs: "60px", md: "80px", lg: "100px" },
          borderRadius: "50%",
          // border: "2px solid transparent",
          overflow: "hidden",
          "& >img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={src} alt="radio-img" loading="lazy" />
      </Box>
    );
  };

  const CheckedRadioIcon = ({ src }) => {
    return (
      <Box
        sx={{
          // width: { xs: "80px", md: "80px", lg: "115px" },
          // height: { xs: "80px", md: "80px", lg: "115px" },
          width: { xs: "60px", md: "80px", lg: "100px" },
          height: { xs: "60px", md: "80px", lg: "100px" },
          borderRadius: "50%",
          // border: "2px solid #000",
          // boxShadow: "0px 0px 10px 5px rgba(205, 202, 192,0.7)",
          overflow: "hidden",
          "& >img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={src} alt="checked-radio-img" loading="lazy" />
      </Box>
    );
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getUserStatusForMobile(status, email, index) {
    switch (status) {
      case "success":
        return (
          <Chip
            size="small"
            sx={{ background: "#B3DCB6", color: "#64A166" }}
            label={email}
          />
        );
      case "notsignup":
        return (
          <Chip
            size="small"
            sx={{ background: "#CFD0D3", color: "#696B70" }}
            label={email}
          />
        );

      case "pending":
        return (
          <Chip
            size="small"
            sx={{ background: "#FFEAB6", color: "#DBAC3D" }}
            label={email}
          />
        );

      case "deleted":
        return (
          <Chip
            size="small"
            sx={{ background: "#F2CFD1", color: "#C94A51" }}
            label={email}
          />
        );

      default:
        return (
          <Chip
            size="small"
            sx={{ background: "#CFD0D3", color: "#696B70" }}
            label={email}
          />
        );
        break;
    }
  }

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
    if (UserFormDeleted === null || UserFormDeleted === "false") {
      try {
        apis
          .POST({
            endpoint: "/delete-user-form",
            payload: { resultId: resultData?._id },
            dispatch,
            options: {
              type: "json",
            },
          })
          .then((object) => {
            // console.log("object", object.data);
            // setFinalResult(object.data);
            // return <Result />;
            localStorage.setItem("UserFrom Deleted", true);
          });
      } catch (error) {
        console.log(error);
      }
    }
    confetti({
      ...defaults,
      particleCount: 50,
      shapes: [dollar],

      flat: true,
    });
    confetti({
      ...defaults,
      particleCount: 50,
      shapes: [notes],

      flat: true,
    });
    confetti({
      ...defaults,
      particleCount: 35,
      shapes: [symbol],

      flat: true,
    });
    const startJoyRide = setTimeout(() => {
      setRun(true);
    }, 2000);

    return () => clearTimeout(startJoyRide);
  };

  function GetBackGroundColor(skillDemand, type) {
    if (skillDemand === "Trending") {
      switch (type) {
        case "y":
          return "#278C37";
        case "tech":
          return "#278C37";
        case "mgmt":
          return "#30B044";
        case "management":
          return "#30B044";
        case "generic":
          return "#38C94E";
        default:
          return "#278C37";
      }
    }
    if (skillDemand === "Obsolete") {
      switch (type) {
        case "y":
          return "#028EDF";
        case "tech":
          return "#028EDF";
        case "mgmt":
          return "#09A2FA";
        case "management":
          return "#09A2FA";
        case "generic":
          return "#79CDFD";
        default:
          return "#028EDF";
      }
    }
    if (skillDemand === "In demand") {
      switch (type) {
        case "y":
          return "#E9B112";
        case "tech":
          return "#E9B112";
        case "mgmt":
          return "#FEC113";
        case "management":
          return "#FEC113";
        case "generic":
          return "#FAD15D";
        default:
          return "#E9B112";
      }
    }
    if (skillDemand === "Getting outdated fast") {
      switch (type) {
        case "y":
          return "#C60015";
        case "tech":
          return "#C60015";
        case "mgmt":
          return "#E2031B";
        case "management":
          return "#E2031B";
        case "generic":
          return "#FF465A";
        default:
          return "#C60015";
      }
    }
  }

  function checkYearWithGpt(option) {
    const year = option?.expiryYear;
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (!year) {
      return {
        label: "Default",
        barPrimaryColor: "#e0e0e0",
        barSecondaryColor: "#e0e0e0",
      };
    }

    if (year > currentYear) {
      if (option?.chat_gpt === false && option?.estYear === 2010) {
        return {
          label: `Obsolete`,
          barPrimaryColor: "#028EDF",
          // barSecondaryColor: "#FFCDD2",
          barSecondaryColor: GetBackGroundColor("Obsolete", option?.type),
          // color: "red",
          color: "#ffffff",
        };
      } else {
        return {
          label: `Trending`,
          barPrimaryColor: "#278C37",
          // barSecondaryColor: "#A5D6A7",
          barSecondaryColor: GetBackGroundColor("Trending", option?.type),

          // color: "#26801E",
          color: "#ffffff",
        };
      }
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return {
        label: `In demand`,
        barPrimaryColor: "#E9B112",
        // barSecondaryColor: "#ffcc80",
        barSecondaryColor: GetBackGroundColor("In demand", option?.type),
        // color: "darkorange",
        color: "#ffffff",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      return {
        label: `Getting outdated fast`,
        barPrimaryColor: "#C60015",
        // barSecondaryColor: "#fff59d",
        barSecondaryColor: GetBackGroundColor(
          `Getting outdated fast`,
          option?.type
        ),

        // color: "#FF9D09",
        color: "#ffffff",
      };
    } else if (year <= currentYear - minYearDiff) {
      return {
        label: `Obsolete `,
        barPrimaryColor: "#028EDF",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: GetBackGroundColor(`Obsolete`, option?.type),
        // color: "red",
        color: "#ffffff",
      };
    }
  }

  return (
    <>
      <Header />
      <Joyride
        steps={JoyrideSteps}
        run={run}
        showSkipButton
        continuous
        disableCloseOnEsc
        disableOverlayClose
        disableScrolling
      />
      <Box
        sx={{
          px: { xs: 3, sm: 3, md: 3, lg: 3, xl: 10 },
          py: 3,
          mt: { xs: 8, lg: 14 },
        }}
      >
        {IncognitoModeStatus && (
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            id="information-dialog"
          >
            <DialogTitle
              sx={{
                textAlign: "center",
              }}
            >
              Information
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon onClick={handleDialogClose} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  py: { xs: 1, md: 2 },
                  px: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  style={{
                    maxWidth: `${isMobile ? "150px" : "250px"}`,
                    margin: "0px",
                  }}
                />
              </Box>
              <DialogContentText sx={{ textAlign: "center" }}>
                <Typography variant="subtitle1">
                  Your form data will be deleted to ensure your privacy. Using
                  Incognito mode makes your data untraceable.
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <Button onClick={handleDialogClose} color="primary">
            Okay
          </Button> */}
            </DialogActions>
          </Dialog>
        )}
        <Grid container>
          <Grid item xs={12} lg={12} sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <Typography
                sx={{
                  fontSize: { xs: "25px", md: "35px", lg: "40px" },
                  fontWeight: 600,
                  textAlign: { xs: "center", lg: "center" },
                  // lineHeight: "40px",
                  color: "transparent",
                  backgroundImage:
                    "linear-gradient(to right, #2BB344, #FFC218, #E0011C, #0BA2FF)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  flexGrow: 1,
                  wordWrap: "break-word",
                  pl: { xs: "0vw", md: "0vw", lg: "10vw", xl: "15vw" },
                }}
              >
                {`${resultData?.name?.trim()}'s TechWorth`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "14px", lg: "16px" },
                  textAlign: { xs: "center", lg: "center" },
                  display: "flex",
                  alignItems: "center",
                  margin: { xs: "0 auto", sm: "0 auto" },
                }}
              >
                {" "}
                <LocationOnIcon
                  sx={{
                    fontSize: "inherit",
                    color: "#2BB344",
                    marginRight: "-2px",
                  }}
                />
                <span style={{ color: "#2BB344", fontSize: "inherit" }}>
                  {" "}
                  Work location:&nbsp;
                </span>{" "}
                {userForm?.location}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {/* <Grid
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", lg: "space-between" },
                alignItems: "center",
              }}
            >
              <Grid
                xs={12}
                lg={7}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: { xs: "center", lg: "center" },
                  alignItems: "center",
                  flexDirection: { xs: "column", md: "column", lg: "row" },
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontSize: { xs: "20px", lg: "24px" },
                    textAlign: { xs: "center", lg: "left" },
                  }}
                >
                  {" "}
                  <span style={{ color: "#2BB344" }}> Result Id: </span>
                  {resultData?._id}
                </Typography>{" "}
              </Grid>
            </Grid> */}

            <Grid container spacing={{ xs: 4, md: 5, lg: 5, xl: 5 }}>
              <Grid item xs={12} md={12} lg={5}>
                <Grid
                  sx={{
                    boxShadow: "0px 0px 4px 0px #CDCAC073",
                    border: "1.5px solid #D3D3D3",
                    borderRadius: "20px",
                    background: "#FFFAEC",
                    gap: 1,
                    py: 3,
                    px: { xs: "15px", sm: 3 },
                    display: "flex",
                    flexDirection: "column",
                    margin: {
                      xs: 0,
                      sm: "0px auto",
                      md: "0px auto",
                      lg: "0px 0px",
                    },
                    mb: "20px !important",
                  }}
                  md={8}
                  sm={8}
                  lg={12}
                  className="smart-salary-container"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: { xs: "nowrap", md: "nowrap", lg: "nowrap" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "nowrap" }}>
                      <Box>
                        <Img
                          sx={{ width: { xs: "38px", md: "100%" } }}
                          src="/assets/images/wallet.svg"
                          alt="wallet"
                          loading="lazy"
                        />
                      </Box>
                      <Box>
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontSize: { sm: "22px", xl: "24px" },
                            fontWeight: 600,
                            textAlign: { xs: "center", sm: "left" },
                          }}
                        >
                          SmartSalary
                        </Typography>
                        <Typography variant="subtitle2">
                          (TechValue based)
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        // margin: { xs: "0vw", sm: "2vw", md: "3vw", lg: "0 auto" },
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "68px",
                      }}
                    >
                      {selectedValue === "contract-salary" ? (
                        <Typography
                          variant="h3"
                          sx={{
                            fontWeight: 600,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {resultData?.contractSalary}
                          <span style={{ fontSize: "12px" }}>(Rate/hour)</span>
                        </Typography>
                      ) : (
                        <Typography
                          variant="h3"
                          sx={{
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          {resultData?.convertedMarketSalary}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: { xs: "wrap", sm: "wrap", lg: "nowrap" },
                      ml: { xs: "0%", sm: "5%", md: "10%", lg: "10%" },
                      gap: 2,
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        lg: "space-between",
                      },
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                          className={"full-time-salary"}
                        >
                          <Radio
                            value="full-time-salary"
                            name="radio-buttons"
                            checked={selectedValue === "full-time-salary"}
                            onChange={handleChangeSalaryType}
                            inputProps={{ "aria-label": "A" }}
                            sx={{ p: 0, pr: 1 }}
                          />
                          <Box
                            component="div"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              component="p"
                              variant="subtitle2"
                              sx={{ color: "#636363" }}
                            >
                              Full-time salary
                            </Typography>
                            <Typography variant="caption">
                              (per annum)
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                          }}
                          className={"contact-salary"}
                        >
                          <Radio
                            value="contract-salary"
                            name="radio-buttons"
                            checked={selectedValue === "contract-salary"}
                            onChange={handleChangeSalaryType}
                            inputProps={{ "aria-label": "B" }}
                            sx={{ p: 0, pr: 1 }}
                          />
                          <Box
                            component="div"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              component="p"
                              variant="subtitle2"
                              sx={{ color: "#636363" }}
                            >
                              Contract salary{" "}
                            </Typography>
                            <Typography variant="caption">
                              (Rate/hour)
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: { xs: "flex", sm: "flex" },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          component="div"
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <ReferaFriend
                            className={"refer-a-friend"}
                            text={"Help Your Friends"}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box
                            component="div"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <TooltipComponent
                              size="small"
                              message={
                                "People who are looking for a change, just underpaid, job hunting, recently laid off, etc."
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1.5,
                        alignItems: "center",
                        boxShadow: "0px 0px 4px 0px #CDCAC073",
                        border: "1.5px solid #D3D3D3",
                        borderRadius: "20px",
                        backgroundColor: "#FFFAEC",
                        flexWrap: "nowrap",
                        height: "100%",
                        boxSizing: "inherit",
                        py: { xs: 1, md: 1, lg: 0 },
                        px: { xs: 2, md: 2, lg: 2, xl: 3 },
                      }}
                      className={"tech-value-container"}
                    >
                      <Box>
                        <Img
                          sx={{ width: { xs: "38px", md: "100%" } }}
                          src="/assets/images/techvalue-box-img.svg"
                          alt="yellow-star"
                          loading="lazy"
                        />
                      </Box>
                      <Box component="div">
                        <Typography
                          sx={{
                            fontSize: { sm: "22px", xl: "24px" },
                            fontWeight: 600,
                            textAlign: { xs: "left", sm: "left" },
                          }}
                        >
                          TechValue
                        </Typography>
                        <Typography variant="subtitle2">
                          (Value you bring to the table)
                        </Typography>
                        <Typography
                          className="btn-shine"
                          variant="h3"
                          sx={{ fontWeight: 600 }}
                        >
                          {resultData?.convertedTechValue}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    className={"find-jobs-container"}
                  >
                    <Box
                      sx={{
                        borderRadius: "20px",
                        boxShadow: "0px 0px 4px 0px #CDCAC073",
                        border: "1.5px solid #D3D3D3",
                        background: "#FFFAEC",
                        height: "100%",
                        boxSizing: "inherit",
                        py: 2,
                        px: 3,
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        Jobs that best fit your expertise and TechValue
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Link
                          sx={{
                            maxWidth: "140px",
                            width: "100%",
                            display: "inline-flex",
                            gap: "4px",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#2BB344",
                            border: "2px solid #2BB344",
                            p: "8px 12px",
                            borderRadius: "24px",
                            textDecoration: "none",
                            "&:hover": {
                              backgroundColor: "#0D9B21 !important",
                              color: "#fff",
                              borderColor: "#fff",
                            },
                          }}
                          target="_blank"
                          href={`https://www.linkedin.com/jobs/search/?currentJobId=3788215852&f_TPR=r604800&keywords=${
                            resultData?.topSkills
                              ? resultData?.topSkills[0]["name"]
                              : ""
                          }&location=${jobLocation}&origin=JOB_SEARCH_PAGE_JOB_FILTER`}
                        >
                          <img
                            src="../../../assets/images/linkedin-square.png"
                            alt="Linkedin"
                            width="20px"
                            loading="lazy"
                          />
                          Linkedin
                          <KeyboardArrowRight />{" "}
                        </Link>{" "}
                        <Link
                          sx={{
                            maxWidth: "140px",
                            width: "100%",
                            display: "inline-flex",
                            gap: "4px",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#2BB344",
                            border: "2px solid #2BB344",
                            p: "8px 12px",
                            borderRadius: "24px",
                            textDecoration: "none",
                            "&:hover": {
                              backgroundColor: "#0D9B21 !important",
                              color: "#fff",
                              borderColor: "#0D9B21",
                            },
                          }}
                          target="_blank"
                          href={`${indeedUrl}/jobs?q=${
                            resultData?.topSkills
                              ? resultData?.topSkills[0]["name"]
                              : ""
                          }-${monthlySalary}&l=${jobLocation}`}
                        >
                          <img
                            src="../../../assets/images/indeed.png"
                            alt="Indeed"
                            width="20px"
                            loading="lazy"
                          />
                          Indeed
                          <KeyboardArrowRight />{" "}
                        </Link>{" "}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    border: "1.5px solid #8DE49A",
                    boxShadow: "0px 0px 4px 0px #CDCAC073",
                    p: "17px 20px 35px",
                    backgroundColor: "#F1FFF4",
                    borderRadius: "20px",
                    position: "relative",
                  }}
                  className={"techworth-dial-container"}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        xs: "80px",
                        sm: "108px",
                        md: "108px",
                        lg: "108px",
                      },
                      // width: "100%",
                      // height: "auto",
                      overflow: "hidden",
                      "& >img": { width: "100%", height: "100%" },
                      position: "absolute",
                      top: "5%",
                      right: "10%",
                      m: "0px auto",
                    }}
                  >
                    {resultData?.evaluationTested ? (
                      <StyledBadge
                        badgeContent={resultData?.evaluationResultlength || 1}
                        color="primary"
                      >
                        <img
                          src={`/assets/images/${
                            resultData?.evaluationTested
                              ? "peerreviewed"
                              : "SELF-ATTESTED"
                          }.svg`}
                          alt="peer-reviewed"
                          style={{ maxWidth: isMobile ? "65px" : "108px" }}
                          loading="lazy"
                        />
                      </StyledBadge>
                    ) : (
                      <img
                        src={`/assets/images/${"SELF-ATTESTED"}.svg`}
                        alt="self-attested"
                        style={{ maxWidth: isMobile ? "65px" : "108px" }}
                        loading="lazy"
                      />
                    )}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Img
                      sx={{
                        width: { xs: "38px", md: "100%" },
                        maxWidth: "51px",
                        mr: 1,
                      }}
                      src="/assets/images/green-diamond.png"
                      alt="techworth"
                      loading="lazy"
                    />
                    <Typography
                      color="primary"
                      variant="h3"
                      sx={{ fontWeight: 600 }}
                    >
                      TechWorth
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      // flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
                      // gap: 3,
                      justifyContent: "center",
                      alignItems: "center",
                      mb: "10px",
                    }}
                  >
                    {/* <Box  sx={{ maxWidth: "350px", width: "100%", height: "auto" }}> */}
                    <ReactSpeedometer
                      segments={6}
                      customSegmentLabels={[
                        {
                          text: "<1",
                          position: "INSIDE",
                          color: "#fff",
                        },
                        {
                          text: "1-2",
                          position: "INSIDE",
                          color: "#fff",
                        },
                        {
                          text: "2-3",
                          position: "INSIDE",
                          color: "#fff",
                        },
                        {
                          text: "3-4 ",
                          position: "INSIDE",
                          color: "#fff",
                        },
                        {
                          text: "4-5",
                          position: "INSIDE",
                          color: "#fff",
                        },
                        {
                          text: "5+",
                          position: "INSIDE",
                          color: "#fff",
                        },
                      ]}
                      segmentColors={[
                        "rgba(215, 38, 38, 1)",
                        "rgba(255, 169, 2, 1)",
                        "rgba(43, 179, 68, 1)",
                        "rgba(13, 155, 33, 1)",
                        "rgba(255, 135, 26, 1)",
                        "rgba(215, 38, 38, 1)",
                      ]}
                      value={
                        resultData?.techWorth > 6 ? 6 : resultData?.techWorth
                      }
                      needleHeightRatio={0.8}
                      valueTextFontSize={"20px"}
                      valueTextFontWeight={700}
                      textColor={"#000"}
                      maxValue={6}
                      minValue={0}
                      needleTransition="easeElastic"
                      customSegmentStops={[0, 1, 2, 3, 4, 5, 6]}
                      // fluidWidth={true}
                      height={isDesktop ? 160 : 210}
                      width={isDesktop ? 250 : 350}
                      ringWidth={40}
                      needleColor="#000"
                      labelFontSize="12px"
                      // needleHeightRatio={0.8}
                    />
                    {/* </Box> */}

                    {/* <Box
                      sx={{
                        maxWidth: "107px",
                        minWidth: "90px",
                        width: "100%",
                        height: "auto",
                        overflow: "hidden",
                        "& >img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src="/assets/images/SELF-ATTESTED.svg" alt="self-attested" />
                    </Box> */}
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "10px", lg: "14px" },
                        textAlign: { xs: "center", lg: "center" },
                      }}
                    >
                      {" "}
                      <span style={{ color: "#2BB344" }}> Result Id: </span>
                      {resultData?._id}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      overflow: "hidden",
                      "& >img": {
                        width: "100%",
                        height: "100%",
                      },
                      pb: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "474px",
                        width: "100%",
                        height: "auto",
                      }}
                      src="/assets/images/scale.svg"
                      alt="scale"
                      loading="lazy"
                    />
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "center",
                      fontSize: "18px",
                      lineHeight: "26px",
                    }}
                  >
                    This is an indicative figure based on your given answers.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "center",
                      fontSize: "18px",
                      lineHeight: "26px",
                      mb: "10px",
                    }}
                  >
                    Ideally, your TechWorth must be at least 2 to 4, if you are
                    to be considered productive and useful in your organization.
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: "556px",
                      width: "100%",
                      height: "auto",
                      overflow: "hidden",
                      margin: "0px auto",
                      "& >img": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <img
                      src="/assets/images/equation-image.png"
                      alt="techworth-equation"
                      loading="lazy"
                    />
                  </Box>

                  {/* <Box sx={{ display: "flex", justifyContent: "center", mt: "35px" }}>
                    <Button
                      type="button"
                      // size="large"
                      variant="contained"
                      color="primary"
                      href="../../../assets/TrueSelfy-TechWorth-Factors.pdf"
                      target="_blank"
                      // sx={{ fontWeight: 400, borderRadius: "55px" }}
                      endIcon={<KeyboardArrowRight sx={{ fontSize: "24px !important" }} />}
                    >
                      TechWorth
                    </Button>
                  </Box> */}
                </Box>
              </Grid>
              <Grid container item xs={12} md={12} lg={7}>
                {/* true edge grid */}
                <Grid
                  sx={{
                    border: "1.5px solid #8DE49A",
                    // background: "#ECFFEF",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: 3,
                    mb: 3,
                  }}
                  lg={8}
                  md={12}
                  xl={7}
                  sm={12}
                  item
                  className={"true-edge-container"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: 1, md: 2, xl: 2 },
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                        md: "row",
                        lg: "row",
                      },
                      py: 3,
                      pb: { xs: 0, sm: 3 },
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "60px",
                        width: "100%",
                        height: "auto",
                        overflow: "hidden",
                        "& >img": { width: "100%", height: "100%" },
                      }}
                    >
                      <img
                        src="/assets/images/trueedge-mail.png"
                        alt="trueedge"
                        loading="lazy"
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          textAlign: { xs: "center", sm: "center", md: "left" },
                          color: "#2BB344",
                        }}
                      >
                        TrueEdge
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#636363",
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {resultData?.coverLetter === undefined
                          ? `You are Unique. Your Unique Cover Letter to stand out from the crowd
                        (chatgpt enabled)`
                          : `Your Unique Cover Letter`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      pt: { xs: 3, sm: 0, md: 0, lg: 0 },
                      pb: { xs: 3, sm: 0, lg: 0 },
                    }}
                  >
                    {resultData?.coverLetter === undefined ? (
                      <Button
                        type="button"
                        // size="large"
                        variant="outlined"
                        onClick={() => {
                          setTrueEdge(true);
                          window.scrollTo({
                            top: isMobile ? 2600 : 1000,
                            behavior: "smooth",
                          });
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#0D9B21 !important",
                            color: "#fff",
                            borderColor: "#fff",
                          },
                        }}
                        endIcon={
                          <KeyboardArrowRight
                            sx={{ fontSize: "24px !important" }}
                          />
                        }
                      >
                        Proceed
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        // size="large"
                        variant="outlined"
                        onClick={() => {
                          setViewTrueEdge(true);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#0D9B21 !important",
                            color: "#fff",
                            borderColor: "#fff",
                          },
                        }}
                        endIcon={
                          <KeyboardArrowRight
                            sx={{ fontSize: "24px !important" }}
                          />
                        }
                      >
                        View
                      </Button>
                    )}
                  </Box>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  lg={4}
                  xl={5}
                  sx={{ mb: 3, justifyContent: { xs: "center", lg: "flex-end" } }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <ReferaFriend className={"refer-a-friend"} text={"Help Your Friends"} />
                  <TooltipComponent
                    size="small"
                    message={
                      "People who are looking for a change, just underpaid, job hunting, recently laid off, etc."
                    }
                  />
                </Grid> */}
                {/* Skills and all tools component */}
                <Grid
                  container
                  sx={{
                    border: "1.5px solid #E9E9E9",
                    boxShadow: "0px 0px 4px 0px #CDCAC073",
                    p: { xs: "20px", lg: "24px" },
                    borderRadius: "20px",
                    background: "#FBFBFB",
                    boxSizing: "inherit",
                    // gap: 2,
                    ml: "0px !important",
                    mt: "0px !important",
                    mb: 6,
                  }}
                  spacing={{ xs: 0, md: 0, lg: 3, xl: 3 }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { sm: "center", lg: "flex-start" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Img
                          sx={{ width: { xs: "38px", md: "100%" } }}
                          src="/assets/images/person-star.svg"
                          alt="techworth"
                          loading="lazy"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant={{ xs: "h4", md: "h3" }}
                          sx={{
                            fontWeight: 600,
                            pl: { xs: 1, lg: 2 },
                          }}
                        >
                          {isNaN(userForm?.experience)
                            ? `${userForm?.experience} experience with`
                            : (() => {
                                const totalMonths = parseInt(
                                  userForm?.experience,
                                  10
                                );

                                // Convert months to years and months
                                const years = Math.floor(totalMonths / 12);
                                const months = totalMonths % 12;

                                // Build the experience string
                                let experienceText = "";
                                if (years > 0) {
                                  experienceText += `${years} ${
                                    years > 1 ? "Years" : "Year"
                                  }`;
                                }
                                if (months > 0) {
                                  experienceText += ` ${months} ${
                                    months > 1 ? "Months" : "Month"
                                  }`;
                                } else if (totalMonths === 0) {
                                  return `Fresher/Intern/Trainee with`;
                                }
                                // if(months)

                                return `${experienceText} experience with`;
                              })()}
                        </Typography>
                      </Box>
                    </Box>
                    {loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          py: 2,
                        }}
                      >
                        <CircularProgress color="primary" size={30} />
                      </Box>
                    ) : (
                      <Grid container sx={{ gap: 2, py: 2, flexWrap: "wrap" }}>
                        {skillsData.primarySkill
                          .concat(skillsData.secondarySkill)
                          .map((item, index) => {
                            // Filter subskills based on the current skill name
                            const filteredSubSkills = subSkillsData?.filter(
                              (sub) => sub.parentSkill === item.name
                            );
                            return (
                              <Grid item key={index}>
                                {filteredSubSkills.length === 0 ? (
                                  <Paper
                                    sx={{
                                      borderRadius: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      backgroundColor:
                                        item.barPrimaryColor || "#fff", // Set individual skill background color
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",

                                        m: 0.2,
                                      }}
                                    >
                                      {item?.iconUrl && ( // Only render Avatar if iconUrl is present
                                        <Avatar
                                          src={item?.iconUrl}
                                          alt="Icons"
                                          sx={{
                                            width: "35px",
                                            height: "35px",
                                            p: "5px",
                                            ml: 1,
                                            backgroundColor: "#fff",
                                          }}
                                          slotProps={{
                                            img: {
                                              loading: "lazy",
                                            },
                                          }}
                                        />
                                      )}
                                      <Typography
                                        sx={{
                                          pt: 2,
                                          pl: 2,
                                          pr: 2,
                                          pb: 2,
                                          fontSize: {
                                            xs: "16px",
                                            md: "20px",
                                            lg: "20px",
                                          },
                                          color: item.color || "#000", // Apply the text color for each skill
                                        }}
                                      >
                                        {item?.name}
                                      </Typography>
                                    </Box>
                                    {filteredSubSkills.length === 0 &&
                                    index == 0 ? (
                                      <Paper
                                        key={index}
                                        sx={{
                                          p: 0.4,
                                          // mx: 1,
                                          mx: item?.iconUrl ? 2 : 2,
                                          mb: 2,
                                          borderRadius: "10px",
                                          // Dynamic background color
                                          // border: `1.5px solid ${borderColor}`, // Dynamic border color
                                          minWidth: "120px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "16px",
                                            px: 0.3,
                                          }}
                                        >
                                          {/* {sub.subSkill}{" "} */}
                                          No subskills entered
                                          {/* Display each subskill name */}
                                        </Typography>
                                      </Paper>
                                    ) : (
                                      <Box
                                        key={index}
                                        sx={{
                                          p: 0.4,
                                          // mx: 1,
                                          mx: item?.iconUrl ? 2 : 2,
                                          mb: 2,
                                          borderRadius: "10px",
                                          // Dynamic background color
                                          // border: `1.5px solid ${borderColor}`, // Dynamic border color
                                          minWidth: "120px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Stack direction="row" spacing={1}>
                                          <Chip label="No subskills entered" />
                                        </Stack>
                                      </Box>
                                    )}
                                  </Paper>
                                ) : (
                                  <SubSkills
                                    subSkills={filteredSubSkills}
                                    parentName={item.name}
                                    bgColor={item.barPrimaryColor || "#fff"}
                                    avatarIcon={item?.iconUrl}
                                  />
                                )}
                              </Grid>
                            );
                          })}
                      </Grid>
                    )}
                  </Grid>
                  {relevanceResult[0]?.estYear == null ||
                  relevanceResult[0]?.estYear === 0 ||
                  relevanceResult[0]?.expiryYear == null ||
                  relevanceResult[0]?.expiryYear === 0 ? (
                    ""
                  ) : (
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      xl={6}
                      className="top-skill-validity"
                    >
                      {/* animated image box */}
                      <Box>
                        <PrimarySkillRelevanceAIResult
                          relevanceResult={relevanceResult}
                        />
                      </Box>
                    </Grid>
                  )}
                  {resultData?.AllTools?.length !== 0 && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      xl={6}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Img
                          sx={{
                            width: { xs: "38px", md: "100%" },
                            maxWidth: "51px",
                          }}
                          src="/assets/images/tools.svg"
                          alt="techworth"
                          loading="lazy"
                        />
                        <Typography
                          variant={{ xs: "h4", md: "h3" }}
                          sx={{ fontWeight: 600, pl: { xs: 1, lg: 2 } }}
                        >
                          All tools
                        </Typography>
                      </Box>

                      {loading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            py: 2,
                          }}
                        >
                          <CircularProgress color="primary" size={30} />
                        </Box>
                      ) : (
                        <Grid
                          container
                          sx={{ gap: 2, py: 2, flexWrap: "wrap" }}
                        >
                          {skillsData?.AllTools?.map((item, index) => (
                            <Grid item key={index}>
                              <Paper
                                sx={{
                                  borderRadius: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                key={index}
                              >
                                {item?.iconUrl && ( // Only render Avatar if iconUrl is present
                                  <Avatar
                                    src={item?.iconUrl}
                                    alt="Icons"
                                    sx={{
                                      width: "35px",
                                      height: "35px",
                                      p: "5px",
                                      ml: 2,
                                    }}
                                    slotProps={{
                                      img: {
                                        loading: "lazy",
                                      },
                                    }}
                                  />
                                )}
                                <Typography
                                  sx={{
                                    p: 2,
                                    fontSize: {
                                      xs: "16px",
                                      md: "20px",
                                      lg: "20px",
                                    },
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} lg={12} xl={6}>
                    {/* top skill validity box */}
                    <Box
                      sx={{
                        display: "flex",

                        textAlign: { xs: "center", md: "center", lg: "left" },
                        alignItems: "center",
                        flexDirection: {
                          xs: "column",
                          md: "column",
                          lg: "row",
                        },
                      }}
                      className={"truselfy-tech-relevance"}
                    >
                      <Box sx={{ p: 1, py: { xs: 2, md: 2, lg: 0 } }}>
                        <Img
                          sx={{ width: { xs: "38px", md: "100%" } }}
                          style={{
                            maxWidth: "119px",
                            height: "auto",
                            width: "100%",
                          }}
                          src="/assets/images/rocket.gif"
                          loading="lazy"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                            lg: "flex-start",
                            xl: "flex-start",
                          },
                          gap: 1,
                          py: 2,
                          px: 1,
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#584AAF",
                            fontWeight: 600,
                          }}
                        >
                          AI Boost your career
                        </Typography>
                        <Typography variant="subtitle2">
                          Find out how long your each skill will stay relevant
                          in this ever-changing landscape?
                        </Typography>
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: "#fff",
                            zIndex: 1,
                            color: "#584AAF",

                            borderColor: "#584AAF",
                            "&:hover": {
                              backgroundColor: "#4747a6 !important",
                              color: "#fff",
                              borderColor: "#fff",
                            },
                          }}
                          target="_blank"
                          href={"https://www.techrelevance.trueselfy.com/"}
                          endIcon={<ChevronRightIcon size="large" />}
                        >
                          Boost your career
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/* <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6} md={12} lg={6}>
                    <Box
                      sx={{
                        border: "1.5px solid #D3D3D3",
                        borderRadius: "20px",
                        height: "100%",
                        boxSizing: "inherit",
                        p: { xs: 2, lg: 4 },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: { xs: "80px", sm: "108px", md: "80px", lg: "108px" },
                          width: "100%",
                          height: "auto",
                          overflow: "hidden",
                          "& >img": { width: "100%", height: "100%" },
                          display: "block",
                          m: "0px auto",
                          mb: { xs: "30px", lg: "52px" },
                        }}
                      >
                        <img src="/assets/images/no-of-references.png" alt="no-of-references" />
                      </Box>
                      <Typography
                        variant="h1"
                        sx={{ textAlign: "center", fontWeight: 600, mb: "20px" }}
                      >
                        {referralslist?.length}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "center", color: "#636363", mb: "20px" }}
                      >
                        No. of references provided
                      </Typography>
                      <Box sx={{ textAlign: "center" }}>
                        <Link
                          component="button"
                          sx={{
                            display: "inline-flex",
                            gap: 1,
                            alignItems: "center",
                            fontSize: "24px",
                            fontWeight: 500,
                            color: "#0D9B21",
                            textDecorationColor: "inherit",
                          }}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          View list
                          <Box
                            component="span"
                            sx={{
                              width: "22px",
                              height: "22px",
                              display: "grid",
                              placeContent: "center",
                              background: "#0D9B21",
                              borderRadius: "12px",
                              color: "#fff",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            <KeyboardArrowRight />{" "}
                          </Box>
                        </Link>{" "}
                      </Box>
                    </Box>
                  </Grid>
                </Grid> */}
                {/* references grid  */}
                {IncognitoModeStatus === false && (
                  <Grid
                    container
                    sx={{
                      border: "1.5px solid #E9E9E9",
                      borderRadius: "20px",
                      py: { xs: 0.5, sm: 2, md: 2, lg: 2 },
                      px: { xs: 1, sm: 3, md: 3, lg: 3 },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{ px: 1, py: 1 }}
                      className={"provided-references"}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Box>
                          <Img
                            sx={{
                              width: { xs: "38px", md: "100%" },
                              maxWidth: "51px",
                            }}
                            src="/assets/images/provide-references.svg"
                            loading="lazy"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle1">
                            No. of references provided
                          </Typography>
                          <Link
                            sx={{
                              display: {
                                xs: "none",
                                sm: "flex",
                                md: "flex",
                                lg: "flex",
                              },
                              color: "#393939",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            component="button"
                            onClick={() => {
                              setOpen(true);
                            }}
                            variant="subtitle1"
                          >
                            View list
                            <ChevronRightIcon
                              sx={{
                                ml: 1,
                                background: "#000",
                                color: "#fff",
                                borderRadius: "50px",
                              }}
                              fontSize="small"
                            />
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            display: {
                              xs: "none",
                              sm: "block",
                              md: "block",
                              lg: "block",
                            },
                          }}
                        >
                          <Typography
                            sx={{ color: "#232323", fontWeight: 700 }}
                            variant="h4"
                          >
                            {referralslist?.length}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "none",
                            md: "none",
                            lg: "none",
                          },
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{ color: "#232323", fontWeight: 700 }}
                          variant="h4"
                        >
                          {referralslist?.length}
                        </Typography>
                        <Link
                          sx={{
                            color: "#393939",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                          component="button"
                          onClick={() => {
                            setOpen(true);
                          }}
                          variant="subtitle1"
                        >
                          View list
                          <ChevronRightIcon
                            sx={{
                              ml: 1,
                              background: "#000",
                              color: "#fff",
                              borderRadius: "50px",
                            }}
                            fontSize="small"
                          />
                        </Link>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      className={"endorsed-references"}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Box>
                          <Img
                            sx={{
                              width: { xs: "38px", md: "100%" },
                              maxWidth: "51px",
                            }}
                            src="/assets/images/peer-reviewed.svg"
                            loading="lazy"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle1">
                            No. of references endorsed
                          </Typography>
                          {referralslist?.filter(
                            (item) => item?.status === "success"
                          )?.length > 0 && (
                            <Link
                              component="button"
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "flex",
                                  md: "flex",
                                  lg: "flex",
                                },
                                color: "#393939",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                setOpenEndorse(true);
                              }}
                              variant="subtitle1"
                            >
                              View list
                              <ChevronRightIcon
                                sx={{
                                  ml: 1,
                                  background: "#000",
                                  color: "#fff",
                                  borderRadius: "50px",
                                }}
                                fontSize="small"
                              />
                            </Link>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: {
                              xs: "none",
                              sm: "block",
                              md: "block",
                              lg: "block",
                            },
                          }}
                        >
                          <Typography
                            sx={{ color: "#232323", fontWeight: 700 }}
                            variant="h4"
                          >
                            {
                              referralslist?.filter(
                                (item) => item?.status === "success"
                              )?.length
                            }
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "none",
                            md: "none",
                            lg: "none",
                          },
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{ color: "#232323", fontWeight: 700 }}
                          variant="h4"
                        >
                          {
                            referralslist?.filter(
                              (item) => item?.status === "success"
                            )?.length
                          }
                        </Typography>
                        {referralslist?.filter(
                          (item) => item?.status === "success"
                        )?.length > 0 && (
                          <Link
                            sx={{
                              color: "#393939",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "0 auto",
                            }}
                            component="button"
                            onClick={() => {
                              setOpenEndorse(true);
                            }}
                            variant="subtitle1"
                          >
                            View list
                            <ChevronRightIcon
                              sx={{
                                ml: 1,
                                background: "#000",
                                color: "#fff",
                                borderRadius: "50px",
                              }}
                              fontSize="small"
                            />
                          </Link>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* true edge modal component */}
          {getTrueEdge && resultData?.coverLetter === undefined ? (
            <Grid item xs={12} id="salary-feedback">
              <Typography
                variant="h5"
                display="block"
                sx={{ color: "#636363", textAlign: "center", mt: 3, mb: 3 }}
              >
                Parameters changes so, Comeback after few days and check again!
              </Typography>
              <Typography
                variant="h2"
                display="block"
                color="primary"
                sx={{
                  fontSize: "40px",
                  textAlign: "center",
                  fontWeight: 600,
                  mb: "42px",
                }}
              >
                Provide your feedback and Get your TrueEdge{" "}
              </Typography>
              <Box
                sx={{
                  border: "1.5px solid #D3D3D3",
                  borderRadius: "20px",
                  p: { xs: 2, md: 3, lg: 6 },
                }}
              >
                {selectedValue === "full-time-salary" && (
                  <Typography
                    variant="h2"
                    display="block"
                    sx={{
                      fontSize: "40px",
                      textAlign: "center",
                      fontWeight: 600,
                      mb: "42px",
                    }}
                  >
                    My Current salary is
                  </Typography>
                )}

                {selectedValue === "contract-salary" && (
                  <Typography
                    variant="h2"
                    display="block"
                    sx={{
                      fontSize: "40px",
                      textAlign: "center",
                      fontWeight: 600,
                      mb: "42px",
                    }}
                  >
                    My Contract salary is
                  </Typography>
                )}

                <Typography
                  variant="h5"
                  display="block"
                  sx={{ color: "#636363", textAlign: "center", mb: 3 }}
                >
                  Your input is valuable in helping us better understand your{" "}
                  <br /> current earnings and tailor our service accordingly.{" "}
                </Typography>
                <RadioGroup
                  sx={{
                    display: { xs: "flex", lg: "flex" },
                    flexDirection: { xs: "row", lg: "row" },
                    alignItems: "flex-start",
                    justifyContent: { xs: "center", lg: "center" },
                    flexWrap: { xs: "wrap", lg: "wrap" },
                    "& .MuiFormControlLabel-root": {
                      // flex: 1,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "inherit",
                      fontWeight: "inherit",
                      textAlign: "center",
                    },
                  }}
                  value={salaryFeedback}
                  onChange={handleSalaryFeedback}
                >
                  {contractSalarySelect
                    ? resultData?.contractSalaryPartition?.map(
                        (value, index, array) => {
                          return (
                            <FormControlLabel
                              key={index} // Assuming 'value' is a unique identifier
                              value={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              label={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                    lg: "22px",
                                  },
                                  fontWeight: 600,
                                },
                              }}
                              labelPlacement="bottom"
                              control={
                                <Radio
                                  icon={
                                    <RadioIcon
                                      src={salaryData[index]?.iconSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                  checkedIcon={
                                    <CheckedRadioIcon
                                      src={salaryData[index]?.checkedSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                />
                              }
                            />
                          );
                        }
                      )
                    : resultData?.salaryPartition?.map(
                        (value, index, array) => {
                          return (
                            <FormControlLabel
                              key={index} // Assuming 'value' is a unique identifier
                              value={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              label={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                    lg: "22px",
                                  },
                                  fontWeight: 600,
                                },
                              }}
                              labelPlacement="bottom"
                              control={
                                <Radio
                                  icon={
                                    <RadioIcon
                                      src={salaryData[index]?.iconSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                  checkedIcon={
                                    <CheckedRadioIcon
                                      src={salaryData[index]?.checkedSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                />
                              }
                            />
                          );
                        }
                      )}
                </RadioGroup>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    type="text"
                    multiline
                    minRows={4}
                    placeholder="Add a comment..."
                    fullWidth
                    value={salaryComment}
                    onChange={(e) => setSalaryComment(e.target.value)}
                    sx={{ mb: "40px", mt: "40px", maxWidth: "800px" }}
                  />
                </Box>

                {User_Cover_letter ? (
                  <LoadingButton
                    sx={{ display: "block", m: "0px auto" }}
                    variant={"contained"}
                    disabled={salaryFeedback === ""}
                    onClick={() => getBiolater()}
                  >
                    Get your True Edge
                  </LoadingButton>
                ) : (
                  ""
                )}

                {/* <Button
                  variant="contained"
                  color="primary"
                  sx={{ display: "block", m: "0px auto" }}
                >
                  {" "}
                  Get your TrueEdge
                </Button> */}
              </Box>
            </Grid>
          ) : (
            ""
          )}

          {openTrueEdge ? (
            <CoverLetter
              setOpen={setOpenTrueEdge}
              open={openTrueEdge}
              data={{ ...userForm, coverLetter: text }}
            />
          ) : (
            <CoverLetter
              setOpen={setViewTrueEdge}
              open={viewTrueEdge}
              data={resultData}
            />
          )}
        </Grid>
        <Box sx={{ textAlign: "center", mt: "64px" }}>
          {" "}
          <Button
            className="btn"
            variant="outlined"
            endIcon={<HomeOutlinedIcon />}
            // onClick={() => {
            //   navigate("/");
            // }}
            href={"/"}
          >
            Back to Home
          </Button>
        </Box>
        <Box>
          <Dialog
            open={openPopup}
            onClose={handleClosePopup}
            id="true-edge-popup"
          >
            <DialogContent sx={{ px: { xs: 3, lg: 10 }, pt: 10, pb: 2 }}>
              <IconButton
                onClick={() => setOpenPopUp(false)}
                size="small"
                sx={{
                  pt: 2,
                  pr: 2,
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  zIndex: 99,
                  background: "#FFF",
                  "&:hover": {
                    background: "#fff",
                  },
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
              <Box
                sx={{
                  position: "relative",
                  height: "230px",
                  overflow: "hidden",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={"../../assets/images/true-edge-open.gif"}
                  alt="true edge image"
                  style={{
                    height: "100%",
                    width: "100%",
                    maxWidth: "230px",
                    maxHeight: "230px",
                    objectFit: "cover",
                  }}
                  loading="lazy"
                />
              </Box>
              <Typography
                variant="h2"
                sx={{ fontWeight: 700, textAlign: "center", pb: 2 }}
              >
                Get Your TrueEdge
              </Typography>
              <Typography
                display="block"
                sx={{
                  fontSize: "18px",
                  textAlign: "center",
                  fontWeight: 600,
                  pb: 1,
                }}
              >
                Provide your feedback and Get your TrueEdge{" "}
              </Typography>
              <Typography sx={{ textAlign: "center" }}>
                You are Unique. Your Unique Cover Letter to stand out from the
                crowd (chatGPT enabled)
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", paddingTop: "8px" }}>
              <Button
                type="button"
                // size="large"
                variant="contained"
                color="primary"
                onClick={() => {
                  setTrueEdge(true);
                  setOpenPopUp(false);
                }}
                sx={{ mb: 3 }}
                endIcon={
                  <KeyboardArrowRight sx={{ fontSize: "24px !important" }} />
                }
              >
                Proceed
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openEndorse}
            onClose={handleCloseEndorse}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={800}
            id="references-dialog"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #EBEBEB",
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{ fontWeight: 500, lineHeight: 1.8 }}
              >
                Your endorsed References list
              </Typography>
              <IconButton onClick={handleCloseEndorse}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ReferencesTable
                  referralsList={referralslist?.filter(
                    (item) => item?.status === "success"
                  )}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={800}
            id="reference-all-dialog"
          >
            <DialogTitle
              id="referenced-dialog-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // borderBottom: "1px solid #EBEBEB",
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{ fontWeight: 500, lineHeight: 1.8 }}
              >
                Your References list
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ReferencesTable referralsList={referralslist} />
              </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
          </Dialog>
        </Box>
        {/* add information dialog for Incognito mode */}
      </Box>
      <Footer />
    </>
  );
}

export default TechResultV3;
