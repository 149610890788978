import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import ReferencesTable from "../../common/ReferencesTable";

function ReferencesContainer() {
  const referralslist =
    useSelector((state) => state?.ReferralsReducer?.referrals) || [];
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  const resultPageSections = useSelector(
    (state) => state?.ResultReducer?.resultPageSections || []
  );
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseEndorse = () => setOpenEndorse(false);
  const [open, setOpen] = useState(false);
  const [openEndorse, setOpenEndorse] = useState(false);
  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ minWidth: { xs: "100%", md: "100%", lg: "70%" } }}>
              {/* References Stats Section */}
              {IncognitoModeStatus === false && (
                <Grid container spacing={2}>
                  {/* Left Card */}
                  <Grid item xs={12} sm={6} className={"provided-references"}>
                    <Box
                      sx={{
                        border: "1px solid #8DE49A",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Img
                          sx={{
                            width: { xs: "38px", md: "100%" },
                            height: "40px",
                            width: "40px",
                            maxWidth: "40px",
                          }}
                          src="/assets/images/provide-references-green.svg"
                          loading="lazy"
                        />
                        <Box sx={{ marginLeft: "16px" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            No. of references provided
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              marginTop: "0px !important",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#232323",
                                fontWeight: 700,
                                mx: "16px",
                              }}
                              variant="h4"
                            >
                              {referralslist?.length}
                            </Typography>
                            {referralslist?.length > 0 && (
                              <Link
                                sx={{
                                  color: "#393939",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                component="button"
                                onClick={() => {
                                  setOpen(true);
                                }}
                                variant="subtitle1"
                              >
                                View list
                                <ChevronRightIcon
                                  sx={{
                                    ml: 1,
                                    background: "#000",
                                    color: "#fff",
                                    borderRadius: "50px",
                                  }}
                                  fontSize="small"
                                />
                              </Link>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Right Card */}
                  <Grid item xs={12} sm={6} className={"endorsed-references"}>
                    <Box
                      sx={{
                        border: "1px solid #8DE49A",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Img
                          sx={{
                            width: { xs: "38px", md: "100%" },
                            height: "40px",
                            width: "40px",
                            maxWidth: "40px",
                          }}
                          src="/assets/images/peer-reviewed-green.svg"
                          loading="lazy"
                        />
                        <Box sx={{ marginLeft: "16px" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            No. of references endorsed
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              marginTop: "0px !important",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#232323",
                                fontWeight: 700,
                                mx: "16px",
                              }}
                              variant="h4"
                            >
                              {
                                referralslist?.filter(
                                  (item) => item?.status === "success"
                                )?.length
                              }
                            </Typography>
                            {referralslist?.filter(
                              (item) => item?.status === "success"
                            )?.length > 0 && (
                              <Link
                                component="button"
                                sx={{
                                  color: "#393939",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setOpenEndorse(true);
                                }}
                                variant="subtitle1"
                              >
                                View list
                                <ChevronRightIcon
                                  sx={{
                                    ml: 1,
                                    background: "#000",
                                    color: "#fff",
                                    borderRadius: "50px",
                                  }}
                                  fontSize="small"
                                />
                              </Link>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
        <Box>
          <Dialog
            open={openEndorse}
            onClose={handleCloseEndorse}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={800}
            id="references-dialog"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #EBEBEB",
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{ fontWeight: 500, lineHeight: 1.8 }}
              >
                Your endorsed References list
              </Typography>
              <IconButton onClick={handleCloseEndorse}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ReferencesTable
                  referralsList={referralslist?.filter(
                    (item) => item?.status === "success"
                  )}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={800}
            id="reference-all-dialog"
          >
            <DialogTitle
              id="referenced-dialog-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // borderBottom: "1px solid #EBEBEB",
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{ fontWeight: 500, lineHeight: 1.8 }}
              >
                Your References list
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ReferencesTable referralsList={referralslist} />
              </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
          </DialogActions> */}
          </Dialog>
        </Box>
      </Box>
    </>
  );
}

export default ReferencesContainer;
