import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardArrowRight, Circle } from "@mui/icons-material";

function JobsLinkedinIndeed() {
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  const [jobLocation, setJobLocation] = useState();
  const [indeedUrl, setIndeedUrl] = useState(
    resultData?.results?.countryCode?.indeedUrl
  );
  const [monthlySalary, setMonthlySalary] = useState();

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                borderRadius: "20px",
                // boxShadow: "0px 0px 4px 0px #CDCAC073",
                border: "1.5px solid #8DE49A",
                background: "#F1FFF4",
                // height: "100%",
                boxSizing: "inherit",
                py: 2,
                px: 3,
                minWidth: { xs: "100%", md: "100%", lg: "70%" },
                // mb: "20px !important",
              }}
              className={"find-jobs-container"}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "12px", sm: "12px", md: "18px" },
                }}
              >
                Jobs that best fit your expertise and TechValue
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "10px",
                  gap: 2,
                }}
              >
                <Link
                  sx={{
                    maxWidth: {
                      xs: "130px",
                      sm: "13 0px",
                      md: "180px",
                    },
                    width: "100%",
                    display: "inline-flex",
                    gap: "4px",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "13px", sm: "13px", md: "16px" },
                    fontWeight: 500,
                    backgroundColor: "rgba(43, 179, 68, 0.1)",
                    color: "#2BB344",
                    // border: "2px solidrgb(23, 29, 24)",
                    p: "8px 12px",
                    borderRadius: "10px",
                    textDecoration: "none",
                    // "&:hover": {
                    //   backgroundColor: "#0D9B21 !important",
                    //   color: "#fff",
                    //   borderColor: "#fff",
                    // },
                  }}
                  target="_blank"
                  href={`https://www.linkedin.com/jobs/search/?currentJobId=3788215852&f_TPR=r604800&keywords=${
                    resultData?.topSkills
                      ? resultData?.topSkills[0]["name"]
                      : ""
                  }&location=${jobLocation}&origin=JOB_SEARCH_PAGE_JOB_FILTER`}
                >
                  <img
                    src="../../../assets/images/linkedin-square.png"
                    alt="Linkedin"
                    width="20px"
                    loading="lazy"
                  />
                  Linkedin
                  <KeyboardArrowRight />{" "}
                </Link>{" "}
                <Link
                  sx={{
                    maxWidth: { xs: "130px", sm: "130px", md: "180px" },
                    width: "100%",
                    display: "inline-flex",
                    gap: "4px",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "13px", sm: "13px", md: "16px" },
                    fontWeight: 500,
                    backgroundColor: "rgba(43, 179, 68, 0.1)",
                    color: "#2BB344",
                    // border: "2px solid #2BB344",
                    p: "8px 12px",
                    borderRadius: "10px",
                    textDecoration: "none",
                    // "&:hover": {
                    //   backgroundColor: "#0D9B21 !important",
                    //   color: "#fff",
                    //   borderColor: "#0D9B21",
                    // },
                  }}
                  target="_blank"
                  href={`${indeedUrl}/jobs?q=${
                    resultData?.topSkills
                      ? resultData?.topSkills[0]["name"]
                      : ""
                  }-${monthlySalary}&l=${jobLocation}`}
                >
                  <img
                    src="../../../assets/images/indeed.png"
                    alt="Indeed"
                    width="20px"
                    loading="lazy"
                  />
                  Indeed
                  <KeyboardArrowRight />{" "}
                </Link>{" "}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default JobsLinkedinIndeed;
