import axios from "axios";
import React from "react";
import { UAParser } from "ua-parser-js";

async function DeviceInfoAndLocation(setLocation, setDeviceInfo) {
  try {
    // Get the user's public IP address
    const ipResponse = await axios.get("https://api.ipify.org/?format=json");
    const ipAddress = ipResponse.data.ip;

    // Fetch location data and device info concurrently
    const [locationResponse, deviceInfo] = await Promise.all([
      fetch(`https://ipapi.co/${ipAddress}/json/`).then((response) =>
        response.json()
      ),
      getDeviceInfo(),
    ]);

    // Check if location data is valid and set it
    if (!locationResponse?.error) {
      setLocation(locationResponse);
    }

    // Check if device info contains browser or OS details and set it
    if (deviceInfo?.browser || deviceInfo?.os) {
      setDeviceInfo(deviceInfo);
    }
  } catch (error) {
    // console.log("Error:", error);
    // Handle specific errors here if needed
  }
}

async function getDeviceInfo() {
  const parser = new UAParser();
  const userAgent = parser.getResult();
  const deviceInfo = {
    device: userAgent?.device?.model || "",
    os: userAgent?.os?.name || "",
    browser: userAgent?.browser?.name || "",
  };
  return deviceInfo;
}

export default DeviceInfoAndLocation;
