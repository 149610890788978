import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardArrowRight, Circle } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { submitSalaryFeedback } from "../../stepper_form/stepper.helper";
import { getEmail } from "../../../utils/storage";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import CoverLetter from "../../common/CoverLetter";
import {
  getCoverLeter,
  getCoverLetterForResult,
} from "../../stepper_form/stepper.helper";

function TrueEdgeContainer() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:1024px)");
  const isDesktop = useMediaQuery("(max-width:1400px)");
  const [loading, setLoading] = React.useState(false);
  const resultPageSections = useSelector(
    (state) => state?.ResultReducer?.resultPageSections || []
  );
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  const userForm = useSelector(
    (state) =>
      state?.OldFormReducer || state?.ResultReducer?.publicResult?.userData
  );
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  const [selectedValue, setSelectedValue] = useState("full-time-salary");
  const [contractSalarySelect, setContractSalarySelect] = useState(false);
  // cover letter code
  function removeEmptyKeysAndObjects(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          obj[key] === null ||
          obj[key] === undefined ||
          obj[key] === "" ||
          obj[key] === "No" ||
          key === "userlocation" ||
          key === "resultTime" ||
          key === "lastStepper" ||
          key === "deviceinfo" ||
          key === "location" ||
          key === "typeOfCompany"
        ) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
              delete obj[key];
            } else {
              obj[key].forEach((item) => {
                if (item && typeof item === "object" && "type" in item) {
                  delete item.type;
                }
              });
            }
          } else {
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
    }
    return obj;
  }
  function filterSalaryPartitions(value, index, array) {
    const isNotFirstOrLast = index > 0 && index < array.length - 1;
    const label = isNotFirstOrLast
      ? `${value}`
      : index === 0
      ? `<${value}`
      : `>${value}`;

    return label;
  }
  const [salaryComment, setSalaryComment] = useState("");
  const handleCloseEndorse = () => setOpenEndorse(false);
  const handleOpenTrueEdge = () => {
    setOpenTrueEdge(true);
    setText("");
  };

  const User_Cover_letter = useFeatureFlag("User_Cover_letter");
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const [salaryFeedback, setSalaryFeedback] = useState("");
  const handleSalaryFeedback = (event) => {
    setSalaryFeedback(event.target.value);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    // getBiolater();
  };
  const getBiolater = async () => {
    // console.log("removeObjects", removeEmptyKeysAndObjects(userForm));
    handleOpenTrueEdge();
    submitSalaryFeedback(resultData?._id, salaryFeedback, salaryComment);
    const techValue = resultData?.convertedTechValue;
    let userEmail = getEmail();
    let resultId = resultData?._id;
    const userFormData = { ...userForm };
    const IncognitoMode = IncognitoModeStatus;
    let userData = removeEmptyKeysAndObjects(userFormData);
    let userObject = {
      userData,
      resultId,
      techValue,
      userEmail,
      IncognitoMode,
    };

    getCoverLeter(userObject, setText, setLoading, dispatch, resultData);
  };
  const RadioIcon = ({ src }) => {
    return (
      <Box
        sx={{
          width: { xs: "60px", md: "80px", lg: "100px" },
          height: { xs: "60px", md: "80px", lg: "100px" },
          borderRadius: "50%",
          // border: "2px solid transparent",
          overflow: "hidden",
          "& >img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={src} alt="radio-img" loading="lazy" />
      </Box>
    );
  };
  const CheckedRadioIcon = ({ src }) => {
    return (
      <Box
        sx={{
          // width: { xs: "80px", md: "80px", lg: "115px" },
          // height: { xs: "80px", md: "80px", lg: "115px" },
          width: { xs: "60px", md: "80px", lg: "100px" },
          height: { xs: "60px", md: "80px", lg: "100px" },
          borderRadius: "50%",
          // border: "2px solid #000",
          // boxShadow: "0px 0px 10px 5px rgba(205, 202, 192,0.7)",
          overflow: "hidden",
          "& >img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={src} alt="checked-radio-img" loading="lazy" />
      </Box>
    );
  };
  const salaryData = [
    {
      id: 0,
      iconSrc: "/assets/images/unchecked-1-3lac.png",
      checkedSrc: "/assets/images/1-3lac.png",
      label: "1-3 Lacs",
      value: "-30%",
    },
    {
      id: 1,
      iconSrc: "/assets/images/unchecked-3-5lac.png",
      checkedSrc: "/assets/images/3-5lac.png",
      label: "3-5 Lacs",
      value: "-20%",
    },
    {
      id: 2,
      iconSrc: "/assets/images/unchecked-5-10lac.png",
      checkedSrc: "/assets/images/5-10lac.png",
      label: "5-10 Lacs",
      value: "-10%",
    },
    {
      id: 3,
      iconSrc: "/assets/images/unchecked-10-15lac.png",
      checkedSrc: "/assets/images/10-15lac.png",
      label: "10-15 Lacs",
      value: "-5%",
    },
    {
      id: 4,
      iconSrc: "/assets/images/unchecked-15-20lac.png",
      checkedSrc: "/assets/images/15-20lac.png",
      label: "15-20 Lacs",
      value: "10%",
    },
    {
      id: 5,
      iconSrc: "/assets/images/unchecked-20-25lac.png",
      checkedSrc: "/assets/images/20-25lac.png",
      label: "20-25 Lacs",
      value: "20%",
    },
    {
      id: 6,
      iconSrc: "/assets/images/unchecked-25-30lac.png",
      checkedSrc: "/assets/images/25-30lac.png",
      label: "25-30 Lacs",
      value: "30%",
    },
  ];
  const [getTrueEdge, setTrueEdge] = useState(false);
  const [viewTrueEdge, setViewTrueEdge] = useState(false);
  const [openTrueEdge, setOpenTrueEdge] = useState(false);

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                border: "1px solid #8DE49A",
                borderRadius: "8px",
                justifyContent: "space-between",
                minWidth: { xs: "100%", md: "100%", lg: "70%" },
                // backgroundColor: "#F1FFF4",
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              className={"true-edge-container"}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: 1, md: 2, xl: 2 },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                  },
                  py: 3,
                  pb: { xs: 0, sm: 3 },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "60px",
                    width: "100%",
                    height: "auto",
                    overflow: "hidden",
                    "& >img": { width: "100%", height: "100%" },
                  }}
                >
                  <img
                    src="/assets/images/trueedge-mail.png"
                    alt="trueedge"
                    loading="lazy"
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      textAlign: { xs: "center", sm: "center", md: "left" },
                      color: "#2BB344",
                    }}
                  >
                    TrueEdge
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#636363",
                      textAlign: { xs: "center", sm: "left" },
                      mr: { lg: "10px" },
                    }}
                  >
                    {resultData?.coverLetter !== ""
                      ? `Your Unique Cover Letter`
                      : `You are Unique. Your Unique Cover Letter to stand out from the crowd
                    (chatgpt enabled)`}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  pt: { xs: 3, sm: 0, md: 0, lg: 0 },
                  pb: { xs: 3, sm: 0, lg: 0 },
                }}
              >
                {resultData?.coverLetter !== "" ? (
                  <Button
                    type="button"
                    // size="large"
                    variant="outlined"
                    onClick={() => {
                      setViewTrueEdge(true);
                      // setOpenTrueEdge(true);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#0D9B21 !important",
                        color: "#fff",
                        borderColor: "#fff",
                      },
                    }}
                    endIcon={
                      <KeyboardArrowRight
                        sx={{ fontSize: "24px !important" }}
                      />
                    }
                  >
                    View
                  </Button>
                ) : (
                  <Button
                    type="button"
                    // size="large"
                    variant="outlined"
                    onClick={() => {
                      setTrueEdge(true);
                      window.scrollTo({
                        top: isMobile ? 2600 : 1000,
                        behavior: "smooth",
                      });
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#0D9B21 !important",
                        color: "#fff",
                        borderColor: "#fff",
                      },
                    }}
                    endIcon={
                      <KeyboardArrowRight
                        sx={{ fontSize: "24px !important" }}
                      />
                    }
                  >
                    Proceed
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* true edge modal component */}
        {getTrueEdge && resultData?.coverLetter?.length === 0 ? (
          <Grid item xs={12}>
            <Box id="salary-feedback">
              <Typography
                variant="h5"
                display="block"
                sx={{ color: "#636363", textAlign: "center", mb: 3, mt: 1.5 }}
              >
                Parameters changes so, Comeback after few days and check again!
              </Typography>
              <Typography
                variant="h2"
                display="block"
                color="primary"
                sx={{
                  fontSize: "40px",
                  textAlign: "center",
                  fontWeight: 600,
                  mb: "42px",
                }}
              >
                Provide your feedbaqck and Get your TrueEdge{" "}
              </Typography>
              <Box
                sx={{
                  border: "1.5px solid #D3D3D3",
                  borderRadius: "20px",
                  p: { xs: 2, md: 3, lg: 6 },
                }}
              >
                {selectedValue === "full-time-salary" && (
                  <Typography
                    variant="h2"
                    display="block"
                    sx={{
                      fontSize: "40px",
                      textAlign: "center",
                      fontWeight: 600,
                      mb: "42px",
                    }}
                  >
                    My Current salary is
                  </Typography>
                )}

                {selectedValue === "contract-salary" && (
                  <Typography
                    variant="h2"
                    display="block"
                    sx={{
                      fontSize: "40px",
                      textAlign: "center",
                      fontWeight: 600,
                      mb: "42px",
                    }}
                  >
                    My Contract salary is
                  </Typography>
                )}

                <Typography
                  variant="h5"
                  display="block"
                  sx={{ color: "#636363", textAlign: "center", mb: 3 }}
                >
                  Your input is valuable in helping us better understand your{" "}
                  <br /> current earnings and tailor our service accordingly.{" "}
                </Typography>
                <RadioGroup
                  sx={{
                    display: { xs: "flex", lg: "flex" },
                    flexDirection: { xs: "row", lg: "row" },
                    alignItems: "flex-start",
                    justifyContent: { xs: "center", lg: "center" },
                    flexWrap: { xs: "wrap", lg: "wrap" },
                    "& .MuiFormControlLabel-root": {
                      // flex: 1,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "inherit",
                      fontWeight: "inherit",
                      textAlign: "center",
                    },
                  }}
                  value={salaryFeedback}
                  onChange={handleSalaryFeedback}
                >
                  {contractSalarySelect
                    ? resultData?.results?.contractSalaryPartition?.map(
                        (value, index, array) => {
                          return (
                            <FormControlLabel
                              key={index} // Assuming 'value' is a unique identifier
                              value={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              label={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                    lg: "22px",
                                  },
                                  fontWeight: 600,
                                },
                              }}
                              labelPlacement="bottom"
                              control={
                                <Radio
                                  icon={
                                    <RadioIcon
                                      src={salaryData[index]?.iconSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                  checkedIcon={
                                    <CheckedRadioIcon
                                      src={salaryData[index]?.checkedSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                />
                              }
                            />
                          );
                        }
                      )
                    : resultData?.results?.salaryPartition?.map(
                        (value, index, array) => {
                          return (
                            <FormControlLabel
                              key={index} // Assuming 'value' is a unique identifier
                              value={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              label={filterSalaryPartitions(
                                value,
                                index,
                                array
                              )}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                    lg: "22px",
                                  },
                                  fontWeight: 600,
                                },
                              }}
                              labelPlacement="bottom"
                              control={
                                <Radio
                                  icon={
                                    <RadioIcon
                                      src={salaryData[index]?.iconSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                  checkedIcon={
                                    <CheckedRadioIcon
                                      src={salaryData[index]?.checkedSrc}
                                    />
                                  } // Use optional chaining to avoid potential errors
                                />
                              }
                            />
                          );
                        }
                      )}
                </RadioGroup>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    type="text"
                    multiline
                    minRows={4}
                    placeholder="Add a comment..."
                    fullWidth
                    value={salaryComment}
                    onChange={(e) => setSalaryComment(e.target.value)}
                    sx={{ mb: "40px", mt: "40px", maxWidth: "800px" }}
                  />
                </Box>

                {User_Cover_letter ? (
                  <LoadingButton
                    sx={{ display: "block", m: "0px auto" }}
                    variant={"contained"}
                    disabled={salaryFeedback === ""}
                    onClick={() => getBiolater()}
                  >
                    Get your True Edge
                  </LoadingButton>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Grid>
        ) : null}
        {viewTrueEdge ? (
          <CoverLetter
            setOpen={setViewTrueEdge}
            open={viewTrueEdge}
            data={resultData}
          />
        ) : (
          <CoverLetter
            setOpen={setOpenTrueEdge}
            open={openTrueEdge}
            data={{ ...userForm, coverLetter: text }}
          />
        )}
      </Box>
    </>
  );
}

export default TrueEdgeContainer;
