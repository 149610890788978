import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { setResultPageCarousel } from "../../redux/action/result";
import { getResultPageCarousel } from "../stepper_form/stepper.helper";

function ToolsComponent() {
  const getIconBySkillName = async (skillName) => {
    const svgIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.svg`;
    const pngIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.png`;
    const darkIconPath = `${process.env.PUBLIC_URL}/assets/images/dark-icons/${skillName}.svg`;

    if (await imageExists(svgIconPath)) {
      return svgIconPath;
    } else if (await imageExists(pngIconPath)) {
      return pngIconPath;
    } else if (await imageExists(darkIconPath)) {
      return darkIconPath;
    }
    return null;
  };
  const imageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };
  const [loading, setLoading] = React.useState(false);
  const relevanceResultData = useSelector(
    (state) =>
      state?.ResultReducer?.publicResult?.userData?.relevanceResult || ""
  );
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  const [skillsData, setSkillsData] = useState({
    AllTools: [],
    AIToolsList: [],
    softwareToolsList: [],
  });

  useEffect(() => {
    const fetchSkillsWithIcons = async () => {
      setLoading(true);

      // Fetch all tools with icons
      // const toolsWithIcons = await Promise.all(
      //   resultData?.AllTools?.map(async (tool) => {
      //     const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
      //     return { ...tool, iconUrl }; // Append iconUrl to each tool object
      //   }) || []
      // );

      // Fetch Ai tools with icons
      const aitoolsIcons = await Promise.all(
        resultData?.AIToolsList?.map(async (tool) => {
          const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
          return { ...tool, iconUrl }; // Append iconUrl to each tool object
        }) || []
      );

      // Fetch Software tools with icons
      const softwaretoolsIcons = await Promise.all(
        resultData?.softwareToolsList?.map(async (tool) => {
          const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
          return { ...tool, iconUrl }; // Append iconUrl to each tool object
        }) || []
      );

      // Update state with both primary and secondary skills
      setSkillsData({
        // AllTools: toolsWithIcons,
        AIToolsList: aitoolsIcons,
        softwareToolsList: softwaretoolsIcons,
      });

      setLoading(false);
    };

    if (resultData) {
      fetchSkillsWithIcons(); // Call the async function
    } else {
      setLoading(false); // If no resultData, set loading to false
    }
  }, []); // Dependency on resultData
  function GetBackGroundColor(skillDemand, type) {
    if (skillDemand === "Trending") {
      switch (type) {
        case "y":
          return "#278C37";
        case "tech":
          return "#278C37";
        case "mgmt":
          return "#30B044";
        case "management":
          return "#30B044";
        case "generic":
          return "#38C94E";
        default:
          return "#278C37";
      }
    }
    if (skillDemand === "Obsolete") {
      switch (type) {
        case "y":
          return "#028EDF";
        case "tech":
          return "#028EDF";
        case "mgmt":
          return "#09A2FA";
        case "management":
          return "#09A2FA";
        case "generic":
          return "#79CDFD";
        default:
          return "#028EDF";
      }
    }
    if (skillDemand === "In demand") {
      switch (type) {
        case "y":
          return "#E9B112";
        case "tech":
          return "#E9B112";
        case "mgmt":
          return "#FEC113";
        case "management":
          return "#FEC113";
        case "generic":
          return "#FAD15D";
        default:
          return "#E9B112";
      }
    }
    if (skillDemand === "Getting outdated fast") {
      switch (type) {
        case "y":
          return "#C60015";
        case "tech":
          return "#C60015";
        case "mgmt":
          return "#E2031B";
        case "management":
          return "#E2031B";
        case "generic":
          return "#FF465A";
        default:
          return "#C60015";
      }
    }
  }

  function checkYearWithGpt(option) {
    const year = option?.expiryYear;
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (!year) {
      return {
        label: "Default",
        barPrimaryColor: "#e0e0e0",
        barSecondaryColor: "#e0e0e0",
      };
    }

    if (year > currentYear) {
      if (option?.chat_gpt === false && option?.estYear === 2010) {
        return {
          label: `Obsolete`,
          barPrimaryColor: "#028EDF",
          // barSecondaryColor: "#FFCDD2",
          barSecondaryColor: GetBackGroundColor("Obsolete", option?.type),
          // color: "red",
          color: "#ffffff",
        };
      } else {
        return {
          label: `Trending`,
          barPrimaryColor: "#278C37",
          // barSecondaryColor: "#A5D6A7",
          barSecondaryColor: GetBackGroundColor("Trending", option?.type),

          // color: "#26801E",
          color: "#ffffff",
        };
      }
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return {
        label: `In demand`,
        barPrimaryColor: "#E9B112",
        // barSecondaryColor: "#ffcc80",
        barSecondaryColor: GetBackGroundColor("In demand", option?.type),
        // color: "darkorange",
        color: "#ffffff",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      return {
        label: `Getting outdated fast`,
        barPrimaryColor: "#C60015",
        // barSecondaryColor: "#fff59d",
        barSecondaryColor: GetBackGroundColor(
          `Getting outdated fast`,
          option?.type
        ),

        // color: "#FF9D09",
        color: "#ffffff",
      };
    } else if (year <= currentYear - minYearDiff) {
      return {
        label: `Obsolete `,
        barPrimaryColor: "#028EDF",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: GetBackGroundColor(`Obsolete`, option?.type),
        // color: "red",
        color: "#ffffff",
      };
    }
  }

  const dispatch = useDispatch();
  const resultPageCarousel = useSelector(
    (state) => state?.ResultReducer?.resultPageCarousel || []
  );
  useEffect((item) => {
    // DeviceInfoAndLocation(setLocation, setDeviceInfo);
    // extractCityState();
    // convertCurrency();
    getResultPageCarousel(dispatch, setResultPageCarousel);
    // setTimeout(handleOpenPopup, 10000);
  }, []);
  return (
    <>
      {/* Tools component */}
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid item xs={12}>
          {/* Tools Section */}
          {resultData?.AllTools?.length !== 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid #8DE49A",
                  borderRadius: "20px",
                  p: {
                    xs: 3,
                    sm: 3,
                    md: 3,
                    lg: 3,
                  },
                  minWidth: {
                    xs: "100%",
                    md: "100%",
                    lg: "70%",
                  },
                  maxWidth: {
                    xs: "100%",
                    md: "100%",
                    lg: resultData?.AllTools?.length === 0 ? "100%" : "70%",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: "32px",
                    }}
                  >
                    <Img
                      sx={{
                        width: { xs: "38px", md: "100%" },
                        maxWidth: "51px",
                      }}
                      src="/assets/images/tools-green.svg"
                      alt="techworth"
                      loading="lazy"
                    />
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 600,
                        pl: { xs: 1, lg: 2 },
                        textAlign: "center",
                      }}
                    >
                      Productivity tools
                    </Typography>
                  </Box>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 2,
                      }}
                    >
                      <CircularProgress color="primary" size={30} />
                    </Box>
                  ) : (
                    <Grid
                      container
                      sx={{
                        gap: 2,
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {skillsData?.AIToolsList?.map((item, index) => (
                        <Grid item key={index}>
                          <Paper
                            sx={{
                              border: "1px solid #8DE49A",
                              borderRadius: "15px",
                              display: "flex",
                              boxShadow: "none",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            {item?.iconUrl && ( // Only render Avatar if iconUrl is present
                              <Avatar
                                src={item?.iconUrl}
                                alt="Icons"
                                sx={{
                                  width: "35px",
                                  height: "35px",
                                  p: "5px",
                                  ml: 2,
                                }}
                                // slotProps={{
                                //   img: {
                                //     loading: "lazy",
                                //   },
                                // }}
                              />
                            )}
                            <Typography
                              sx={{
                                p: 2,
                                fontSize: {
                                  xs: "16px",
                                  md: "20px",
                                  lg: "20px",
                                },
                              }}
                            >
                              {item?.name}
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                      {skillsData?.softwareToolsList?.map((item, index) => (
                        <Grid item key={index}>
                          <Paper
                            sx={{
                              border: "1px solid #8DE49A",
                              borderRadius: "15px",
                              display: "flex",
                              boxShadow: "none",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            {item?.iconUrl && ( // Only render Avatar if iconUrl is present
                              <Avatar
                                src={item?.iconUrl}
                                alt="Icons"
                                sx={{
                                  width: "35px",
                                  height: "35px",
                                  p: "5px",
                                  ml: 2,
                                }}
                                // slotProps={{
                                //   img: {
                                //     loading: "lazy",
                                //   },
                                // }}
                              />
                            )}
                            <Typography
                              sx={{
                                p: 2,
                                fontSize: {
                                  xs: "16px",
                                  md: "20px",
                                  lg: "20px",
                                },
                              }}
                            >
                              {item?.name}
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
      {/* ProfessionalBackground component */}
    </>
  );
}

export default ToolsComponent;
