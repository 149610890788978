import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Header from "../components/Header";
import FooterComponent from "../components/Footer";
import Circle from "@mui/icons-material/Circle";

function ChangeLog() {
  const versionData = [
    {
      date: "23 November 2024",
      version: "7.01",
      logs: [
        {
          description: "Subskill Enhancements",
          subPoints: [
            "Show unselected subskills.",
            "Fallback to parent logos.",
          ],
        },
        {
          description: "Updated algorithms for expertise endorsement.",
        },
      ],
    },
    {
      date: "11 October 2024",
      version: "7.00",
      logs: [
        {
          description: "Expertise Endorsement",
          subPoints: ["Added TechWorth with progress UI."],
        },
        {
          description: "Subskill Features",
          subPoints: [
            "Introduced multi-select options.",
            "Added color-coded learning ease.",
          ],
        },
      ],
    },
    {
      date: "09 September 2024",
      version: "6.05",
      logs: [
        {
          description: "UI/UX Updates",
          subPoints: [
            "Added Incognito Info Page.",
            "Improved Result Pages design.",
            "Refined Toggle Switch functionality.",
          ],
        },
      ],
    },
    {
      date: "28 August 2024",
      version: "6.04",
      logs: [
        {
          description: "Result page : Added 'Help Friends' button.",
        },
        {
          description:
            "Enhanced Skill Icons for visibility and dynamic resizing.",
        },
      ],
    },
    {
      date: "05 July 2024",
      version: "6.03",
      logs: [
        {
          description: "Launched Incognito Mode for privacy.",
        },
        {
          description: "Added Synonymous Search.",
        },
      ],
    },
    {
      date: "1 June 2024",
      version: "6.02",
      logs: [
        {
          description: "UX Updates",
          subPoints: [
            "Redesigned home page with updated terms and conditions, adding Google and LinkedIn buttons.",
            "Redesigned TechRelevance result page with improved colour schemes.",
          ],
        },
      ],
    },
    {
      date: "1 May 2024",
      version: "6.01",
      logs: [
        {
          description: "Feature Enhancements",
          subPoints: [
            "Improved the tech relevance algorithm and skill handling with updated prompts and conditions.",
            "Added new features including UAE location support.",
            "Enhanced user login functionality.",
            "New coin-based reward system on the 'My Friends' page.",
          ],
        },
        {
          description: "UX Updates",
          subPoints: [
            "Redesigned home, result, and referral pages with new elements like country flags and user drop-downs.",
            "Added app tours for easier user understanding on home and result pages.",
          ],
        },
      ],
    },
    {
      date: "1 April 2024",
      version: "6.00",
      logs: [
        {
          description: "UI/UX Updates",
          subPoints: [
            "Redesigned login, home, and result pages with new animations and responsive design.",
            "Enhanced referral tracking with pagination and mobile-friendly views for referral tables.",
          ],
        },
      ],
    },
    {
      date: "1 March 2024",
      version: "5.05",
      logs: [
        {
          description: "Feature Enhancements",
          subPoints: [
            "Added GenAI extensions for skill estimation and suggestions using ChatGPT.",
            "Implemented TrueEdge popup dialog and responsive design improvements.",
          ],
        },
        {
          description: "UX Updates",
          subPoints: [
            "Redesigned login, home, and result pages.",
            "Improved referral tracking with mobile-friendly tables and pagination.",
          ],
        },
      ],
    },
    {
      date: "1 February 2024",
      version: "5.04",
      logs: [
        {
          description: "Feature Enhancements",
          subPoints: [
            "Added LinkedIn authentication and job search integration.",
          ],
        },
        {
          description: "Backend Updates",
          subPoints: [
            "Enhanced algorithms for salary calculations and contract-specific logic.",
          ],
        },
      ],
    },
    {
      date: "1 January 2024",
      version: "5.03",
      logs: [
        {
          description: "Feature Enhancements",
          subPoints: [
            "Improved suggested skills and salary feedback API.",
            "Integrated ChatGPT for skill suggestions and interview questions.",
          ],
        },
        {
          description: "Backend Updates",
          subPoints: [
            "Enhanced the algorithm with country-specific compensation factors.",
          ],
        },
        {
          description: "UI/UX Improvements",
          subPoints: [
            "Redesigned the homepage and result page with fresh graphics.",
            "Added testimonials, user discounts, and cover letter functionality.",
          ],
        },
      ],
    },
    {
      date: "1 December 2023",
      version: "5.02",
      logs: [
        {
          description: "Referral and User Logic Enhancements",
          subPoints: [
            "Added unique IDs for referral URLs and improved social media referral handling.",
          ],
        },
        {
          description: "Backend and Algorithm Updates",
          subPoints: [
            "Enhanced TechValue calculations with updated location data.",
            "Integrated maxSalary into our algorithm for better salary predictions.",
          ],
        },
        {
          description: "UX Improvements",
          subPoints: [
            "Revamped Result and Referral pages with fresh designs and graphics.",
            "Added a maintenance dialog for users during updates.",
          ],
        },
        {
          description: "New Features",
          subPoints: [
            "Added OTP to email subject lines for easy viewing without opening the email.",
            "Incorporated new AI tools, certificates, and skills into the app.",
            "More locations added for AUS and NZ.",
          ],
        },
      ],
    },
    {
      date: "1 November 2023",
      version: "5.01",
      logs: [
        {
          description: "Result Management",
          subPoints: [
            "Last result can be viewed on login. Enhanced API for faster responses.",
          ],
        },
        {
          description: "Functional Upgrades",
          subPoints: [
            "Multiple business domains can be added as expertise.",
            "Enhanced Result Page for better clarity.",
            "Google-based one-click login enabled.",
            "Multiple locations added.",
          ],
        },
        {
          description: "Notification Enhancements",
          subPoints: [
            "Updated endorse email links and result emails to include salary data.",
          ],
        },
        {
          description: "New Features and API Development",
          subPoints: [
            "TrueEdge launched with ChatGPT API for cover letters.",
            "Added multi-country currency support for salary calculation.",
            "Enhanced domain acceptance for .edu and similar domains.",
          ],
        },
      ],
    },
    {
      date: "16 October 2023",
      version: "5.00",
      logs: [
        {
          description:
            "AI Boost - chatgpt Interface for Career Counseling Suggestions to increase salary",
        },
      ],
    },
    {
      date: "19 September 2023",
      version: "4.00",
      logs: [
        {
          description: '"Endorse your Expertise" feature with impact on salary',
        },
      ],
    },
    {
      date: "05 September 2023",
      version: "3.50",
      logs: [
        {
          description: "Lite Version TechRelevance Released",
        },
      ],
    },
    {
      date: "15 August 2023",
      version: "3.00",
      logs: [
        {
          description:
            "Peer Reviewed Expertise added, New Certificate format added",
        },
      ],
    },
    {
      date: "15 July 2023",
      version: "2.50",
      logs: [
        {
          description: "Role Seniority and Team Size weightages added",
        },
      ],
    },
    {
      date: "15 June 2023",
      version: "2.00",
      logs: [
        {
          description: "Secondary skill weightages added",
        },
      ],
    },
    {
      date: "01 June 2023",
      version: "1.50",
      logs: [
        {
          description:
            "500 new skills and certifications added - Suggestions from users",
        },
      ],
    },
    {
      date: "15 May 2023",
      version: "1.01",
      logs: [
        {
          description: "References made mandatory",
        },
      ],
    },
    {
      date: "01 May 2023",
      version: "1.00",
      logs: [
        {
          description: "Original Version",
        },
      ],
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid
          item
          xs={12}
          mx={{ xs: 0, sm: 0, md: 3, lg: 18 }}
          px={{ xs: 0 }}
          mt={{ xs: 12, md: 14, lg: 14 }}
          mb={{ xs: 2 }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", sm: "28px", md: "44px" },
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
              textAlign: "center",
            }}
            mb={{ xs: "20px", md: "69px", lg: "120px" }}
          >
            {" "}
            TrueSelfy TechWorth Alogrithm Evolution
          </Typography>

          <Timeline
            mt={{ xs: "60px", md: "120px", lg: "120px" }}
            sx={{
              // mt: "120px",
              "& .MuiTimelineItem-root": {
                flexDirection: { xs: "column", md: "row" },
              },
              [`& .MuiTimelineOppositeContent-root`]: {
                flex: 0.3,
              },
              "& .MuiTimelineContent-root": {
                display: "block",
                marginTop: { xs: "0px", md: "-60px" },
                marginBottom: { xs: "40px", md: "120px" },
              },
              "& .MuiTimelineDot-root": {
                background: "transparent",
                width: "50px",
                height: "50px",
                border: "0px",
                padding: "0px",
                boxShadow: "none",
                display: "block",
                margin: "0px auto",
              },
              "& .MuiTimelineSeparator-root": {
                display: { xs: "none", md: "inline-flex" },
                flex: { xs: 0, md: 0.1 },
              },
              "& .MuiTimelineConnector-root": {
                background: (theme) => theme.palette.primary.main,
                width: "5px",
              },
            }}
          >
            {versionData &&
              versionData.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        color: "#232323",
                      }}
                    >
                      {item.date}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot>
                      <img
                        width="50px"
                        height="50px"
                        src="/assets/images/timeline-dot.svg"
                        alt="timeline-dot"
                        loading="lazy"
                      />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      sx={{
                        maxWidth: "1000px",
                        width: "100%",
                        padding: { xs: "20px", md: "33px" },
                        borderRadius: "10px",
                        background: "#F5F5F5",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "28px",
                          fontWeight: 600,
                          color: (theme) => theme.palette.primary.main,
                          marginBottom: { xs: "10px", md: "21px" },
                        }}
                      >
                        v{item.version}
                      </Typography>

                      <List
                        sx={{
                          ml: { xs: "15px", md: "50px" },
                          "& .MuiListItem-root": {
                            //padding: { xs: "6px", md: "12px" },
                            alignItems: "baseline",
                            padding: "0px !important",
                          },
                        }}
                      >
                        {item.logs.map((log, logIndex) => (
                          <React.Fragment key={logIndex}>
                            <ListItem>
                              <ListItemIcon
                                sx={{ minWidth: "unset", mr: "14px" }}
                              >
                                <Circle
                                  color="success"
                                  sx={{ fontSize: "14px" }}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  sx={{ fontSize: "24px", color: "#232323" }}
                                >
                                  {log.description}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            {log.subPoints && (
                              <List sx={{ ml: 4 }}>
                                {log.subPoints.map((subPoint, subIndex) => (
                                  <ListItem key={subIndex}>
                                    <ListItemIcon
                                      sx={{ minWidth: "unset", mr: "14px" }}
                                    >
                                      <Circle
                                        color="success"
                                        sx={{ fontSize: "10px" }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText>
                                      <Typography
                                        sx={{
                                          fontSize: "20px",
                                          color: "#232323",
                                        }}
                                      >
                                        {subPoint}
                                      </Typography>
                                    </ListItemText>
                                  </ListItem>
                                ))}
                              </List>
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </Grid>
        <Grid item xs={12}>
          <FooterComponent />
        </Grid>
      </Grid>
    </>
  );
}

export default ChangeLog;
