import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SubSkills from "../common/SubSkills";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useSelector, useDispatch } from "react-redux";
import { setResultPageCarousel } from "../../redux/action/result";
import { getResultPageCarousel } from "../stepper_form/stepper.helper";

function SkillsComponent() {
  const getIconBySkillName = async (skillName) => {
    const svgIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.svg`;
    const pngIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.png`;
    const darkIconPath = `${process.env.PUBLIC_URL}/assets/images/dark-icons/${skillName}.svg`;

    if (await imageExists(svgIconPath)) {
      return svgIconPath;
    } else if (await imageExists(pngIconPath)) {
      return pngIconPath;
    } else if (await imageExists(darkIconPath)) {
      return darkIconPath;
    }
    return null;
  };
  const imageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };
  const [skillsData, setSkillsData] = useState({
    primarySkill: [],
    secondarySkill: [],
  });
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  const userForm = useSelector(
    (state) =>
      state?.OldFormReducer || state?.ResultReducer?.publicResult?.userData
  );
  const [loading, setLoading] = React.useState(false);
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  const relevanceResultData = useSelector(
    (state) =>
      state?.ResultReducer?.publicResult?.userData?.relevanceResult || ""
  );
  const subSkillsData = useSelector(
    (state) => state?.ResultReducer?.publicResult?.userData?.subSkill || []
  );

  useEffect(() => {
    const fetchSkillsWithIcons = async () => {
      setLoading(true);
      // Fetch primary skills with icons
      const primarySkills = await Promise.all(
        resultData?.primarySkill?.map(async (skill) => {
          const iconUrl = await getIconBySkillName(skill.name); // Await if async
          return { ...skill, iconUrl };
        }) || []
      );

      // Fetch secondary skills with icons
      const secondarySkills = await Promise.all(
        resultData?.secondarySkill?.map(async (skill) => {
          const iconUrl = await getIconBySkillName(skill.name); // Await if async
          return { ...skill, iconUrl };
        }) || []
      );

      const colors = checkYearWithGpt(relevanceResultData[0]);
      const newSkills = primarySkills?.map((item) => {
        if (item?.name === relevanceResultData[0]["name"]) {
          return (item = { ...item, ...colors });
        } else {
          return item;
        }
      });

      // Update state with both primary and secondary skills
      setSkillsData({
        primarySkill: newSkills,
        secondarySkill: secondarySkills,
      });

      setLoading(false);
    };

    if (resultData) {
      fetchSkillsWithIcons(); // Call the async function
    } else {
      setLoading(false); // If no resultData, set loading to false
    }
  }, [resultData]); // Dependency on resultData
  function GetBackGroundColor(skillDemand, type) {
    if (skillDemand === "Trending") {
      switch (type) {
        case "y":
          return "#278C37";
        case "tech":
          return "#278C37";
        case "mgmt":
          return "#30B044";
        case "management":
          return "#30B044";
        case "generic":
          return "#38C94E";
        default:
          return "#278C37";
      }
    }
    if (skillDemand === "Obsolete") {
      switch (type) {
        case "y":
          return "#028EDF";
        case "tech":
          return "#028EDF";
        case "mgmt":
          return "#09A2FA";
        case "management":
          return "#09A2FA";
        case "generic":
          return "#79CDFD";
        default:
          return "#028EDF";
      }
    }
    if (skillDemand === "In demand") {
      switch (type) {
        case "y":
          return "#E9B112";
        case "tech":
          return "#E9B112";
        case "mgmt":
          return "#FEC113";
        case "management":
          return "#FEC113";
        case "generic":
          return "#FAD15D";
        default:
          return "#E9B112";
      }
    }
    if (skillDemand === "Getting outdated fast") {
      switch (type) {
        case "y":
          return "#C60015";
        case "tech":
          return "#C60015";
        case "mgmt":
          return "#E2031B";
        case "management":
          return "#E2031B";
        case "generic":
          return "#FF465A";
        default:
          return "#C60015";
      }
    }
  }
  function checkYearWithGpt(option) {
    const year = option?.expiryYear;
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (!year) {
      return {
        label: "Default",
        barPrimaryColor: "#e0e0e0",
        barSecondaryColor: "#e0e0e0",
      };
    }

    if (year > currentYear) {
      if (option?.chat_gpt === false && option?.estYear === 2010) {
        return {
          label: `Obsolete`,
          barPrimaryColor: "#028EDF",
          // barSecondaryColor: "#FFCDD2",
          barSecondaryColor: GetBackGroundColor("Obsolete", option?.type),
          // color: "red",
          color: "#ffffff",
        };
      } else {
        return {
          label: `Trending`,
          barPrimaryColor: "#278C37",
          // barSecondaryColor: "#A5D6A7",
          barSecondaryColor: GetBackGroundColor("Trending", option?.type),

          // color: "#26801E",
          color: "#ffffff",
        };
      }
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return {
        label: `In demand`,
        barPrimaryColor: "#E9B112",
        // barSecondaryColor: "#ffcc80",
        barSecondaryColor: GetBackGroundColor("In demand", option?.type),
        // color: "darkorange",
        color: "#ffffff",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      return {
        label: `Getting outdated fast`,
        barPrimaryColor: "#C60015",
        // barSecondaryColor: "#fff59d",
        barSecondaryColor: GetBackGroundColor(
          `Getting outdated fast`,
          option?.type
        ),

        // color: "#FF9D09",
        color: "#ffffff",
      };
    } else if (year <= currentYear - minYearDiff) {
      return {
        label: `Obsolete `,
        barPrimaryColor: "#028EDF",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: GetBackGroundColor(`Obsolete`, option?.type),
        // color: "red",
        color: "#ffffff",
      };
    }
  }
  const dispatch = useDispatch();
  const resultPageCarousel = useSelector(
    (state) => state?.ResultReducer?.resultPageCarousel || []
  );
  useEffect((item) => {
    // DeviceInfoAndLocation(setLocation, setDeviceInfo);
    // extractCityState();
    // convertCurrency();
    getResultPageCarousel(dispatch, setResultPageCarousel);
    // setTimeout(handleOpenPopup, 10000);
  }, []);
  return (
    <>
      {resultPageCarousel
        .find((section) => section.sectionName === "Skills")
        ?.subSections.filter((subSection) => subSection.visible) // Filter visible sub-sections
        .sort((a, b) => a.order - b.order) // Sort by 'order'
        .map((subSection) => {
          const skills =
            subSection.name === "PrimarySkills"
              ? skillsData.primarySkill
              : skillsData.secondarySkill;
          return skills.map((item, index) => {
            const isSecondarySkill = skillsData.secondarySkill.some(
              (secondary) => secondary.name === item.name
            );
            // Filter subskills based on the current skill name
            const filteredSubSkills = subSkillsData?.filter(
              (sub) => sub.parentSkill === item.name
            );
            return (
              <SplideSlide key={`${item.name}-${index}`}>
                <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        minWidth: {
                          xs: "100%",
                          md: "100%",
                          lg: "70%",
                        },
                        maxWidth: {
                          xs: "100%",
                          md: "100%",
                          lg: filteredSubSkills.length === 0 ? "100%" : "70%",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: "32px",
                        }}
                      >
                        <Box>
                          <Img
                            sx={{ width: { xs: "38px", md: "100%" } }}
                            src="/assets/images/person-star-green.svg"
                            alt="techworth"
                            loading="lazy"
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: "600",
                            textAlign: "center",
                            pl: { xs: 1, lg: 2 },
                            fontSize: "24px",
                          }}
                        >
                          {isNaN(userForm?.experience)
                            ? `Total experience of ${userForm?.experience} with`
                            : (() => {
                                const totalMonths = parseInt(
                                  userForm?.experience,
                                  10
                                );

                                // Convert months to years and months
                                const years = Math.floor(totalMonths / 12);
                                const months = totalMonths % 12;

                                // Build the experience string
                                let experienceText = "";
                                if (years > 0) {
                                  experienceText += `${years} ${
                                    years > 1 ? "Years" : "Year"
                                  }`;
                                }
                                if (months > 0) {
                                  experienceText += ` ${months} ${
                                    months > 1 ? "Months" : "Month"
                                  }`;
                                } else if (totalMonths === 0) {
                                  return `Fresher/Intern/Trainee with`;
                                }
                                // if(months)

                                return `Total experience of ${experienceText} with`;
                              })()}
                        </Typography>
                      </Box>
                      {filteredSubSkills.length === 0 ? (
                        <Paper
                          sx={{
                            border: `1px solid ${
                              item.barPrimaryColor ? "#fff" : "#8DE49A"
                            }`,
                            borderRadius: "15px",
                            display: "flex",
                            boxShadow: "none",
                            alignItems: "center",
                            flexDirection: "column",
                            backgroundColor: item.barPrimaryColor || "#fff", // Set individual skill background color
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              m: 0.2,
                            }}
                          >
                            {item?.iconUrl && ( // Only render Avatar if iconUrl is present
                              <Avatar
                                src={item?.iconUrl}
                                alt="Icons"
                                sx={{
                                  width: "35px",
                                  height: "35px",
                                  p: "5px",
                                  ml: 1,
                                  backgroundColor: "#fff",
                                }}
                                // slotProps={{
                                //   img: {
                                //     loading: "lazy",
                                //   },
                                // }}
                              />
                            )}
                            <Typography
                              sx={{
                                pt: 2,
                                pl: 2,
                                pr: 2,
                                pb: 2,
                                fontSize: {
                                  xs: "16px",
                                  md: "20px",
                                  lg: "20px",
                                },
                                fontWeight: 500,
                                color: item.color || "#000", // Apply the text color for each skill
                              }}
                            >
                              {item?.name}
                            </Typography>
                          </Box>
                          {filteredSubSkills.length === 0 &&
                          (isSecondarySkill || index > 4) ? (
                            <Box></Box>
                          ) : (
                            <Box
                              key={index}
                              sx={{
                                p: 0.4,
                                // mx: 1,
                                mx: item?.iconUrl ? 2 : 2,
                                mb: 2,
                                borderRadius: "10px",
                                // Dynamic background color
                                // border: 1.5px solid ${borderColor}, // Dynamic border color
                                minWidth: "120px",
                                textAlign: "center",
                              }}
                            >
                              <Stack direction="row" spacing={1}>
                                <Chip label="No subskills entered" />
                              </Stack>
                            </Box>
                          )}
                        </Paper>
                      ) : (
                        <SubSkills
                          subSkills={filteredSubSkills}
                          parentName={item.name}
                          borderColor={`1px solid ${
                            item.barPrimaryColor ? "#fff" : "#8DE49A"
                          }`}
                          bgColor={item.barPrimaryColor || "#fff"}
                          avatarIcon={item?.iconUrl}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </SplideSlide>
            );
          });
        })}
    </>
  );
}

export default SkillsComponent;
