import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function PrimarySkillRelevanceAIResult({ relevanceResult }) {
  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      const labelSuggestion = (
        <Typography
          sx={{
            color: "#232323",
            textAlign: { xs: "center", md: "center", lg: "left" },
          }}
          variant="subtitle1"
        >
          <span
            style={{
              // fontSize: "20px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#26801E",
            }}
          >
            • Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "green",
        barSecondaryColor: "#A5D6A7",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            color: "#232323",
            fontWeight: 600,
            textAlign: { xs: "center", md: "center", lg: "left" },
          }}
          variant="subtitle1"
        >
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "darkorange",
            }}
          >
            • Current
          </span>{" "}
          <spna>: Your tech is current, but it is getting outdated fast</spna>
        </Typography>
      );
      return {
        label: `Skill in demand until ${year}`,
        barPrimaryColor: "darkorange",
        barSecondaryColor: "#ffcc80",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            color: "#232323",
            textAlign: { xs: "center", md: "center", lg: "left" },
          }}
          variant="subtitle1"
        >
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#FF9D09",
            }}
          >
            • Getting outdated
          </span>{" "}
          <spna>: Upskill soon to stay ahead in the tech industry</spna>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#ffc107",
        barSecondaryColor: "#fff59d",
        labelSuggestion,
      };
    } else if (year <= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography
          sx={{
            color: "#232323",
            textAlign: { xs: "center", md: "center", lg: "left" },
          }}
          variant="subtitle2"
        >
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "red",
            }}
          >
            Obsolete
          </span>{" "}
          : Please learn new age skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "red",
        barSecondaryColor: "#FFCDD2",
        labelSuggestion,
      };
    }
  }
  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (obj.expiryYear > maxExpiryYear) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
      color: topSkillSuggestion?.barPrimaryColor,
    };
  }
  return (
    <React.Fragment>
      {/* <Grid container spacing={{ xs: 2, md: 3 }}> */}

      <Box
        sx={{
          // border: "0.5px solid #26801E",
          borderRadius: "5px",
          // background: "#EDFFF0",
          // py: 3,
          // my: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", md: "column", lg: "row" },
        }}
      >
        <Box sx={{ p: 1, py: { xs: 2, md: 2, lg: 0 } }}>
          <img
            style={{ maxWidth: "119px", height: "auto", width: "100%" }}
            src="/assets/images/timer.gif"
            loading="lazy"
          />
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "center", lg: "left" },
              color: "#000",
              fontWeight: 600,
              mb: 1,
            }}
            variant="h6"
          >
             <span style={{ fontWeight: 700, color: "rgb(23 195 61)" }}>
              {relevanceResult[0]?.name}
            </span>&nbsp;: Your top skill validity is until
          </Typography>
          <Typography
            // variant="h6"
            sx={{
              textAlign: { xs: "center", md: "center", lg: "left" },
              color: getTopSkill(relevanceResult)?.color,
              // mb:1,
              fontSize: "35px",
              fontWeight: 700,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: { xs: "center", lg: "left" },
            }}
          >
            {/* {console.log(getTopSkill(relevanceResult)?.barPrimaryColor, "year")} */}
            {getTopSkill(relevanceResult)?.maxExpiryYear}
            <Typography variant="subtitle2" sx={{ pl: 1 }}>
              (approximately)
            </Typography>
          </Typography>

          {getTopSkill(relevanceResult)?.labelSuggestion}
        </Box>
      </Box>
      {/* </Grid> */}
    </React.Fragment>
  );
}

export default PrimarySkillRelevanceAIResult;
