import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import ReactSpeedometer from "react-d3-speedometer";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 15,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#2BB344",
  },
}));
function TechworthDialContainer() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:1024px)");
  const isDesktop = useMediaQuery("(max-width:1400px)");
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: "1px solid #8DE49A",
                // boxShadow: "0px 0px 4px 0px #CDCAC073",
                p: "17px 20px 35px",
                // backgroundColor: "#F1FFF4",
                borderRadius: "10px",
                position: "relative",
                minWidth: { xs: "100%", md: "100%", lg: "70%" },
              }}
              className={"techworth-dial-container"}
            >
              <Box
                sx={{
                  maxWidth: {
                    xs: "80px",
                    sm: "108px",
                    md: "108px",
                    lg: "108px",
                  },
                  // width: "100%",
                  // height: "auto",
                  overflow: "hidden",
                  "& >img": { width: "100%", height: "100%" },
                  position: "absolute",
                  top: "5%",
                  right: "10%",
                  m: "0px auto",
                }}
              >
                {resultData?.evaluationTested ? (
                  <StyledBadge
                    badgeContent={resultData?.evaluationResultlength || 1}
                    color="primary"
                  >
                    <img
                      src={`/assets/images/${
                        resultData?.evaluationTested
                          ? "peerreviewed"
                          : "SELF-ATTESTED"
                      }.svg`}
                      alt="peer-reviewed"
                      style={{
                        maxWidth: isMobile
                          ? "65px"
                          : isTablet
                          ? "56px"
                          : "108px",
                      }}
                      loading="lazy"
                    />
                  </StyledBadge>
                ) : (
                  <img
                    src={`/assets/images/${"SELF-ATTESTED"}.svg`}
                    alt="self-attested"
                    style={{
                      maxWidth: isMobile ? "65px" : isTablet ? "56px" : "108px",
                    }}
                    loading="lazy"
                  />
                )}
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Img
                  sx={{
                    width: { xs: "38px", md: "100%" },
                    maxWidth: "51px",
                    mr: 1,
                  }}
                  src="/assets/images/diamond-green.svg"
                  alt="techworth"
                  loading="lazy"
                />
                <Typography
                  color="primary"
                  variant="h3"
                  sx={{ fontWeight: 600 }}
                >
                  TechWorth
                </Typography>
                </Box> */}
              <Box
                sx={{
                  display: "flex",
                  // flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
                  // gap: 3,
                  justifyContent: "center",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                {/* <Box  sx={{ maxWidth: "350px", width: "100%", height: "auto" }}> */}
                <ReactSpeedometer
                  segments={6}
                  customSegmentLabels={[
                    {
                      text: "<1",
                      position: "INSIDE",
                      color: "#fff",
                    },
                    {
                      text: "1-2",
                      position: "INSIDE",
                      color: "#fff",
                    },
                    {
                      text: "2-3",
                      position: "INSIDE",
                      color: "#fff",
                    },
                    {
                      text: "3-4 ",
                      position: "INSIDE",
                      color: "#fff",
                    },
                    {
                      text: "4-5",
                      position: "INSIDE",
                      color: "#fff",
                    },
                    {
                      text: "5+",
                      position: "INSIDE",
                      color: "#fff",
                    },
                  ]}
                  segmentColors={[
                    "rgba(215, 38, 38, 1)",
                    "rgba(255, 169, 2, 1)",
                    "rgba(43, 179, 68, 1)",
                    "rgba(13, 155, 33, 1)",
                    "rgba(255, 135, 26, 1)",
                    "rgba(215, 38, 38, 1)",
                  ]}
                  value={resultData?.techWorth > 6 ? 6 : resultData?.techWorth}
                  needleHeightRatio={0.8}
                  valueTextFontSize={"20px"}
                  valueTextFontWeight={700}
                  textColor={"#000"}
                  maxValue={6}
                  minValue={0}
                  needleTransition="easeElastic"
                  customSegmentStops={[0, 1, 2, 3, 4, 5, 6]}
                  // fluidWidth={true}
                  height={isDesktop ? 160 : 210}
                  width={isDesktop ? 250 : 350}
                  ringWidth={40}
                  needleColor="#000"
                  labelFontSize="12px"
                  // needleHeightRatio={0.8}
                />
                {/* </Box> */}

                {/* <Box
    sx={{
      maxWidth: "107px",
      minWidth: "90px",
      width: "100%",
      height: "auto",
      overflow: "hidden",
      "& >img": {
        width: "100%",
        height: "100%",
      },
    }}
  >
    <img src="/assets/images/SELF-ATTESTED.svg" alt="self-attested" />
  </Box> */}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "10px", lg: "14px" },
                    textAlign: { xs: "center", lg: "center" },
                  }}
                >
                  {" "}
                  <span style={{ color: "#2BB344" }}> Result Id: </span>
                  {resultData?._id}
                </Typography>
              </Box>
              <Box
                sx={{
                  overflow: "hidden",
                  "& >img": {
                    width: "100%",
                    height: "100%",
                  },
                  pb: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    maxWidth: "474px",
                    width: "100%",
                    height: "auto",
                  }}
                  src="/assets/images/scale.svg"
                  alt="scale"
                  loading="lazy"
                />
              </Box>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "10px", md: "16px" },
                  lineHeight: { xs: "18px", sm: "18px", md: "28px" },
                }}
              >
                This is an indicative figure based on your given answers.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "10px", md: "16px" },
                  lineHeight: { xs: "18px", sm: "18px", md: "28px" },
                  mb: "10px",
                }}
              >
                Ideally, your TechWorth must be at least 2 to 4, if you are to
                be considered productive and useful in your organization.
              </Typography>
              <Box
                sx={{
                  maxWidth: "556px",
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                  margin: "0px auto",
                  "& >img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img
                  src="/assets/images/equation-image.png"
                  alt="techworth-equation"
                  loading="lazy"
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default TechworthDialContainer;
