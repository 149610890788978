import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import ResultReferaFriend from "../../common/ResultReferaFriend";
import TooltipComponent from "../../common/Tooltip";

function SmartSalaryContainer() {
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  const [selectedValue, setSelectedValue] = useState("full-time-salary");
  const [contractSalarySelect, setContractSalarySelect] = useState(false);
  const handleChangeSalaryType = (event) => {
    const { value } = event.target;
    setContractSalarySelect(value === "contract-salary");
    setSelectedValue(value);
  };
  const [monthlySalary, setMonthlySalary] = useState();
  return (
    <>
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 10 },
        }}
      >
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                // boxShadow: "0px 0px 4px 0px #CDCAC073",
                border: "1.5px solid #8DE49A",
                borderRadius: "20px",
                background: "#F1FFF4",
                gap: 1,
                py: { xs: "15px", sm: "15px", md: 3 },
                px: { xs: "15px", sm: "15px", md: 2, lg: 2, xl: 3 },
                display: "flex",
                flexDirection: "column",
                minWidth: { xs: "100%", md: "100%", lg: "70%" },
                // mb: "20px !important",
              }}
              className="smart-salary-container"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: {
                    xs: "nowrap",
                    md: "nowrap",
                    lg: "nowrap",
                  },
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, flexWrap: "nowrap" }}>
                  <Box sx={{ display: "flex" }}>
                    <Img
                      sx={{
                        width: { xs: "38px", md: "35px", lg: "100%" },
                      }}
                      maxWidth="51px"
                      src="/assets/images/wallet-green.svg"
                      alt="wallet"
                      loading="lazy"
                    />
                  </Box>
                  <Box component="div">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "24px",
                        },
                        fontWeight: 600,
                        textAlign: { xs: "left", sm: "left" },
                      }}
                    >
                      SmartSalary
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "16px",
                        },
                      }}
                    >
                      (TechValue based)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    // margin: { xs: "0vw", sm: "2vw", md: "3vw", lg: "0 auto" },
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "68px",
                  }}
                >
                  {selectedValue === "contract-salary" ? (
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 600,
                        textAlign: {
                          xs: "right",
                          sm: "right",
                          lg: "right",
                        },
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "24px",
                        },
                      }}
                    >
                      {resultData?.results?.contractSalary}
                      <span style={{ fontSize: "12px" }}>(Rate/hour)</span>
                    </Typography>
                  ) : (
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 600,
                        textAlign: {
                          xs: "right",
                          sm: "right",
                          lg: "right",
                        },
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "24px",
                        },
                      }}
                    >
                      {resultData?.results?.convertedMarketSalary}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", sm: "wrap", lg: "nowrap" },
                  // ml: { xs: "0%", sm: "5%", md: "10%", lg: "10%" },
                  gap: 2,
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    lg: "space-between",
                  },
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: { xs: 3, sm: 4, md: 4, lg: 3, xl: 5 },
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      className={"full-time-salary"}
                    >
                      <Radio
                        value="full-time-salary"
                        // name="salary-type"
                        checked={selectedValue === "full-time-salary"}
                        onChange={handleChangeSalaryType}
                        inputProps={{ "aria-label": "A" }}
                        sx={{ p: 0, pr: 1 }}
                      />
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          component="p"
                          variant="subtitle2"
                          sx={{
                            color: "#636363",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "18px",
                            },
                          }}
                        >
                          Full-time salary
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: {
                              xs: "8px",
                              sm: "8px",
                              md: "12px",
                            },
                          }}
                        >
                          (per annum)
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                      className={"contact-salary"}
                    >
                      <Radio
                        value="contract-salary"
                        // name="salary-type"
                        checked={selectedValue === "contract-salary"}
                        onChange={handleChangeSalaryType}
                        inputProps={{ "aria-label": "B" }}
                        sx={{ p: 0, pr: 1 }}
                      />
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          component="p"
                          variant="subtitle2"
                          sx={{
                            color: "#636363",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "18px",
                            },
                          }}
                        >
                          Contract salary{" "}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: {
                              xs: "8px",
                              sm: "8px",
                              md: "12px",
                            },
                          }}
                        >
                          (Rate/hour)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "flex", sm: "flex" },
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      component="div"
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <ResultReferaFriend
                        className={"refer-a-friend"}
                        text={"Help Your Friends"}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <TooltipComponent
                          size="small"
                          message={
                            "People who are looking for a change, just underpaid, job hunting, recently laid off, etc."
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default SmartSalaryContainer;
