export const SET_USER_FINAL_RESULT = "SET_USER_FINAL_RESULT";
export const SET_RESULT_ID = "SET_RESULT_ID";
export const SET_PRIMARY_SKILL_RELEVANCE = "SET_PRIMARY_SKILL_RELEVANCE";
export const SET_RESULT_PAGE_SECTIONS = "SET_RESULT_PAGE_SECTIONS";
export const SET_RESULT_PAGE_CAROUSEL = "SET_RESULT_PAGE_CAROUSEL";
export const SET_USER_PUBLIC_RESULT = "SET_USER_PUBLIC_RESULT";

export const setUserResult = (response) => {
  return {
    type: SET_USER_FINAL_RESULT,
    payload: response,
  };
};

export const setResultId = (response) => {
  return {
    type: SET_RESULT_ID,
    payload: response,
  };
};

export const setPrimarySkillRelevance = (response) => {
  return {
    type: SET_PRIMARY_SKILL_RELEVANCE,
    payload: response,
  };
};

export const setResultPageSections = (response) => {
  return {
    type: SET_RESULT_PAGE_SECTIONS,
    payload: response,
  };
};

export const setResultPageCarousel = (response) => {
  return {
    type: SET_RESULT_PAGE_CAROUSEL,
    payload: response,
  };
};

export const setUserPublicResult = (response) => {
  return {
    type: SET_USER_PUBLIC_RESULT,
    payload: response,
  };
};
