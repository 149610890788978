import React, { useEffect, useState } from "react";
import ReplyTwoToneIcon from "@mui/icons-material/ReplyTwoTone";
import { RWebShare } from "react-web-share";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

function ReferaFriend({ text }) {
  const [shareLink, setShareLink] = useState(
    `${process.env.REACT_APP_BASE_URL}/signin`
  );
  const Refer_Link_Description_Write_up = useFeatureFlag(
    "Refer_Link_Description_Write_up"
  );
  const userRefId = useSelector((state) => state.userReducer?.userRefId || "");
  const isLoggedIn = useSelector(
    (state) => state.userReducer?.isLoggedIn || false
  );
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );

  useEffect(() => {
    // customGoogleUserLogin(dispatch, uniqueReferredUserId);
    if (isLoggedIn === "true" && !IncognitoModeStatus) {
      setShareLink(
        `${process.env.REACT_APP_BASE_URL}/signin?referBy=${userRefId}`
      );
    } else {
      setShareLink(`${process.env.REACT_APP_BASE_URL}/signin`);
    }
    const loggedInUserReferBy = localStorage.getItem("loggedInUserReferBy");
    if (loggedInUserReferBy === "true") {
      updateReferBy();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn === "true" && !IncognitoModeStatus) {
      setShareLink(
        `${process.env.REACT_APP_BASE_URL}/signin?referBy=${userRefId}`
      );
    } else {
      setShareLink(`${process.env.REACT_APP_BASE_URL}/signin`);
    }
    // console.log(isLoggedIn === "true", "isLoggedIn");
  }, [userRefId, isLoggedIn]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        sx={{ py: { xs: "0px", sm: "0px", md: 2 } }}
      >
        <RWebShare
          data={{
            text: Refer_Link_Description_Write_up,
            url: shareLink,
            title:
              "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <Button
            className={"refer-a-friend"}
            sx={{
              backgroundColor: "#2BB344",            
              display: "flex",
              pl: "10px !important",
              pr: "10px !important",
              color: "#fff",
              alignItems: "center",
              //   border: "1px solid #0D9B21",
              borderRadius: "10px",
              //   boxShadow: 3,

              "&:hover": {
                backgroundColor: "#2BB344 !important",
                color: "#fff",
                // borderColor: "#0D9B21",
              },
            }}
          >
            <ReplyTwoToneIcon
              sx={{
                // mr: "5px",
                mb: "5px",
                transform: "rotateY(-180deg)",
              }}
            />
            {text}
          </Button>
        </RWebShare>
      </Box>
    </>
  );
}
export default ReferaFriend;
