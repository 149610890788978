import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function AiBoostYourCareer() {
  const resultPageSections = useSelector(
    (state) => state?.ResultReducer?.resultPageSections || []
  );
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className={"truselfy-tech-relevance"}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #9B59B6",
                borderRadius: "8px",
                padding: "16px",
                minWidth: { xs: "100%", md: "100%", lg: "70%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: "18px",
                }}
              >
                <Img
                  sx={{ width: { xs: "38px", md: "100%" } }}
                  style={{
                    maxWidth: "82px",
                    height: "82px",
                    width: "82px",
                  }}
                  src="/assets/images/rocket.gif"
                  loading="lazy"
                />
                <Box sx={{ marginLeft: "16px" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#584AAF",
                      fontWeight: 600,
                    }}
                  >
                    AI Boost your career
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      maxWidth: {
                        md: "302px",
                        lg: "243px",
                        xl: "370px",
                      },
                    }}
                  >
                    Find out how long your each skill will stay relevant in this
                    ever-changing landscape?
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#fff",
                  zIndex: 1,
                  color: "#584AAF",

                  borderColor: "#584AAF",
                  "&:hover": {
                    backgroundColor: "#4747a6 !important",
                    color: "#fff",
                    borderColor: "#fff",
                  },
                  mt: {
                    xs: "10px",
                    sm: "10px",
                    md: "0px !important",
                    lg: "0px !important",
                  },
                }}
                target="_blank"
                href={"https://www.techrelevance.trueselfy.com/"}
                endIcon={<ChevronRightIcon size="large" />}
              >
                Boost your career
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default AiBoostYourCareer;
