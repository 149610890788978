import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import ShareIcon from "@mui/icons-material/Share";
import WebShareButton from "../components/common/WebShareButton";
import ReplyTwoToneIcon from "@mui/icons-material/ReplyTwoTone";
import { RWebShare } from "react-web-share";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css"; // Splide core styles
import { useSelector, useDispatch } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SkillsComponent from "../components/resultpagecarousel/SkillsComponent";
import ToolsComponent from "../components/resultpagecarousel/ToolsComponent";
import ProfessionalBackgroundComponent from "../components/resultpagecarousel/ProfessionalBackgroundComponent";
import SmartSalaryContainer from "../components/resultpagecarousel/Techworth/SmartSalaryContainer";
import TechValueContainer from "../components/resultpagecarousel/Techworth/TechValueContainer";
import JobsLinkedinIndeed from "../components/resultpagecarousel/Techworth/JobsLinkedinIndeed";
import TechworthDialContainer from "../components/resultpagecarousel/Techworth/TechworthDialContainer";
import AiBoostYourCareer from "../components/resultpagecarousel/Topskill/AiBoostYourCareer";
import TopSkillValidity from "../components/resultpagecarousel/Topskill/TopSkillValidity";
import TrueEdgeContainer from "../components/resultpagecarousel/TrueEdge/TrueEdgeContainer";
import ReferencesContainer from "../components/resultpagecarousel/References/ReferencesContainer";
import { styled } from "@mui/material/styles";
import {
  setResultPageCarousel,
  setUserPublicResult,
} from "../redux/action/result";
import {
  getResultPageCarousel,
  getPublicResult,
} from "../components/stepper_form/stepper.helper";
import FooterComponent from "../components/Footer";
import Loading from "../components/UI/Loading";
import ErrorComponent from "../components/common/ErrorComponent";
import { saveTargetPath } from "../utils/storage";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { useNavigate } from "react-router-dom";

const ResultCarousel = () => {
  const getIconBySkillName = async (skillName) => {
    const svgIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.svg`;
    const pngIconPath = `${process.env.PUBLIC_URL}/assets/images/icons/${skillName}.png`;
    const darkIconPath = `${process.env.PUBLIC_URL}/assets/images/dark-icons/${skillName}.svg`;

    if (await imageExists(svgIconPath)) {
      return svgIconPath;
    } else if (await imageExists(pngIconPath)) {
      return pngIconPath;
    } else if (await imageExists(darkIconPath)) {
      return darkIconPath;
    }
    return null;
  };

  const imageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const dispatch = useDispatch();
  const path = window.location.pathname;
  const pathParts = path.split("/");
  const uuid = pathParts[pathParts.length - 1];
  const [pageLoading, setPageLoading] = useState(true);
  const [errorPublicPage, setErrorPublicPage] = useState({
    error: false,
    message: "",
    status: 400,
  });
  const MaintenanceMessage = useFeatureFlag("Maintenance_Break");
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const navigate = useNavigate();

  useEffect(() => {
    getPublicResult(
      dispatch,
      setUserPublicResult,
      uuid,
      setPageLoading,
      setErrorPublicPage
    );
    // DeviceInfoAndLocation(setLocation, setDeviceInfo);

    // setTimeout(handleOpenPopup, 10000);
  }, []);
  const relevanceResultData = useSelector(
    (state) =>
      state?.ResultReducer?.publicResult?.userData?.relevanceResult || ""
  );
  const [shareLink, setShareLink] = useState(
    `${process.env.REACT_APP_BASE_URL}/public-result/${uuid}`
  );

  const resultPageSections = useSelector(
    (state) => state?.ResultReducer?.resultPageSections || []
  );
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  const subSkillsData = useSelector(
    (state) => state?.ResultReducer?.publicResult?.userData?.subSkill || []
  );
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  const [skillsData, setSkillsData] = useState({
    primarySkill: [],
    secondarySkill: [],
    AllTools: [],
    AIToolsList: [],
    softwareToolsList: [],
  });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchSkillsWithIcons = async () => {
      setLoading(true);
      // Fetch primary skills with icons
      const primarySkills = await Promise.all(
        resultData?.primarySkill?.map(async (skill) => {
          const iconUrl = await getIconBySkillName(skill.name); // Await if async
          return { ...skill, iconUrl };
        }) || []
      );

      // Fetch secondary skills with icons
      const secondarySkills = await Promise.all(
        resultData?.secondarySkill?.map(async (skill) => {
          const iconUrl = await getIconBySkillName(skill.name); // Await if async
          return { ...skill, iconUrl };
        }) || []
      );

      // Fetch all tools with icons
      // const toolsWithIcons = await Promise.all(
      //   resultData?.AllTools?.map(async (tool) => {
      //     const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
      //     return { ...tool, iconUrl }; // Append iconUrl to each tool object
      //   }) || []
      // );

      // Fetch Ai tools with icons
      const aitoolsIcons = await Promise.all(
        resultData?.AIToolsList?.map(async (tool) => {
          const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
          return { ...tool, iconUrl }; // Append iconUrl to each tool object
        }) || []
      );

      // Fetch Software tools with icons
      const softwaretoolsIcons = await Promise.all(
        resultData?.softwareToolsList?.map(async (tool) => {
          const iconUrl = await getIconBySkillName(tool.name); // Fetch icon for each tool
          return { ...tool, iconUrl }; // Append iconUrl to each tool object
        }) || []
      );

      const colors = checkYearWithGpt(relevanceResultData[0]);
      const newSkills = primarySkills?.map((item) => {
        if (item?.name === relevanceResultData[0]["name"]) {
          return (item = { ...item, ...colors });
        } else {
          return item;
        }
      });

      // Update state with both primary and secondary skills
      setSkillsData({
        primarySkill: newSkills,
        secondarySkill: secondarySkills,
        // AllTools: toolsWithIcons,
        AIToolsList: aitoolsIcons,
        softwareToolsList: softwaretoolsIcons,
      });

      setLoading(false);
    };

    if (resultData) {
      fetchSkillsWithIcons(); // Call the async function
    } else {
      setLoading(false); // If no resultData, set loading to false
    }
  }, []); // Dependency on resultData
  const userForm = useSelector(
    (state) =>
      state?.OldFormReducer || state?.ResultReducer?.publicResult?.userData
  );
  const resultPageCarousel = useSelector(
    (state) => state?.ResultReducer?.resultPageCarousel || []
  );

  function extractCityState() {
    let locationString = userForm?.location;
    // Find the index of the last comma
    let lastIndex = locationString.lastIndexOf(",");
    // Extract the substring up to the last comma
    let result = locationString.substring(0, lastIndex);
    // setJobLocation(result);
  }

  useEffect(() => {
    // extractCityState();
    // convertCurrency();
    getResultPageCarousel(dispatch, setResultPageCarousel);
  }, []);
  function GetBackGroundColor(skillDemand, type) {
    if (skillDemand === "Trending") {
      switch (type) {
        case "y":
          return "#278C37";
        case "tech":
          return "#278C37";
        case "mgmt":
          return "#30B044";
        case "management":
          return "#30B044";
        case "generic":
          return "#38C94E";
        default:
          return "#278C37";
      }
    }
    if (skillDemand === "Obsolete") {
      switch (type) {
        case "y":
          return "#028EDF";
        case "tech":
          return "#028EDF";
        case "mgmt":
          return "#09A2FA";
        case "management":
          return "#09A2FA";
        case "generic":
          return "#79CDFD";
        default:
          return "#028EDF";
      }
    }
    if (skillDemand === "In demand") {
      switch (type) {
        case "y":
          return "#E9B112";
        case "tech":
          return "#E9B112";
        case "mgmt":
          return "#FEC113";
        case "management":
          return "#FEC113";
        case "generic":
          return "#FAD15D";
        default:
          return "#E9B112";
      }
    }
    if (skillDemand === "Getting outdated fast") {
      switch (type) {
        case "y":
          return "#C60015";
        case "tech":
          return "#C60015";
        case "mgmt":
          return "#E2031B";
        case "management":
          return "#E2031B";
        case "generic":
          return "#FF465A";
        default:
          return "#C60015";
      }
    }
  }

  function checkYearWithGpt(option) {
    const year = option?.expiryYear;
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (!year) {
      return {
        label: "Default",
        barPrimaryColor: "#e0e0e0",
        barSecondaryColor: "#e0e0e0",
      };
    }

    if (year > currentYear) {
      if (option?.chat_gpt === false && option?.estYear === 2010) {
        return {
          label: `Obsolete`,
          barPrimaryColor: "#028EDF",
          // barSecondaryColor: "#FFCDD2",
          barSecondaryColor: GetBackGroundColor("Obsolete", option?.type),
          // color: "red",
          color: "#ffffff",
        };
      } else {
        return {
          label: `Trending`,
          barPrimaryColor: "#278C37",
          // barSecondaryColor: "#A5D6A7",
          barSecondaryColor: GetBackGroundColor("Trending", option?.type),

          // color: "#26801E",
          color: "#ffffff",
        };
      }
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return {
        label: `In demand`,
        barPrimaryColor: "#E9B112",
        // barSecondaryColor: "#ffcc80",
        barSecondaryColor: GetBackGroundColor("In demand", option?.type),
        // color: "darkorange",
        color: "#ffffff",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      return {
        label: `Getting outdated fast`,
        barPrimaryColor: "#C60015",
        // barSecondaryColor: "#fff59d",
        barSecondaryColor: GetBackGroundColor(
          `Getting outdated fast`,
          option?.type
        ),

        // color: "#FF9D09",
        color: "#ffffff",
      };
    } else if (year <= currentYear - minYearDiff) {
      return {
        label: `Obsolete `,
        barPrimaryColor: "#028EDF",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: GetBackGroundColor(`Obsolete`, option?.type),
        // color: "red",
        color: "#ffffff",
      };
    }
  }

  const componentMap = {
    SmartSalary: <SmartSalaryContainer />,
    TechValue: <TechValueContainer />,
    Jobs: <JobsLinkedinIndeed />,
    Speedometer: <TechworthDialContainer />,
    Skills: <SkillsComponent />,
    AllTools: resultData?.AllTools?.length !== 0 && <ToolsComponent />,
    ProfessionalBackground: <ProfessionalBackgroundComponent />,
    BoostYourCareer: <AiBoostYourCareer />,
    SkillDuration: <TopSkillValidity />,
    TrueEdge: resultData?.coverLetter !== "" && <TrueEdgeContainer />,
    References: <ReferencesContainer />,
  };

  // Filter and sort sections based on the "order" and "visible" properties
  const sortedSections = resultPageCarousel
    .filter((section) => section.visible)
    .sort((a, b) => a.order - b.order);

  return (
    <>
      {/* Header Section */}
      <Header />
      {pageLoading ? (
        <Loading />
      ) : errorPublicPage.error === true ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            my: 30,
            gap: 4,
          }}
        >
          <ErrorComponent errorWithStatus={errorPublicPage} />
          <Box p={1} className={"calculate-your-tech-worth"}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0D9B21",
                "&:hover": {
                  backgroundColor: "#fff !important",
                  color: "#0D9B21",
                  borderColor: "#fff",
                },
              }}
              onClick={() => {
                ReactGA.event({
                  category: "calculate",
                  action: "calculate-action",
                  label: "calculate-label",
                });
                if (MaintenanceMessage != "undefined") {
                  setResponsiveDialog(true);
                } else {
                  navigate("/skills-calculation");
                  saveTargetPath(window.location.pathname);
                }
              }}
            >
              {CalculateButtonValue}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // maxWidth: "1200px",
            // margin: "auto",
            // px: { xs: 2, sm: 3, md: 5 },
            // py: 3,
            mt: { xs: 8, lg: 14 },
            py: { xs: 3, sm: 3, md: 3, lg: 3, xl: 0 },
            // bgcolor: "#f9f9f9",
            // borderRadius: "12px",
            // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                // gap: 2,
                flexWrap: "wrap",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                mb: { xs: "16px", sm: "16px", md: 3 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "25px", md: "35px", lg: "40px" },
                  fontWeight: 600,
                  textAlign: { xs: "center", lg: "center" },
                  lineHeight: { xs: "30px", md: "40px", lg: "50px" },
                  color: "transparent",
                  backgroundImage:
                    "linear-gradient(to right, #2BB344, #FFC218, #E0011C, #0BA2FF)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  flexGrow: 1,
                  wordWrap: "break-word",
                  // mt: "20px !important",
                }}
              >
                {`${resultData?.name?.trim()}'s`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "25px", md: "35px", lg: "40px" },
                  fontWeight: 600,
                  textAlign: { xs: "center", lg: "center" },
                  lineHeight: { xs: "30px", md: "40px", lg: "50px" },
                  color: "transparent",
                  backgroundImage:
                    "linear-gradient(to right, #2BB344, #FFC218, #E0011C, #0BA2FF)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  flexGrow: 1,
                  wordWrap: "break-word",
                  // mt: "20px !important",
                }}
              >
                TechWorth
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "flex", sm: "flex" },
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "14px", lg: "16px" },
                        textAlign: { xs: "center", lg: "center" },
                        display: "flex",
                        alignItems: "center",
                        margin: { xs: "0 auto", sm: "0 auto" },
                      }}
                    >
                      <LocationOnIcon
                        sx={{
                          fontSize: "inherit",
                          color: "#2BB344",
                          marginRight: "-2px",
                        }}
                      />
                      <span style={{ color: "#2BB344", fontSize: "inherit" }}>
                        Work location:&nbsp;
                      </span>
                      {userForm?.location}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        ml: "10px",
                      }}
                    >
                      <RWebShare
                        data={{
                          text: "Here is something interesting for you.",
                          url: shareLink,
                          title: "Check this out!",
                        }}
                        onClick={() =>
                          console.log("shared successfully! public page")
                        }
                      >
                        <Button
                          className={"refer-a-friend"}
                          sx={{
                            backgroundColor: "#2BB344",
                            display: "flex",
                            pl: "10px !important",
                            pr: "10px !important",
                            color: "#fff",
                            alignItems: "center",
                            borderRadius: "8px",
                            "&:hover": {
                              backgroundColor: "#2BB344 !important",
                              color: "#fff",
                            },
                          }}
                        >
                          <ShareIcon fontSize="small" />
                        </Button>
                      </RWebShare>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "10px", lg: "14px" },
                  textAlign: { xs: "center", lg: "center" },
                  marginTop: "10px",
                }}
              >
                <span style={{ color: "#2BB344" }}> Result Id: </span>
                {resultData?._id}
              </Typography>
            </Box>
          </Grid>
          <Splide
            options={{
              type: "loop",
              perPage: 1,
              perMove: 1,
              gap: "1rem",
              pagination: true,
              arrows: true, // Enable navigation arrows
              autoplay: true, // Disable autoplay
              speed: 600, // Animation speed
              breakpoints: {
                640: { gap: "0.5rem" }, // Mobile responsiveness
              },
            }}
            style={{
              padding: "1rem 0",
              position: "relative",
            }}
          >
            {sortedSections.map((section) => {
              const Component = componentMap[section.sectionName];

              return (
                <>
                  {section.sectionName === "Skills" ||
                  section.sectionName === "ProfessionalBackground" ? (
                    <>{Component}</>
                  ) : (
                    Component && (
                      <SplideSlide key={section.sectionName}>
                        {Component}
                      </SplideSlide>
                    )
                  )}
                </>
              );
            })}

            {/* <ProfessionalBackgroundComponent /> */}
          </Splide>

          {/* Inline Styling for Custom Navigation Arrows */}
          <style>
            {`
        .splide__arrow {
          background-color: #2bb344;
          color: white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .splide__arrow:hover {
          background-color: #229b3c;
        }

        .splide__pagination {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .splide__pagination__page {
          background: #ddd;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 0 3px;
          cursor: pointer;
          transition: background 0.3s ease;
        }

        .splide__pagination__page.is-active {
          background: #2bb344;
        }

       /* Center content inside slides */
          // .splide__slide {
          //   align-content: center;
          // }

        /* margin the arrows for desktop */
        @media (min-width: 1440px) {
          .splide__arrow {
              margin-left: 80px;
              margin-right: 80px;
          }
        }
        `}
          </style>
        </Box>
      )}
    </>
  );
};

export default ResultCarousel;
