import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { setResultPageCarousel } from "../../redux/action/result";
import { getResultPageCarousel } from "../stepper_form/stepper.helper";

function ProfessionalBackgroundComponent() {
  const userForm = useSelector(
    (state) =>
      state?.OldFormReducer || state?.ResultReducer?.publicResult?.userData
  );
  const dispatch = useDispatch();
  const resultPageCarousel = useSelector(
    (state) => state?.ResultReducer?.resultPageCarousel || []
  );
  useEffect((item) => {
    // DeviceInfoAndLocation(setLocation, setDeviceInfo);
    // extractCityState();
    // convertCurrency();
    getResultPageCarousel(dispatch, setResultPageCarousel);
    // setTimeout(handleOpenPopup, 10000);
  }, []);
  const DynamicIcon = ({ rawValue, defaultIcon, itemKey }) => {
    const [icon, setIcon] = useState(defaultIcon);

    // Helper function to sanitize the string (convert to lowercase and remove special characters)
    const sanitizeString = (str) =>
      str
        .toLowerCase()
        .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "") // Remove special characters
        .replace(/\s+/g, ""); // Remove spaces

    // Use useEffect to update icon based on rawValue
    useEffect(() => {
      if (rawValue) {
        // Sanitize both display value and icon name
        const sanitizedValue = sanitizeString(rawValue);

        // Dynamically build the icon path based on sanitized value
        const dynamicIconPath = `/assets/images/professional-background/all_sections/${sanitizedValue}.gif`;

        // Check if the custom icon exists by comparing with sanitized icon name
        fetch(dynamicIconPath)
          .then((response) => {
            if (response.ok) {
              setIcon(dynamicIconPath); // Set dynamic icon if found
            } else {
              setIcon(defaultIcon); // Fallback to default icon if not found
            }
          })
          .catch(() => {
            setIcon(defaultIcon); // Fallback to default icon on error
          });
      } else {
        setIcon(defaultIcon); // Fallback to default icon if no value
      }
    }, [rawValue, itemKey, defaultIcon]);

    return (
      <Box
        component="img"
        src={icon}
        alt={itemKey}
        sx={{
          width: "70px",
          height: "70px",
          marginRight: "15px",
          border: "1px solid #8DE49A",
          borderRadius: "20px",
        }}
      />
    );
  };
  return (
    <>
      {resultPageCarousel
        ?.find((section) => section.sectionName === "ProfessionalBackground")
        ?.subSections.slice() // Create a shallow copy to avoid mutating the original array
        .sort((a, b) => a.order - b.order) // Sort based on 'order'
        .filter((section) => {
          const value = userForm[section.key]; // Get the value of the key in userForm
          return (
            value !== undefined &&
            value !== "No" &&
            value !== "" &&
            value !== null
          ); // Include only if value is defined and not "No"
        })
        ?.filter((section) => section.visible === true)
        ?.map((subSection, index) => {
          const item = resultPageCarousel
            ?.find(
              (section) => section.sectionName === "ProfessionalBackground"
            )
            ?.subSections.find((data) => data.key === subSection.key); // Match the key with professionalBackgroundData

          if (!item) return null; // Skip if no matching item is found

          const value = userForm?.[item.key]; // Get the value dynamically

          // Determine what to render
          let displayValue;
          let rawValue = ""; // To hold the unprocessed string for icon
          if (typeof value === "string") {
            rawValue = value; // Store the raw string for icon sanitization
            const splitValue = value.match(/^(.*?)\s*\((.*?)\)$/); // Match text and parentheses
            if (splitValue) {
              displayValue = (
                <>
                  <div>{splitValue[1].trim()}</div>
                  <div>({splitValue[2].trim()})</div>
                </>
              );
            } else {
              displayValue = value; // Fallback if no parentheses are found
            }
          } else if (Array.isArray(value)) {
            displayValue = value.map((v) => v.name || v);
          } else if (typeof value === "object" && value !== null) {
            rawValue =
              value.name || value.certificatePopularName || "Not Provided";
            displayValue = rawValue;
          }
          return (
            <SplideSlide>
              <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
                <Box
                  key={subSection._id}
                  sx={{
                    display: item.visible ? "block" : "none",
                    position: "relative",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        minWidth: { xs: "100%", md: "100%", lg: "70%" },
                        maxWidth: {
                          xs: "100%",
                          md: "100%",
                          lg: displayValue.length === 0 ? "100%" : "70%",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline-block",
                            background: "#2BB344",
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "14px",
                            px: 4,
                            py: 1,
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                          }}
                        >
                          {subSection.name}
                        </Box>
                      </Box>
                      <Card
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #8DE49A",
                          borderRadius: "20px",
                          boxShadow: "none",
                          padding: "15px",
                        }}
                      >
                        <DynamicIcon
                          rawValue={rawValue}
                          defaultIcon={item.icon}
                          itemKey={item.key}
                        />
                        <CardContent sx={{ p: "0 !important", flexGrow: 1 }}>
                          {item.key !== "certificationList" &&
                            item.key !== "domainArea" && (
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {displayValue}
                              </Typography>
                            )}
                          {item.key === "certificationList" &&
                            Array.isArray(value) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 1,
                                }}
                              >
                                {value.map(
                                  (certification, certificationIndex) => (
                                    <Typography
                                      key={certificationIndex}
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        padding: "4px 8px",
                                        borderRadius: "10px",
                                        backgroundColor: "#F8F8F8",
                                        border: "1px solid #8DE49A",
                                        textAlign: "center",
                                      }}
                                    >
                                      {certification.name || certification}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            )}
                          {item.key === "domainArea" &&
                            Array.isArray(value) && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 1,
                                }}
                              >
                                {value.map((domain, domainIndex) => (
                                  <Typography
                                    key={domainIndex}
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: 600,
                                      padding: "4px 8px",
                                      borderRadius: "10px",
                                      backgroundColor: "#F8F8F8",
                                      border: "1px solid #8DE49A",
                                      textAlign: "center",
                                    }}
                                  >
                                    {domain.name || domain}
                                  </Typography>
                                ))}
                              </Box>
                            )}
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SplideSlide>
          );
        })}
    </>
  );
}

export default ProfessionalBackgroundComponent;
