import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";

function TechValueContainer() {
  const Img = styled("img")({
    maxWidth: { xs: "35px !important", md: "56px" },
    maxHeight: "100%",
    width: "100%",
  });
  const resultData = useSelector(
    (state) => state?.ResultReducer?.publicResult || []
  );
  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: "1.5px solid #8DE49A",
                borderRadius: "20px",
                backgroundColor: "#F1FFF4",
                display: "flex",
                alignItems: "center",
                flexWrap: { xs: "nowrap", md: "nowrap", lg: "nowrap" },
                justifyContent: "space-between",
                py: { xs: 1, md: 1, lg: 2 },
                px: { xs: 2, md: 2, lg: 2, xl: 3 },
                minWidth: { xs: "100%", md: "100%", lg: "70%" },
                // mb: "20px !important",
              }}
              className={"tech-value-container"}
            >
              <Box sx={{ display: "flex", gap: 1, flexWrap: "nowrap" }}>
                <Box sx={{ display: "flex" }}>
                  <Img
                    sx={{
                      width: { xs: "38px", md: "35px", lg: "100%" },
                    }}
                    src="/assets/images/diamond-green.svg"
                    alt="yellow-star"
                    loading="lazy"
                  />
                </Box>
                <Box component="div">
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "16px", md: "24px" },
                      fontWeight: 600,
                      textAlign: { xs: "left", sm: "left" },
                    }}
                  >
                    TechValue
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: { xs: "10px", sm: "10px", md: "16px" },
                    }}
                  >
                    (Value you bring to the table)
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  // margin: { xs: "0vw", sm: "2vw", md: "3vw", lg: "0 auto" },
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "68px",
                }}
              >
                <Typography
                  className="btn-shine"
                  variant="h3"
                  sx={{
                    fontWeight: 600,
                    textAlign: {
                      xs: "right",
                      sm: "right",
                      lg: "right",
                    },
                    fontSize: { xs: "16px", sm: "16px", md: "24px" },
                  }}
                >
                  {resultData?.results?.convertedTechValue}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default TechValueContainer;
