import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Avatar,
  Chip,
  DialogActions,
  Tooltip,
  TablePagination,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import PrimarySkillRelevanceAIResult from "../../stepper_form/question-type-components/PrimarySkillRelevanceAIResult";

function TopSkillValidity() {
  const resultPageSections = useSelector(
    (state) => state?.ResultReducer?.resultPageSections || []
  );
  const relevanceResultData = useSelector(
    (state) =>
      state?.ResultReducer?.publicResult?.userData?.relevanceResult || ""
  );
  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 3, md: 10 } }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="top-skill-validity"
        >
          {relevanceResultData[0]?.estYear == null ||
          relevanceResultData[0]?.estYear === 0 ||
          relevanceResultData[0]?.expiryYear == null ||
          relevanceResultData[0]?.expiryYear === 0 ? (
            ""
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                  },
                  padding: "16px",
                  border: "1px solid #8DE49A",
                  borderRadius: "8px",
                  minWidth: { xs: "100%", md: "100%", lg: "70%" },
                  // backgroundColor: "#F1FFF4",
                  // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box>
                  <PrimarySkillRelevanceAIResult
                    relevanceResult={relevanceResultData}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default TopSkillValidity;
